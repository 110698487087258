import { AppAuthStateI } from './store';
import { API, Auth } from 'aws-amplify';
import { updateUserInIntercom } from './helpers';

const getCurrUser = async (userData: AppAuthStateI): Promise<AppAuthStateI> => {
	try {
		const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

		const savedAttributionId = window.localStorage.getItem('attributionId');

		await updateUserInIntercom(currentUser);

		if (savedAttributionId) {
			if (!currentUser?.attributes?.['custom:attribution_id']) {
				await Auth.updateUserAttributes(currentUser, {
					'custom:attribution_id': savedAttributionId,
				});
			}
		}

		const isAdmin = currentUser?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.some((group: string) => group === 'Admin');
		const isStudioDeveloper = currentUser?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.some((group: string) => group === 'StudioDeveloper');

		const currentUserAttributes = await API.get('AppsApi', '/currentUserAtts', {});

		return {
			...userData,
			isAdmin,
			isStudioDeveloper,
			currentUserAttributes: null,
			currentUser: null,
			appAuthState: 'signedOut',
			...(!!currentUser ? { currentUser, appAuthState: 'signedIn', currentUserAttributes } : []),
		};
	} catch (err: any) {
		return {
			...userData,
			isAdmin: false,
			isStudioDeveloper: false,
			currentUserAttributes: null,
			currentUser: null,
			appAuthState: 'signedOut',
		};
	}
};

export { getCurrUser };
