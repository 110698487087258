import React, { useContext, useEffect, useState } from 'react';
import { Box, CssBaseline, LinearProgress, Link, Stack, Typography } from '@mui/material';
import { AppContext } from '../utilities/StateProvider';
import { styled } from '@mui/system';
import LoginWrapper from './LoginWrapper';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../utilities/Logo';

const StudioLogin = () => {
    const { state } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const Main = styled('main')({
        flexGrow: 1,
    });

    useEffect(() => {
        const userSession = state.appAuthState.currentUser?.getSignInUserSession();
        const abortController = new AbortController();
        fetch('http://localhost:8989/', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
            },
            body: JSON.stringify(userSession),
            signal: abortController.signal,
        }).then((response) => {
            if (!response.ok) {
                throw new Error('Studio server down.');
            }
            setLoading(false);
            window.close();
        }).catch((_error) => {
            if (abortController.signal.aborted) {
                return;
            }
            setLoading(false);
            setError(true);
        });
        return () => abortController.abort();
    }, []);

    return (
        <Main>
            <LoginWrapper>
                <CssBaseline />
                <Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 4,
                    }}
                >
                    <Stack sx={{ mw: '200px', textAlign: 'center', mb: 2 }} direction='column' alignItems='center'>
                        <Link component={RouterLink} to='/' sx={{ display: 'flex', alignItems: 'center' }}>
                            <Logo sx={{ mw: 3 }} />
                        </Link>
                        <img src='/studio-logo.png' alt='DeepAR Studio logo' style={{ maxWidth: '100%', width: '120px', marginTop: '1rem', marginBottom: '2rem' }} />
                        {loading && (
                            <>
                                <Typography variant='h3'>Loading...</Typography>
                            </>
                        )}
                        {error && (
                            <>
                                <Typography variant='h3'>Login failed.</Typography>
                                <Typography variant='subtitle2' mt='1rem'>Couldn't find a running instance of DeepAR Studio.</Typography>
                                <Typography variant='subtitle2'>Are you sure it's running?</Typography>
                            </>
                        )}
                        {!loading && !error && (
                            <>
                                <Typography variant='h3'>Congrats! You are now logged in.</Typography>
                                <Typography variant='subtitle2' mt='1rem'>You can close this window if it doesn't close automatically.</Typography>
                            </>
                        )}
                    </Stack>
                </Box>
            </LoginWrapper>
        </Main>
    );
};

export default StudioLogin;
