import React from 'react';

import { LinearProgress, Link, Stack } from '@mui/material';
import { Box } from '@mui/system';
import CustomAlert, { CustomAlertPropsI } from '../utilities/CustomAlert';
import Logo from '../utilities/Logo';
import { AppContext } from '../utilities/StateProvider';
import LoginForm from './LoginForm';
import LoginWrapper from './LoginWrapper';
import RequireNewPassword from './RequireNewPassword';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

const Login = () => {
	const [loading, setLoading] = React.useState(false);
	const [alert, setAlert] = React.useState<CustomAlertPropsI>();

	const { state, dispatch } = React.useContext(AppContext);

	const requireNewPassword = state.appAuthState.appAuthState === 'requireNewPassword';

	const { search } = useLocation();

	React.useEffect(() => {
		const urlParams = new URLSearchParams(search);
		const error_description = urlParams.get('error_description');
		if (/Already.found.an.entry.for.username./i.test(error_description || '')) {
			dispatch({ type: 'CHANGE_AUTH_STATE', payload: { appAuthState: 'loading' } });
			const handleFederatedSignIn = async () => {
				const provider = window.localStorage.getItem('lastFederatedSignInProvider');
				await Auth.federatedSignIn({ provider: provider as CognitoHostedUIIdentityProvider });
			};

			handleFederatedSignIn();
		}
		//eslint-disable-next-line
	}, []);

	return (
		<LoginWrapper>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: 4,
				}}
			>
				<Stack sx={{ mw: '200px', textAlign: 'center', mb: 2 }} direction='column' alignItems='center'>
					<Link component={RouterLink} to='/' sx={{ display: 'flex', alignItems: 'center' }}>
						<Logo sx={{ mw: 3 }} />
					</Link>
					<img src='/login-icons.png' alt='Login icons showing DeepAR face filters' style={{ maxWidth: '100%', width: '240px', marginTop: '0.5rem' }} />
				</Stack>
				{!!alert && <CustomAlert {...alert} />}
				{requireNewPassword ? <RequireNewPassword {...{ setLoading, setAlert }} /> : <LoginForm {...{ setLoading, setAlert }} />}
			</Box>
		</LoginWrapper>
	);
};

export default Login;
