import React, { ChangeEvent } from 'react';

import axios from 'axios';

import { Autocomplete, Box, Button, Dialog, DialogContent, DialogProps, FormControl, FormHelperText, IconButton, LinearProgress, Skeleton, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import { AppContext } from '../../utilities/StateProvider';
import { editEffectTemplate, getEffectTemplateGifSignedUrlAsStudioDeveloper, getEffectTemplateImageSignedUrlAsStudioDeveloper, getEffectTemplateProjectSignedUrlAsStudioDeveloper, getEffectTemplateWebmSignedUrlAsStudioDeveloper, StudioDeveloperEffectTemplateI, useEffectTemplateGifsAsStudioDeveloper, useEffectTemplateImagesAsStudioDeveloper, useEffectTemplateProjectsAsStudioDeveloper, useEffectTemplateWebmsAsStudioDeveloper } from '../../utilities/queries';
import UploadIcon from '@mui/icons-material/Upload';

interface CreateEditEffectTemplatePropsI extends DialogProps {
	closeDialog: () => void;
	effectTemplate?: StudioDeveloperEffectTemplateI;
	forceNew?: Boolean;
}

export interface CreateEditEffectTemplateFormI {
	EnglishName: string;
	EnglishDescription: string;
	Categories: string[];
	SupportedPlatforms: string[];
	MinStudioVersionString: string;
	MaxStudioVersionString: string;
	Zip: {
		DownloadUrl: string;
		SizeInBytes: number;
	};
	ImageUrl: string;
	WebmUrl?: string;
	GifUrl?: string;
}

const EMPTY_ZIP = { DownloadUrl: '', SizeInBytes: 0, LastModifiedUtcMs: 0 };

const CATEGORIES = [
	'FaceSwap',
	'FaceMorphing',
	'Animation',
	'Beauty',
	'Avatar',
	'VirtualTryOn',
	'HairColor',
	'PostProcessing',
	'Overlays',
	'FaceTracking',
	'FootTracking',
	'BackgroundMask',
	'StaticObject',
];

const CreateEditEffectTemplateDialog = (props: CreateEditEffectTemplatePropsI) => {
	const { closeDialog, effectTemplate, forceNew, ...rest } = props;

	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const queryClient = useQueryClient();

	const [projectUploading, setProjectUploading] = React.useState(false);
	const [imageUploading, setImageUploading] = React.useState(false);
	const [webmUploading, setWebmUploading] = React.useState(false);
	const [gifUploading, setGifUploading] = React.useState(false);
	const { data: projects, isFetching: isProjectsLoading } = useEffectTemplateProjectsAsStudioDeveloper();
	const { data: images, isFetching: isImagesLoading } = useEffectTemplateImagesAsStudioDeveloper();
	const { data: webms, isFetching: isWebmsLoading } = useEffectTemplateWebmsAsStudioDeveloper();
	const { data: gifs, isFetching: isGifsLoading } = useEffectTemplateGifsAsStudioDeveloper();

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<CreateEditEffectTemplateFormI>({
		defaultValues: {
			EnglishName: effectTemplate?.Name?.english || '',
			EnglishDescription: effectTemplate?.Description?.english || '',
			Categories: effectTemplate?.Categories || [],
			SupportedPlatforms: effectTemplate?.SupportedPlatforms || [],
			MinStudioVersionString: effectTemplate?.MinStudioVersion?.join('.') || '0.0.0.0',
			MaxStudioVersionString: effectTemplate?.MaxStudioVersion?.join('.') || '',
			Zip: effectTemplate ? { DownloadUrl: effectTemplate.ZipUrl, SizeInBytes: effectTemplate.ZipSizeInBytes } : EMPTY_ZIP,
			ImageUrl: effectTemplate?.ImageUrl || '',
			WebmUrl: effectTemplate?.WebmUrl || '',
			GifUrl: effectTemplate?.GifUrl || '',
		},
	});

	const createNewEffectTemplate = async (form: CreateEditEffectTemplateFormI) => {
		return await API.post('AppsApi', '/studioDeveloper/effectTemplates', {
			body: {
				Name: {
					english: form.EnglishName,
				},
				Description: {
					english: form.EnglishDescription,
				},
				Categories: form.Categories,
				SupportedPlatforms: form.SupportedPlatforms,
				MinStudioVersion: form.MinStudioVersionString.split('.').map(part => parseInt(part, 10)),
				MaxStudioVersion: (form.MaxStudioVersionString || null)?.split('.').map(part => parseInt(part, 10)) || null,
				ZipUrl: form.Zip.DownloadUrl,
				ZipSizeInBytes: form.Zip.SizeInBytes,
				ImageUrl: form.ImageUrl,
				WebmUrl: form.WebmUrl || undefined,
				GifUrl: form.GifUrl || undefined,
			},
			headers: { 'Content-Type': 'application/json' },
		});
	};

	const createEffectTemplateMutation = useMutation(createNewEffectTemplate, {
		onSuccess: async () => {
			queryClient.invalidateQueries('studioDeveloperEffectTemplates');
			setGlobalAlertMessage({ message: 'New effect template created' });
			closeDialog();
		},
	});

	const editEffectTemplateMutation = useMutation(editEffectTemplate, {
		onSuccess: async () => {
			queryClient.invalidateQueries('studioDeveloperEffectTemplates');
			setGlobalAlertMessage({ message: 'Effect template saved successfully' });
			closeDialog();
		},
	});

	const handleSaveEffectTemplate: SubmitHandler<CreateEditEffectTemplateFormI> = async (form: CreateEditEffectTemplateFormI) => {
		try {
			if (forceNew || !effectTemplate) {
				createEffectTemplateMutation.mutateAsync(form);
			} else {
				editEffectTemplateMutation.mutateAsync({
					...effectTemplate,
					Name: {
						english: form.EnglishName,
					},
					Description: {
						english: form.EnglishDescription,
					},
					Categories: form.Categories,
					SupportedPlatforms: form.SupportedPlatforms,
					MinStudioVersion: form.MinStudioVersionString.split('.').map(part => parseInt(part, 10)),
					MaxStudioVersion: (form.MaxStudioVersionString || null)?.split('.').map(part => parseInt(part, 10)) || null,
					ZipUrl: form.Zip.DownloadUrl,
					ZipSizeInBytes: form.Zip.SizeInBytes,
					ImageUrl: form.ImageUrl,
					WebmUrl: form.WebmUrl,
					GifUrl: form.GifUrl,
				});
			}
		} catch (error: any) {
			setGlobalAlertMessage({ message: error.message, severity: 'error' });
		}
	};

	const handleZipUpload = async (e: ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0];
		if (!file) return;
		try {
			setProjectUploading(true);
			const signedResponse = await getEffectTemplateProjectSignedUrlAsStudioDeveloper(file.name);
			const axiosApi = axios.create();
			await axiosApi.put(signedResponse.signedUrl, file);
			queryClient.invalidateQueries('studioDeveloperEffectTemplateProjects');
		} catch (error: any) {
			setGlobalAlertMessage({ message: error.message, severity: 'error' });
		} finally {
			setProjectUploading(false);
		}
	};

	const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0];
		if (!file) return;
		try {
			setImageUploading(true);
			const signedResponse = await getEffectTemplateImageSignedUrlAsStudioDeveloper(file.name);
			const axiosApi = axios.create();
			await axiosApi.put(signedResponse.signedUrl, file);
			queryClient.invalidateQueries('studioDeveloperEffectTemplateImages');
		} catch (error: any) {
			setGlobalAlertMessage({ message: error.message, severity: 'error' });
		} finally {
			setImageUploading(false);
		}
	};

	const handleWebmUpload = async (e: ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0];
		if (!file) return;
		try {
			setWebmUploading(true);
			const signedResponse = await getEffectTemplateWebmSignedUrlAsStudioDeveloper(file.name);
			const axiosApi = axios.create();
			await axiosApi.put(signedResponse.signedUrl, file);
			queryClient.invalidateQueries('studioDeveloperEffectTemplateWebms');
		} catch (error: any) {
			setGlobalAlertMessage({ message: error.message, severity: 'error' });
		} finally {
			setWebmUploading(false);
		}
	};

	const handleGifUpload = async (e: ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0];
		if (!file) return;
		try {
			setGifUploading(true);
			const signedResponse = await getEffectTemplateGifSignedUrlAsStudioDeveloper(file.name);
			const axiosApi = axios.create();
			await axiosApi.put(signedResponse.signedUrl, file);
			queryClient.invalidateQueries('studioDeveloperEffectTemplateGifs');
		} catch (error: any) {
			setGlobalAlertMessage({ message: error.message, severity: 'error' });
		} finally {
			setGifUploading(false);
		}
	};

	return (
		<Dialog {...rest} fullWidth={true} maxWidth='md'>
			<Box sx={{ height: '10px' }}>{createEffectTemplateMutation.isLoading || (editEffectTemplateMutation.isLoading && <LinearProgress />)}</Box>
			<DialogContent>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>{effectTemplate && !forceNew ? 'Edit' : 'New'} Effect Template</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%', px: 5 }} component='form' onSubmit={handleSubmit(handleSaveEffectTemplate)} direction='column'>
						<Box>
							<h2>General</h2>
							<TextField
								{...register('EnglishName', { required: true })}
								margin='normal'
								required
								fullWidth
								id='name'
								label='Name (in English)'
								error={!!errors?.EnglishName}
								helperText={!!errors?.EnglishName && errors.EnglishName.message}
								autoFocus
							/>
							<TextField
								{...register('EnglishDescription', { required: true })}
								margin='normal'
								required
								fullWidth
								id='description'
								label='Description (in English)'
								error={!!errors?.EnglishDescription}
								helperText={!!errors?.EnglishDescription && errors.EnglishDescription.message}
							/>
							<FormControl fullWidth sx={{ pt: 2, pb: 2 }}>
								<Controller
									name='Categories'
									control={control}
									rules={{ required: false, minLength: 0 }}
									render={({ field: { onChange, value }, ...props }) => (
										<Autocomplete
											multiple
											value={value}
											onChange={(e, v) => onChange(v)}
											id='categories'
											options={CATEGORIES}
											renderInput={(params) => <TextField {...params} variant='standard' label='Search category' />}
										/>
									)}
								/>
							</FormControl>
							<FormControl fullWidth sx={{ pt: 2, pb: 5 }}>
								<Controller
									name='SupportedPlatforms'
									control={control}
									rules={{ required: true, minLength: 1 }}
									render={({ field: { onChange, value }, ...props }) => (
										<Autocomplete
											multiple
											value={value}
											onChange={(e, v) => onChange(v)}
											id='supportedPlatforms'
											options={['android', 'ios', 'web', 'macos']}
											renderInput={(params) => <TextField {...params} variant='standard' label='Search platforms' />}
										/>
									)}
								/>
							</FormControl>
							<TextField
								{...register('MinStudioVersionString', { required: true, pattern: /^[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+$/ })}
								margin='normal'
								required
								fullWidth
								id='minStudioVersion'
								label='Min Studio version'
								error={!!errors?.MinStudioVersionString}
								helperText={!!errors?.MinStudioVersionString && errors.MinStudioVersionString.message}
							/>
							<TextField
								sx={{ pb: 2 }}
								{...register('MaxStudioVersionString', { required: false, pattern: /^[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+$/ })}
								margin='normal'
								fullWidth
								id='maxStudioVersion'
								label='Max Studio version'
								error={!!errors?.MaxStudioVersionString}
								helperText={!!errors?.MaxStudioVersionString && errors.MaxStudioVersionString.message}
							/>
							<h2>Assets</h2>
							{isProjectsLoading || projectUploading ? (
								<Skeleton variant='rectangular' height={50} />
							) : (
								<FormControl fullWidth sx={{ pb: 2 }}>
									<Controller
										name='Zip'
										control={control}
										rules={{ required: true, minLength: 1 }}
										render={({ field: { onChange, value } }) => (
											<Autocomplete
												value={value}
												onChange={(e, v) => onChange(v)}
												id='zip'
												options={(projects || []).concat([EMPTY_ZIP])}
												getOptionLabel={project => project?.DownloadUrl ? `${project.DownloadUrl.split('/').pop()} (${project.SizeInBytes} B)` : ''}
												isOptionEqualToValue={(option, value) => option.DownloadUrl === value.DownloadUrl}
												renderInput={(params) => <TextField {...params} variant='standard' label='Choose ZIP'
												error={!!errors?.Zip}
												helperText={!!errors?.Zip && errors.Zip.SizeInBytes?.message}/>}
											/>
										)}
									/>
									<FormHelperText>Showing newest first</FormHelperText>
									<Button sx={{ mt: 2, mb: 1 }} variant='outlined' startIcon={<UploadIcon />} component='label'>
										Upload .ZIP
										<input
											accept='.zip'
											type="file"
											hidden
											onChange={handleZipUpload}
										/>
									</Button>
									<FormHelperText>find . -type f -name '*.deeparcache' -delete</FormHelperText>
									<FormHelperText>zip -r _.deeparproj.zip _.deeparproj -x "*.DS_Store"</FormHelperText>
								</FormControl>
							)}
							{isImagesLoading || imageUploading ? (
								<Skeleton variant='rectangular' height={50} />
							) : (
								<FormControl fullWidth sx={{ pb: 2 }}>
									<Controller
										name='ImageUrl'
										control={control}
										rules={{ required: true, minLength: 1 }}
										render={({ field: { onChange, value } }) => (
											<Autocomplete
												value={value}
												onChange={(e, v) => onChange(v)}
												id='imageUrl'
												options={(images?.map(image => image.DownloadUrl) || []).concat('')}
												getOptionLabel={url => url ? `${url.split('/').pop()}` : ''}
												renderInput={(params) => <TextField {...params} variant='standard' label='Choose thumbnail'
												error={!!errors?.ImageUrl}
												helperText={!!errors?.ImageUrl && errors.ImageUrl.message}/>}
											/>
										)}
									/>
									<FormHelperText>Showing newest first</FormHelperText>
									<Button sx={{ mt: 2, mb: 1 }} variant='outlined' startIcon={<UploadIcon />} component='label'>
										Upload Image
										<input
											accept='image/*'
											type="file"
											hidden
											onChange={handleImageUpload}
										/>
									</Button>
									<FormHelperText>Small size preferred</FormHelperText>
								</FormControl>
							)}
							{isWebmsLoading || webmUploading ? (
								<Skeleton variant='rectangular' height={50} />
							) : (
								<FormControl fullWidth sx={{ pb: 2 }}>
									<Controller
										name='WebmUrl'
										control={control}
										rules={{ required: false }}
										render={({ field: { onChange, value } }) => (
											<Autocomplete
												value={value}
												onChange={(e, v) => onChange(v)}
												id='webmUrl'
												options={(webms?.map(webm => webm.DownloadUrl) || []).concat('')}
												getOptionLabel={url => url ? `${url.split('/').pop()}` : ''}
												renderInput={(params) => <TextField {...params} variant='standard' label='Choose WEBM'
												error={!!errors?.WebmUrl}
												helperText={!!errors?.WebmUrl && errors.WebmUrl.message}/>}
											/>
										)}
									/>
									<FormHelperText>Showing newest first</FormHelperText>
									<Button sx={{ mt: 2, mb: 1 }} variant='outlined' startIcon={<UploadIcon />} component='label'>
										Upload WEBM
										<input
											accept='video/webm'
											type="file"
											hidden
											onChange={handleWebmUpload}
										/>
									</Button>
									<FormHelperText>Small size preferred</FormHelperText>
								</FormControl>
							)}
							{isGifsLoading || gifUploading ? (
								<Skeleton variant='rectangular' height={50} />
							) : (
								<FormControl fullWidth sx={{ pb: 2 }}>
									<Controller
										name='GifUrl'
										control={control}
										rules={{ required: false }}
										render={({ field: { onChange, value } }) => (
											<Autocomplete
												value={value}
												onChange={(e, v) => onChange(v)}
												id='gifUrl'
												options={(gifs?.map(gif => gif.DownloadUrl) || []).concat('')}
												getOptionLabel={url => url ? `${url.split('/').pop()}` : ''}
												renderInput={(params) => <TextField {...params} variant='standard' label='Choose GIF'
												error={!!errors?.GifUrl}
												helperText={!!errors?.GifUrl && errors.GifUrl.message}/>}
											/>
										)}
									/>
									<FormHelperText>Showing newest first</FormHelperText>
									<Button sx={{ mt: 2, mb: 1 }} variant='outlined' startIcon={<UploadIcon />} component='label'>
										Upload GIF
										<input
											accept='image/gif'
											type="file"
											hidden
											onChange={handleGifUpload}
										/>
									</Button>
									<FormHelperText>Not needed when WEBM file already chosen</FormHelperText>
								</FormControl>
							)}
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button
								variant='contained'
								fullWidth
								size='large'
								type='submit'
								disabled={isProjectsLoading || isImagesLoading || isWebmsLoading || isGifsLoading || projectUploading || imageUploading || webmUploading || gifUploading}>
								Save
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent >
		</Dialog >
	);
};

export default CreateEditEffectTemplateDialog;
