import React, { useContext } from 'react';

import { Box, Divider } from '@mui/material';
import { useProjects } from '../../utilities/queries';
import { AppContext } from '../../utilities/StateProvider';
import SidebarListItem from './SidebarListItem';
import SecurityIcon from '@mui/icons-material/Security';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ApiIcon from '@mui/icons-material/Api';

interface SignedInMenuItemsPropsI {
	onClick?: () => void;
}

const SignedInMenuItems = ({ onClick }: SignedInMenuItemsPropsI) => {
	const { data: projects, isLoading } = useProjects();
	const { state } = useContext(AppContext);
	const isAdmin = state.appAuthState.isAdmin;
	const isStudioDeveloper = state.appAuthState.isStudioDeveloper;

	return (
		<Box>
			<Box>
				<Box paddingY={2}>
					<SidebarListItem text={`Projects ${!isLoading && projects ? `(${projects.length})` : ''} `} url='/projects' onClick={onClick} />
					{isAdmin && <SidebarListItem text='Resources' url='/resources' icon={<FolderOpenIcon />} onClick={onClick} />}
					{(isAdmin) && <SidebarListItem text='Admin' url='/admin' icon={<SecurityIcon />} onClick={onClick} />}
					{(isStudioDeveloper) && <SidebarListItem text='Studio Developer' url='/studio-developer' icon={<SecurityIcon />} onClick={onClick} />}
				</Box>
				<Divider />
			</Box>
		</Box>
	);
};

export default SignedInMenuItems;
