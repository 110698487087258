import React from 'react';

import { Box, Button, ClickAwayListener, Grid, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AddIcon from '@mui/icons-material/Add';
import AndroidIcon from '@mui/icons-material/Android';
import LanguageIcon from '@mui/icons-material/Language';
import AppsIcon from '@mui/icons-material/Apps';
import KeyIcon from '@mui/icons-material/Key';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProjectI } from '../../../../utilities/queries';
import MauChart, { transformForMauChart } from '../MauChart';
import ProjectAppMoreMenu from './ProjectAppMoreMenu';
import DeleteProjectAppConfirmationDialog from './DeleteProjectAppConfirmationDialog';
import { lastMonthStrings } from '../../../../utilities/helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppContext } from '../../../../utilities/StateProvider';
import LicenceKey from './LicenceKey';
import { mauDataIntoYears } from '../TotalMauChart';

export type ProjectAppId = 'ios' | 'android' | 'web' | 'macos';

export interface ProjectAppTypeI {
	id: ProjectAppId;
	title: string;
}

export interface ProjectAppPropsI {
	appType: ProjectAppTypeI;
	setCreateAppDialogType?: React.Dispatch<React.SetStateAction<ProjectAppTypeI | null>>;
	project?: ProjectI;
	readonly?: boolean;
	activeYear?: string | number;
}

export const appTypes: ProjectAppTypeI[] = [
	{
		id: 'ios',
		title: 'iOS App',
	},
	{
		id: 'macos',
		title: 'MacOS App',
	},
	{
		id: 'android',
		title: 'Android App',
	},
	{
		id: 'web',
		title: 'Web App',
	},
];

const AppIcon = (appId: ProjectAppId, app: boolean) => {
	switch (appId) {
		case 'ios':
		case 'macos':
			return <AppleIcon color={app ? undefined : 'disabled'} />;
		case 'android':
			return <AndroidIcon color={app ? undefined : 'disabled'} />;
		case 'web':
			return <LanguageIcon color={app ? undefined : 'disabled'} />;
		default:
			return <AppsIcon color={app ? undefined : 'disabled'} />;
	}
};

const getStartedLink = (appId: ProjectAppId) => {
	switch (appId) {
		case 'ios':
		case 'macos':
			return 'https://docs.deepar.ai/deepar-sdk/platforms/ios/getting-started';
		case 'android':
			return 'https://docs.deepar.ai/deepar-sdk/platforms/android/getting-started';
		case 'web':
			return 'https://docs.deepar.ai/deepar-sdk/platforms/web/getting-started';
		default:
			return 'https://help.deepar.ai/en/';
	}
};

const ProjectApp = (props: ProjectAppPropsI) => {
	const { appType, setCreateAppDialogType, project, readonly, activeYear } = props;
	const [showKey, setShowKey] = React.useState(false);
	const [copiedTooltipOpen, setCopiedTooltipOpen] = React.useState(false);
	const [deleteAppDialogOpen, setDeleteAppDialogOpen] = React.useState(false);
	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const app = React.useMemo(() => {
		return project?.apps.find((a) => a.Platform === appType.id);
	}, [project, appType]);

	const handleKeyCopy = () => {
		if (app) {
			setCopiedTooltipOpen(true);
		}
	};

	const toggleShowKey = () => {
		setShowKey(!showKey);
		if (!showKey) {
			setCopiedTooltipOpen(false);
		}
	};

	const monthStrings = React.useMemo(() => {
		return lastMonthStrings(6);
	}, []);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const mauSortedIntoYears = mauDataIntoYears(app?.Mau || {});

	return (
		<>
			<Paper sx={{ p: 2 }}>
				<Grid container spacing={2} minHeight={!!app ? '240px' : undefined}>
					<Grid item xs={12} sm={3} height='100%'>
						<Stack direction='column' justifyContent='space-between' alignContent='space-between' sx={{ width: '100%' }}>
							<Stack direction='row' justifyContent='space-between'>
								<Stack direction='row' spacing={1}>
									{AppIcon(appType.id, !!app)}
									<Stack direction='column'>
										<Typography variant='h6' color={!!app ? undefined : 'text.secondary'}>
											{appType.title}
										</Typography>
										{app && (
											<Typography variant='body1' color='text.secondary'>
												{app.AppId}
											</Typography>
										)}
									</Stack>
								</Stack>
								{app && !readonly && <ProjectAppMoreMenu setDeleteAppDialogOpen={setDeleteAppDialogOpen} />}
							</Stack>
							{!!app && !isMobile && (
								<Box>
									<Button
										variant='text'
										size='large'
										color='inherit'
										startIcon={<KeyIcon color={showKey ? 'warning' : undefined} />}
										endIcon={<ExpandMoreIcon sx={{ transform: showKey ? 'rotate(180deg)' : undefined }} />}
										onClick={toggleShowKey}
									/>
								</Box>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} sm={9} sx={{ textAlign: 'right' }}>
						{!app ? (
							!readonly ? (
								<Stack direction='row' spacing={2} width='100%' justifyContent='end'>
									<Button href={getStartedLink(appType.id)} variant='text' target='_blank'>
										Setup guide
									</Button>
									<Button variant={!project?.apps.length ? 'contained' : 'outlined'} startIcon={<AddIcon />} onClick={(e) => setCreateAppDialogType?.(appType)}>
										Add app
									</Button>
								</Stack>
							) : (
								<Typography color='text.secondary'>No app created</Typography>
							)
						) : (
							<MauChart
								data={mauSortedIntoYears[activeYear || new Date().getFullYear()]}
								infoRight={activeYear}
								showX
								infoLeft={
									<Box sx={{ mb: 2 }}>
										<Typography variant='h3'>Monthly Active Users (MAUs)</Typography>
									</Box>
								}
								emptyState={
									<Box sx={{ bgcolor: 'background.default', height: '100%', p: 4, borderRadius: 3, display: 'flex', alignItems: 'center' }}>
										<Box>
											<Typography variant='h6' gutterBottom>
												Let's get your app connected
											</Typography>
											<Stack direction='row' spacing={2}>
												<Button variant='contained' href={getStartedLink(appType.id)} target='_blank'>
													Setup guide
												</Button>
												<CopyToClipboard
													text={app?.Key || ''}
													onCopy={() => setGlobalAlertMessage({ message: '🔑 SDK Key copied to clipboard', severity: 'success' })}
												>
													<Button startIcon={<KeyIcon />}>Copy SDK Key</Button>
												</CopyToClipboard>
											</Stack>
										</Box>
									</Box>
								}
							/>
						)}
					</Grid>
				</Grid>
				{!!app && isMobile && (
					<Box>
						<Button
							variant='text'
							size='large'
							color='inherit'
							startIcon={<KeyIcon color={showKey ? 'warning' : undefined} />}
							endIcon={<ExpandMoreIcon sx={{ transform: showKey ? 'rotate(180deg)' : undefined }} />}
							onClick={toggleShowKey}
						/>
					</Box>
				)}
				{showKey && app && <LicenceKey app={app} />}
			</Paper>
			{deleteAppDialogOpen && !!app && !!props.project && (
				<DeleteProjectAppConfirmationDialog
					open
					onClose={() => setDeleteAppDialogOpen(false)}
					closeDialog={() => setDeleteAppDialogOpen(false)}
					app={app}
					project={props.project}
					appType={appType}
				/>
			)}
		</>
	);
};

export default ProjectApp;
