import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { CardElement } from '@stripe/react-stripe-js';
import { Card } from '@stripe/stripe-js';
import React from 'react';

interface SelectOrCreateCardI {
	cards?: Card[];
	loading: boolean;
	createNewCard: boolean;
	setCreateNewCard: React.Dispatch<React.SetStateAction<boolean>>;
	selectedCard: string;
	setSelectedCard: React.Dispatch<React.SetStateAction<string>>;
}

const SelectOrCreateCard = (props: SelectOrCreateCardI) => {
	const { cards, loading, createNewCard, setCreateNewCard, selectedCard, setSelectedCard } = props;

	React.useEffect(() => {
		if (!cards?.length) {
			setCreateNewCard(true);
		} else {
			setSelectedCard(cards[0].id);
			setCreateNewCard(false);
		}
		//eslint-disable-next-line
	}, [cards]);

	if (loading) {
		return <CircularProgress />;
	}

	if (createNewCard) {
		return (
			<>
				<CardElement />
				{!!cards?.length && <Button sx={{ mt: 1 }} onClick={() => setCreateNewCard(false)}>Use saved card</Button>}
			</>
		);
	}

	return (
		<Box>
			<FormControl fullWidth size='small'>
				<InputLabel id='select-card-label'>Previously used card</InputLabel>
				<Select
					labelId='select-card-label'
					id='select-card'
					value={selectedCard}
					label='Previously used card'
					onChange={(e) => setSelectedCard(e.target.value)}
				>
					{cards?.map((card, i) => (
						<MenuItem
							key={i}
							value={card.id}
						>{`**** ${card.last4} ${card.exp_month}/${card.exp_year} (${card.brand})`}</MenuItem>
					))}
				</Select>
			</FormControl>
			<Button sx={{ mt: 1 }} onClick={() => setCreateNewCard(true)}>Add new card</Button>
		</Box>
	);
};

export default SelectOrCreateCard;
