import React from 'react';

import { Alert, Box, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { MonthlyActiveUsers } from '../../../utilities/queries';
import MauChart, { MauMonthI } from './MauChart';

const monthStrings = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export interface TotalMauChartPropsI {
	mau: MonthlyActiveUsers;
	maxMau?: number;
	activeYear?: string | number;
	setActiveYear?: React.Dispatch<React.SetStateAction<string | number>>;
}

export const mauDataIntoYears = (mauObject: MonthlyActiveUsers) => {
	const allData: MauYearsI = {};

	Object.keys(mauObject).forEach((mauKey) => {
		const mauValue = mauObject[mauKey];
		const [year, monthNum] = mauKey.split('-');

		if (allData[year]) {
			allData[year][parseInt(monthNum) - 1].mau = mauValue;
		} else {
			const newYear: MauMonthI[] = [...monthStrings.map((month) => ({ month, mau: 0 }))];
			newYear[parseInt(monthNum) - 1].mau = mauValue;
			allData[year] = newYear;
		}
	});

	const allYears = Object.keys(allData);
	const currentYear = new Date().getFullYear().toString();
	const firstYear = allYears[0];

	if (firstYear !== currentYear) {
		for (let i = parseInt(firstYear); i <= parseInt(currentYear); i++) {
			if (!allData[i]) {
				allData[i] = [...monthStrings.map((month) => ({ month, mau: 0 }))];
			}
		}
	}

	return allData;
};

interface MauYearsI {
	[year: string]: MauMonthI[];
}

const TotalMauChart = ({ mau, maxMau, activeYear, setActiveYear }: TotalMauChartPropsI) => {
	const { years, mauSortedIntoYears } = React.useMemo(() => {
		const mauSortedIntoYears = mauDataIntoYears(mau);

		return {
			mauSortedIntoYears,
			years: Object.keys(mauSortedIntoYears),
		};
	}, [mau]);

	const highestMauThisYear = React.useMemo(() => {
		// find the highest value in mauSortedIntoYears
		return mauSortedIntoYears[activeYear || years[years.length - 1]]?.reduce((aggregation, currentValue) => {
			return Math.max(aggregation, currentValue.mau);
		}, 0);
	}, [mauSortedIntoYears, activeYear]);

	React.useEffect(() => {
		setActiveYear?.(years[years.length - 1]);
	}, [years, mau]);

	if (!Object.keys(mau).length) {
		return null;
	}

	return (
		<Box sx={{ py: 3 }}>
			<Box sx={{ height: '400px' }}>
				<MauChart
					data={mauSortedIntoYears[activeYear || years[years.length - 1]]}
					showX
					maxMau={maxMau}
					infoLeft={
						<Box sx={{ pb: 2 }}>
							<Typography variant='h2'>Total MAUs</Typography>
							<Typography variant='body1' gutterBottom color='text.secondary'>
								Total Monthly Active Users across all Projects
							</Typography>
							<FormControl sx={{ minWidth: '200px', my: 2 }}>
								<InputLabel id='year'>Year</InputLabel>
								<Select
									labelId='year'
									id='year'
									value={activeYear || new Date().getFullYear()}
									label='Year'
									onChange={(event) => setActiveYear?.(event.target.value)}
									size='small'
								>
									{years.map((year, i) => (
										<MenuItem value={year} key={i}>
											{year}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					}
					infoRight={
						maxMau &&
						highestMauThisYear > maxMau && (
							<Paper sx={{ display: 'flex', p: 1 }}>
								<Box sx={{ p: 2 }}>
									<Typography>Highest MAU</Typography>
									<Typography variant='h2' color='red'>
										{highestMauThisYear}
									</Typography>
									<Typography>
										{mauSortedIntoYears[activeYear || years[years.length - 1]].find((m) => m.mau === highestMauThisYear)?.month} {activeYear}
									</Typography>
								</Box>
								<Alert severity='warning' sx={{ maxWidth: '300px' }}>
									It looks like you could exceed your MAU limit this month. Upgrade to avoid disruption.
								</Alert>
							</Paper>
						)
					}
				/>
			</Box>
		</Box>
	);
};

export default TotalMauChart;
