import { Container, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AppContext } from '../../utilities/StateProvider';

interface AdminRoutePropsI extends RouteProps {
	component: () => JSX.Element;
}

const renderMergedProps = (component: () => JSX.Element, ...rest: RouteProps[]) => {
	const finalProps = Object.assign({}, ...rest);
	return React.createElement(component, finalProps);
};

const AdminRoute = ({ component, ...rest }: AdminRoutePropsI) => {
	const { state } = useContext(AppContext);

	const isLoading = state.appAuthState.appAuthState === 'loading';
	const signedOut = state.appAuthState.appAuthState === 'signedOut';
	const isAdmin = state.appAuthState.isAdmin;

	return (
		<Route
			{...rest}
			render={(routeProps) => {
				if (isLoading) {
					return (
						<Container component={Box} pt={4}>
							<Stack spacing={2}>
								{[1, 2, 3, 4].map((v, i) => (
									<Skeleton variant='rectangular' height={200} key={i} />
								))}
							</Stack>
						</Container>
					);
				} else if (signedOut) {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: routeProps.location },
							}}
						/>
					);
				} else if (!isAdmin && !isLoading) {
					return (
						<Redirect
							to={{
								pathname: '/projects',
								state: { from: routeProps.location },
							}}
						/>
					);
				} else {
					return renderMergedProps(component, routeProps, rest);
				}
			}}
		/>
	);
};

export default AdminRoute;
