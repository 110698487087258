import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Button, Drawer, DrawerProps, IconButton, List, Paper, Stack, Toolbar, Typography } from '@mui/material';
import SidebarListItem, { SideBarListItemProps } from './SidebarListItem';
import { Box, useTheme } from '@mui/system';
import { AppContext, ColorModeContext } from '../../utilities/StateProvider';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DownloadIcon from '@mui/icons-material/Download';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SignedInMenuItems from './SignedInMenuItems';
import ApiIcon from '@mui/icons-material/Api';
import { Chat } from '@mui/icons-material';

const links: SideBarListItemProps[] = [
	{
		text: 'Demo Projects',
		url: '/demo-projects',
		icon: <ApiIcon />,
	},
	// {
	// 	text: 'Effect Templates',
	// 	url: '/effect-templates',
	// },
	{
		text: 'Pricing',
		url: '/pricing',
		icon: <AttachMoneyIcon />,
	},
	{
		text: 'Downloads',
		url: '/downloads',
		icon: <DownloadIcon />,
	},
	{
		text: 'Documentation',
		url: 'https://docs.deepar.ai/',
		target: '_blank',
		icon: <HelpOutlineIcon />,
	},
	{
		text: 'Contact sales',
		url: 'https://deepar.ai/contact#demo-form',
		target: '_blank',
		icon: <Chat />,
	},
];

export interface SidebarPropsI extends DrawerProps {
	width?: number;
	mobile?: boolean;
	closeSidebar?: () => void;
}

const Sidebar = ({ open = true, variant = 'permanent', width = 240, mobile = false, closeSidebar, ...rest }: SidebarPropsI) => {
	const { state } = useContext(AppContext);
	const signedIn = state.appAuthState.appAuthState === 'signedIn';

	const { toggleColorMode } = React.useContext(ColorModeContext);
	const theme = useTheme();

	const handleListItemClick = () => {
		if (mobile) {
			closeSidebar?.();
		}
	};

	return (
		<Drawer
			variant={variant}
			open={open}
			sx={{
				...rest.sx,
				...(width ? { width } : undefined),
				[`& .MuiDrawer-paper`]: {
					...(width ? { width } : undefined),
				},
				display: { xs: mobile ? 'flex' : 'none', md: mobile ? 'none' : 'flex' },
			}}
			{...rest}
		>
			<Stack direction='column' justifyContent='space-between' height='100%'>
				<Box>
					<Toolbar />
					{signedIn ? (
						<SignedInMenuItems onClick={handleListItemClick} />
					) : (
						<Paper sx={{ p: 2, m: 2 }}>
							<Typography variant='body1'>Powerful AR for any app. Integrate in minutes.</Typography>
							<Stack direction='row' spacing={1} mt={1}>
								<Button size='small' component={Link} to='/login'>
									Sign in
								</Button>
								<Button variant='contained' size='small' component={Link} to='/signup'>
									Start for free
								</Button>
							</Stack>
						</Paper>
					)}
					<List>
						{links.map((link, index) => (
							<SidebarListItem {...link} key={index} onClick={handleListItemClick} />
						))}
					</List>
				</Box>
				<Box p={2} sx={{ display: 'flex', justifyContent: 'right' }}>
					<IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color='inherit'>
						{theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
					</IconButton>
				</Box>
			</Stack>
		</Drawer>
	);
};

export default Sidebar;
