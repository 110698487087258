import React from 'react';

import { Autocomplete, Box, Button, Dialog, DialogContent, DialogProps, IconButton, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { AdminProjectI, AdminSingleUserI, AdminUserI, updateAdminProject, useAccumulatedAdminUsers } from '../../utilities/queries';
import ResponsiveDialog from '../../components/ResponsiveDialog';

interface ChangeProjectOwnerDialogPropsI extends DialogProps {
	closeDialog: () => void;
	project: AdminProjectI;
	user: AdminSingleUserI;
}

export interface ChangeProjectOwnerFormI {
	user: AdminUserI | null;
}

const ChangeProjectOwnerDialog = (props: ChangeProjectOwnerDialogPropsI) => {
	const { closeDialog, project, ...rest } = props;
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<ChangeProjectOwnerFormI>({
		defaultValues: {
			user: null,
		},
	});

	const { data, isLoading } = useAccumulatedAdminUsers();

	const queryClient = useQueryClient();

	const mutation = useMutation(updateAdminProject, {
		onSuccess: async () => {
			queryClient.invalidateQueries(['adminProject', project?.Id]);
			closeDialog();
		},
	});

	const handleCreateProjectApp: SubmitHandler<ChangeProjectOwnerFormI> = async (data: ChangeProjectOwnerFormI) => {
		if (data.user === null) {
			return;
		}

		try {
			await mutation.mutateAsync({
				PlanId: project.PlanId,
				MauLimit: project.MauLimit,
				Valid: project.Valid,
				ValidResponse: project.ValidResponse,
				InvalidResponse: project.InvalidResponse,
				MultiDomainAccess: project.MultiDomainAccess,
				projectId: project.Id,
				Expires: project.Expires,
				UserPoolUserId: data.user?.UserPoolUserId,
			});
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{mutation.isLoading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box>
					<Box sx={{ p: 1, textAlign: 'center' }}>
						<Typography variant='h5'>Change owner for project '{project.Name}'</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} component='form' onSubmit={handleSubmit(handleCreateProjectApp)} direction='column'>
						<Box>
							<Box sx={{ py: 2 }}>
								<Typography> Current owner: {props.user.UserAtts.Email}</Typography>
							</Box>
							<Controller
								name='user'
								control={control}
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										value={value}
										onChange={(e, v) => onChange(v)}
										id='user'
										getOptionLabel={(option) => option?.Email || '(no email)'}
										isOptionEqualToValue={(option, value) => option.UserPoolUserId === value.UserPoolUserId}
										options={data?.filter((user) => user.Email !== props.user.UserAtts.Email) || []}
										sx={{ width: 300 }}
										loading={isLoading}
										renderInput={(params) => (
											<TextField
												{...params}
												label='New owner'
												error={!!errors.user}
												helperText={!!errors.user && 'Please select a new owner for this project'}
											/>
										)}
									/>
								)}
							/>
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' type='submit'>
								Continue
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default ChangeProjectOwnerDialog;
