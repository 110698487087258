export interface Platform {
	name: string;
}

export interface EffectTemplate {
	imageURL: string;
	title: string;
	platforms: Platform[];
}

export const effectTemplates: EffectTemplate[] = [
	{
		imageURL:
			'https://static.wixstatic.com/media/46862b_8e1b365305d04ea2af03b3dce67999d2~mv2.png/v1/fill/w_440,h_750,al_c,q_90,usm_0.66_1.00_0.01/46862b_8e1b365305d04ea2af03b3dce67999d2~mv2.webp',
		title: 'Vendetta',
		platforms: [
			{
				name: 'iOS',
			},
			{
				name: 'Android',
			},
			{
				name: 'Web',
			},
		],
	},
	{
		imageURL:
			'https://static.wixstatic.com/media/46862b_a502b2cc9e66453d8528f11a534c3323~mv2.png/v1/fill/w_753,h_1284,al_c,q_90/46862b_a502b2cc9e66453d8528f11a534c3323~mv2.webp',
		title: 'Cowboi',
		platforms: [
			{
				name: 'iOS',
			},
			{
				name: 'Android',
			},
			{
				name: 'Web',
			},
		],
	},
	{
		imageURL:
			'https://static.wixstatic.com/media/1be86c_e86d7e58fd3f45528bea362d66cd3697~mv2.jpg/v1/fill/w_546,h_751,al_c,q_85,usm_0.66_1.00_0.01/1be86c_e86d7e58fd3f45528bea362d66cd3697~mv2.jpg',
		title: 'Bunny Ears',
		platforms: [
			{
				name: 'iOS',
			},
			{
				name: 'Android',
			},
			{
				name: 'Web',
			},
		],
	},
	{
		imageURL:
			'https://static.wixstatic.com/media/1be86c_d0d4ffa018fb4d7ab40c5ddbf5ed561e~mv2.jpg/v1/fill/w_880,h_1500,al_c,q_85,usm_0.66_1.00_0.01/1be86c_d0d4ffa018fb4d7ab40c5ddbf5ed561e~mv2.webp',
		title: 'Thanos',
		platforms: [
			{
				name: 'iOS',
			},
			{
				name: 'Android',
			},
			{
				name: 'Web',
			},
		],
	},
];
