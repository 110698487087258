import React from 'react';

import { Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, Skeleton, Stack, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffectTemplatesAsStudioDeveloper } from '../../utilities/queries';
import StudioDeveloperEffectTemplateItem from './StudioDeveloperEffectTemplateItem';
import CreateEditEffectTemplateDialog from './CreateEditEffectTemplateDialog';

function compareVersionNumbers(first: number[] | null, second: number[] | null) {
    if (first == null && second == null) return 0; // Nothing is equal to nothing.
    if (first != null && second == null) return 1; // First is greater than nothing.
    if (first == null && second != null) return -1; // Second is greater than nothing.
    for (let level = 0; level < Math.max(first!.length, second!.length); level++) {
        if (first![level] == null && second![level] == null) return 0; // Nothing is equal to nothing.
        if (first![level] != null && second![level] == null) return 1; // First is greater than nothing.
        if (first![level] == null && second![level] != null) return -1; // Second is greater than nothing.
        if (first![level] > second![level]) return 1;
        if (first![level] < second![level]) return -1;
    }
    return 0;
};

const StudioDeveloperEffectTemplates = () => {
    const { data, isLoading } = useEffectTemplatesAsStudioDeveloper();
    const [createEffectTemplateDialogOpen, setCreateffectTemplateDialogOpen] = React.useState(false);
    const [discontinuedHidden, setDiscontinuedHidden] = React.useState(true);
    const [versionFilter, setVersionFilter] = React.useState('');

    if (isLoading) {
        return (
            <Container component={Box} paddingY={4}>
                <Stack direction='row' spacing={4}>
                    <Skeleton variant='rectangular' height={50} />
                </Stack>
                <Stack direction='column' spacing={2}>
                    <Skeleton variant='rectangular' height={100} />
                    <Skeleton variant='rectangular' height={100} />
                    <Skeleton variant='rectangular' height={100} />
                    <Skeleton variant='rectangular' height={100} />
                </Stack>
            </Container>
        );
    }

    let filteredData = data || [];
    if (discontinuedHidden) {
        filteredData = filteredData.filter(effectTemplate => effectTemplate.MaxStudioVersion == null);
    }
    if (versionFilter.length > 0) {
        const parsedNumbers = versionFilter.split('.').map(part => parseInt(part, 10));
        const versionNumbers = [0, 0, 0, 0].map((defaultNumber, i) => parsedNumbers[i] == null || isNaN(parsedNumbers[i]) ? defaultNumber : parsedNumbers[i]);
        filteredData = filteredData
            .filter(effectTemplate => compareVersionNumbers(versionNumbers, effectTemplate.MinStudioVersion) >= 0)
            .filter(effectTemplate => effectTemplate.MaxStudioVersion == null || compareVersionNumbers(versionNumbers, effectTemplate.MaxStudioVersion) <= 0);
    }

    return (
        <Container component={Box} pt={4} pb={8}>
            <Stack paddingY={2} direction='row' justifyContent='space-between' mb={3}>
                <Box>
                    <Typography variant='h5' gutterBottom>
                        Effect Templates {isLoading ? <CircularProgress size={20} /> : `(${data?.length})`}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                        Manage which effect templates are shown on the Studio splash screen
                    </Typography>
                </Box>
                <Button startIcon={<AddIcon />} onClick={() => setCreateffectTemplateDialogOpen(true)}>
                    New Effect Template
                </Button>
            </Stack>
            {isLoading ? (
                <Stack spacing={2}>
                    {[1, 2, 3, 4].map((v, i) => (
                        <Skeleton variant='rectangular' height={200} key={i} />
                    ))}
                </Stack>
            ) : (
                <>
                    <Box>
                        <FormControlLabel control={<Checkbox size='small' checked={discontinuedHidden} onChange={(e) => setDiscontinuedHidden(e.target.checked)} />} label='Hide discontinued' />
                        <TextField size='small' value={versionFilter} onChange={(e) => setVersionFilter(e.target.value)} label='Studio version' />
                    </Box>
                    <Box>
                        {filteredData?.map(effectTemplate => (
                            <StudioDeveloperEffectTemplateItem key={effectTemplate.Id} effectTemplate={effectTemplate} />
                        ))}
                    </Box>
                </>
            )}
            {createEffectTemplateDialogOpen && <CreateEditEffectTemplateDialog open closeDialog={() => setCreateffectTemplateDialogOpen(false)} onClose={() => setCreateffectTemplateDialogOpen(false)} />}
        </Container>
    );
};

export default StudioDeveloperEffectTemplates;
