import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { ProjectI } from '../../../utilities/queries';
import RetryPaymentDialog from './RetryPaymentDialog';

interface IncompleteSubscriptionPropsI {
	project: ProjectI;
}

const IncompleteSubscription = (props: IncompleteSubscriptionPropsI) => {
	const [retryPaymentDialogOpen, setRetryPaymentDialogOpen] = React.useState(false);
	return (
		<Alert severity='warning'>
			<Stack direction='row'>
				<Box sx={{ maxWidth: '300px' }}>
					<Typography>Incomplete subscription</Typography>
					<Typography variant='body2'>This is probably because a payment failed bank authentication. Please try again.</Typography>
				</Box>
				<Button variant='contained' onClick={() => setRetryPaymentDialogOpen(true)}>
					Try again
				</Button>
			</Stack>
			{retryPaymentDialogOpen && (
				<RetryPaymentDialog
					open
					onClose={() => setRetryPaymentDialogOpen(false)}
					closeDialog={() => setRetryPaymentDialogOpen(false)}
					project={props.project}
				/>
			)}
		</Alert>
	);
};

export default IncompleteSubscription;
