import { CognitoUser } from 'amazon-cognito-identity-js';

export type AppAuthStateT = 'confirmSignIn' | 'requireNewPassword' | 'TOTPSetup' | 'signedIn' | 'signedUp' | 'signedOut' | 'loading';

export interface UserAttributesI {
	Email?: string;
	email?: string;
	AgreedToTermOfUse?: boolean;
	given_name?: string;
	family_name?: string;
	'custom:attribution_id'?: string;
	'custom:company_name'?: string;
	'custom:company_website'?: string;
	UserPoolUserId?: string;
	AccessToPrivatePlans?: string[];
}

export interface CognitoUserExtended extends CognitoUser {
	username?: string;
	attributes?: {
		given_name: string;
		family_name: string;
		email: string;
		email_verified: boolean;
		sub: string;
		address: string;
		['custom:city']: string;
		['custom:country']: string;
		['custom:zip_code']: string;
		['custom:company_name']: string;
		['custom:company_website']: string;
		['custom:send_notifications']: 'true' | 'false';
		['custom:attribution_id']: string;
		['custom:phone']: string;
		['custom:vat_id']: string;
		['custom:stripe_id']: string;
	};
}

export interface AppAuthStateI {
	isAdmin: Boolean;
	isStudioDeveloper: Boolean;
	currentUser: CognitoUserExtended | null;
	currentUserAttributes: UserAttributesI | null;
	appAuthState: AppAuthStateT;
}

export interface GlobalStateI {
	appAuthState: AppAuthStateI;
}

interface LogInActionI {
	type: 'login';
	payload: {};
}

interface LogOutActionI {
	type: 'logout';
	payload: {};
}

interface SignUpActionI {
	type: 'signup';
	payload: {};
}

interface ChangeAuthStateActionI {
	type: 'CHANGE_AUTH_STATE';
	payload: {
		isAdmin?: Boolean;
		isStudioDeveloper?: Boolean;
		currentUser?: CognitoUserExtended | null;
		currentUserAttributes?: UserAttributesI | null;
		appAuthState?: AppAuthStateT;
	};
}

export type ActionT = LogInActionI | LogOutActionI | SignUpActionI | ChangeAuthStateActionI;

const reducer = (state: GlobalStateI, action: ActionT) => {
	const { type, payload } = action;

	switch (type) {
		case 'CHANGE_AUTH_STATE':
			const newState = {
				...state,
				appAuthState: {
					...state.appAuthState,
					...(payload.isAdmin !== undefined && { isAdmin: payload.isAdmin }),
					...(payload.isStudioDeveloper !== undefined && { isStudioDeveloper: payload.isStudioDeveloper }),
					...(payload.currentUser !== undefined && { currentUser: payload.currentUser }),
					...(payload.currentUserAttributes !== undefined && { currentUserAttributes: payload.currentUserAttributes }),
					...(payload.appAuthState !== undefined && { appAuthState: payload.appAuthState }),
					...(payload.appAuthState === 'signedOut' && { currentUser: null, currentUserAttributes: null }),
				},
			};
			return newState;
		default:
			return state;
	}
};

export { reducer };
