import { Box, LinearProgress, Typography, Stack, Button, TextField } from '@mui/material';
import { Link as BrowserLink, useHistory, useParams } from 'react-router-dom';
import React from 'react';
import CustomAlert, { CustomAlertPropsI } from '../utilities/CustomAlert';
import LoginWrapper from './LoginWrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { API, Auth } from 'aws-amplify';
import { AppContext } from '../utilities/StateProvider';

interface ResetPasswordFormI {
	password: string;
}

const ResetPassword = () => {
	const { code } = useParams<{ code: string }>();
	const [loading, setLoading] = React.useState(false);
	const [alert, setAlert] = React.useState<CustomAlertPropsI>();

	const { dispatch } = React.useContext(AppContext);
	const history = useHistory();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPasswordFormI>();

	const resetPassword: SubmitHandler<ResetPasswordFormI> = async (data: ResetPasswordFormI) => {
		setLoading(true);

		const codeData = code.split('$Cd');
		const newPassword = data.password.trim();

		try {
			const _userEmail = window.atob(codeData[0]);
			const _extractedCode = codeData[1];

			await Auth.forgotPasswordSubmit(_userEmail, _extractedCode, newPassword);
			const user = await Auth.signIn(_userEmail, newPassword);
			const isAdmin = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.some((group: string) => group === 'Admin');
			const isStudioDeveloper = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.some((group: string) => group === 'StudioDeveloper');
			const currentUserAttributes = await API.get('AppsApi', '/currentUserAtts', {});

			dispatch({
				type: 'CHANGE_AUTH_STATE',
				payload: { appAuthState: 'signedIn', currentUser: user, currentUserAttributes, isAdmin, isStudioDeveloper },
			});
			history.push('/projects');
		} catch (error: any) {
			setAlert({ severity: 'warning', message: error.message });
		} finally {
			setLoading(false);
		}
	};

	return (
		<LoginWrapper>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					padding: 4,
				}}
			>
				<Typography variant='h3' textAlign='center' gutterBottom>
					Reset password
				</Typography>
				{alert && <CustomAlert {...alert} />}
				<Box sx={{ my: 1, width: '100%' }} component='form' onSubmit={handleSubmit(resetPassword)}>
					{!alert && (
						<TextField
							{...register('password', { required: true, minLength: 8 })}
							margin='normal'
							required
							fullWidth
							id='password'
							label='New Password'
							name='password'
							type='password'
							error={!!errors?.password}
							helperText={!!errors.password && 'Please enter a password that is 8 characters or longer'}
							autoFocus
						/>
					)}
					<Stack sx={{ my: 3 }} direction='column' spacing={1}>
						{!alert && (
							<Button type='submit' fullWidth variant='contained'>
								Reset password
							</Button>
						)}
						<Button component={BrowserLink} to='/login' fullWidth>
							{!alert ? 'Cancel' : 'Back to Log in'}
						</Button>
					</Stack>
				</Box>
			</Box>
		</LoginWrapper>
	);
};

export default ResetPassword;
