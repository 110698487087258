import { Box, Button, Dialog, DialogContent, DialogProps, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import { ResourceSetI } from '../../utilities/queries';
import { API } from 'aws-amplify';
import { AppContext } from '../../utilities/StateProvider';
import ResponsiveDialog from '../../components/ResponsiveDialog';

interface DeleteResourceSetConfirmationDialogPropsI extends DialogProps {
	closeDialog: () => void;
	resourceSet: ResourceSetI;
}

const DeleteResourceSetConfirmationDialog = (props: DeleteResourceSetConfirmationDialogPropsI) => {
	const { resourceSet, closeDialog, ...rest } = props;

	const queryClient = useQueryClient();

	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const deleteResourceSet = async () => {
		return await API.del('AppsApi', '/resourceSets/' + resourceSet.Id, {});
	};

	const mutation = useMutation(deleteResourceSet, {
		onSuccess: async () => {
			queryClient.invalidateQueries('resourceSets');
			setGlobalAlertMessage({ message: 'Resource set deleted' });
			closeDialog();
		},
	});

	const handleDeleteResourceSet = async () => {
		try {
			await mutation.mutateAsync();
		} catch (error: any) {
			setGlobalAlertMessage({ message: error.message, severity: 'error' });
			console.error(error);
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{mutation.isLoading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '600px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Delete resource set?</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box py={2}>
						<Typography>This resource set and associated files will be deleted. Are you sure you want to go ahead?</Typography>
					</Box>
					<Stack direction='row' spacing={1} mt={3}>
						<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
							Cancel
						</Button>
						<Button variant='contained' color='error' fullWidth size='large' onClick={() => handleDeleteResourceSet()}>
							Delete resource set
						</Button>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default DeleteResourceSetConfirmationDialog;
