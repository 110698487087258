import React from 'react';

import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../utilities/StateProvider';
import { DemoProject, DemoProjectCode } from '../../../Models/demoProjects';
import { ProjectI, useProjects } from '../../../utilities/queries';
import CreateProjectDialog from '../../Projects/CreateProjectDialog';
import { Elements } from '@stripe/react-stripe-js';
import CreateProjectAppDialog from '../../Projects/Project/CreateProjectAppDialog';
import { appTypes } from '../../Projects/Project/ProjectApp/ProjectApp';
import LicenceKey from '../../Projects/Project/ProjectApp/LicenceKey';

interface SelectOrCreateProjectPropsI {
	demoProject: DemoProject;
	code: DemoProjectCode;
}

const SelectOrCreateProject = (props: SelectOrCreateProjectPropsI) => {
	const { state, stripePromise } = React.useContext(AppContext);
	const signedIn = state.appAuthState.appAuthState === 'signedIn';

	const { data: projects, isLoading } = useProjects();

	const [selectedProject, setSelectedProject] = React.useState<ProjectI | null>(projects?.[0] || null);
	const [createProjectDialogOpen, setCreateProjectDialogOpen] = React.useState(false);
	const [createProjectAppDialogOpen, setCreateProjectAppDialogOpen] = React.useState(false);

	const appForPlatformType = selectedProject?.apps?.find((app) => app.Platform === props.code.Platform.toLowerCase());

	React.useEffect(() => {
		if (!!projects?.length) {
			setSelectedProject(projects[0]);
		}
	}, [projects]);

	if (!signedIn) {
		return (
			<Button component={Link} variant='contained' to={{ pathname: '/login', state: { from: `/demo-projects/${props.demoProject.Id}` } }}>
				Sign in to create project
			</Button>
		);
	} else {
		return (
			<>
				<Box>
					{!!projects?.length && selectedProject && (
						<Autocomplete
							value={selectedProject}
							onChange={(e, value) => setSelectedProject(value)}
							disablePortal
							fullWidth
							options={projects || []}
							getOptionLabel={(option) => option.Name}
							isOptionEqualToValue={(option, value) => option.Id === value.Id}
							renderInput={(params) => <TextField {...params} label='Project' />}
							disableClearable
							loading={isLoading}
							sx={{ mb: 1 }}
						/>
					)}
					<Button variant={projects?.length ? 'outlined' : 'contained'} onClick={() => setCreateProjectDialogOpen(true)} size='small'>
						Create new project
					</Button>
				</Box>
				<Box sx={{ mt: 3 }}>
					{!!appForPlatformType ? (
						<Box>
							<Typography variant='body1' gutterBottom>
								Copy your DeepAR licence key. You'll paste it into your project code soon.
							</Typography>
							<LicenceKey app={appForPlatformType} />
						</Box>
					) : (
						<Button variant={!!appForPlatformType ? 'outlined' : 'contained'} onClick={() => setCreateProjectAppDialogOpen(true)}>
							Link {props.code.Platform} app
						</Button>
					)}
				</Box>
				{createProjectDialogOpen && (
					<Elements stripe={stripePromise}>
						<CreateProjectDialog open setCreateProjectOpen={setCreateProjectDialogOpen} onClose={() => setCreateProjectDialogOpen(false)} redirect={false} />
					</Elements>
				)}
				{createProjectAppDialogOpen && selectedProject && (
					<Elements stripe={stripePromise}>
						<CreateProjectAppDialog
							open
							closeDialog={() => setCreateProjectAppDialogOpen(false)}
							onClose={() => setCreateProjectAppDialogOpen(false)}
							appType={appTypes.find((at) => at.id === props.code.Platform.toLowerCase()) || appTypes[0]}
							project={selectedProject}
						/>
					</Elements>
				)}
			</>
		);
	}
};

export default SelectOrCreateProject;
