import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material';
import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer, LineChart, Line, YAxis, CartesianGrid, ReferenceLine } from 'recharts';
import { MonthlyActiveUsers } from '../../../utilities/queries';

export interface MauMonthI {
	month: string;
	mau: number;
	mauOver?: number;
}

export interface MauChartPropsI {
	tiny?: boolean;
	data?: MauMonthI[];
	showX?: boolean;
	infoLeft?: React.ReactNode;
	infoRight?: React.ReactNode;
	emptyState?: React.ReactNode;
	maxMau?: number;
}

export const transformForMauChart = (monthStrings: string[], mau?: MonthlyActiveUsers) => {
	return monthStrings?.map((monthString) => ({
		month: monthString,
		mau: mau?.[monthString] || 0,
	}));
};

const MauChart = (props: MauChartPropsI) => {
	const { tiny, data: receivedData, showX, infoLeft, infoRight, emptyState, maxMau } = props;
	const theme = useTheme();


	const data = React.useMemo(() => {
		return (
			receivedData?.map((month) => {
				const { month: monthString, mau } = month;
				if (maxMau && mau > maxMau) {
					return {
						...month,
						mau: maxMau,
						mauOver: mau - maxMau,
					};
				} else {
					return month;
				}
			}) || []
		);
	}, [receivedData, maxMau]);

	if (!!emptyState && data.reduce((aggregation, currentValue) => aggregation + currentValue.mau, 0) <= 0) {
		return <Box sx={{ textAlign: 'left', width: '100%', pl: 5, height: '100%' }}>{emptyState}</Box>;
	}

	return (
		<Stack height='100%' width='100%' direction='column'>
			{!tiny && (
				<Stack direction='row' textAlign='left' justifyContent='space-between'>
					<Box>{infoLeft && infoLeft}</Box>
					<Box>{infoRight && infoRight}</Box>
				</Stack>
			)}
			<Box
				height='100%'
				width='100%'
				sx={{
					'& .recharts-tooltip-label': {
						color: theme.palette.primary.dark,
					},
				}}
			>
				<ResponsiveContainer width='100%' height='100%' minHeight='40px' minWidth='80px'>
					{tiny ? (
						<LineChart data={data}>
							<XAxis dataKey='month' hide />
							<Tooltip />
							<Line type='monotone' dataKey='mau' stroke={theme.palette.primary.main} strokeWidth={2} dot={false} />
						</LineChart>
					) : (
						<BarChart data={data} barCategoryGap={2}>
							<XAxis dataKey='month' hide={!showX} />
							<YAxis />
							<CartesianGrid stroke={theme.palette.text.secondary} opacity={0.2} />
							<Tooltip />
							<Bar dataKey='mau' stackId='a' fill={theme.palette.primary.main} />
							<Bar dataKey='mauOver' stackId='a' fill={theme.palette.error.main} />
							{maxMau && <ReferenceLine y={maxMau} stroke='red' strokeDasharray={'6, 6'} strokeWidth='2' style={{ zIndex: 1000 }} />}
						</BarChart>
					)}
				</ResponsiveContainer>
			</Box>
		</Stack>
	);
};

export default MauChart;
