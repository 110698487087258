import React, { useContext } from 'react';
import { CssBaseline } from '@mui/material';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppContext } from '../utilities/StateProvider';
import { styled } from '@mui/system';
import LoginWrapper from './LoginWrapper';
import StudioLogin from './StudioLogin';

interface LoginRoutePropsI extends RouteProps {
	component: () => JSX.Element;
}

const renderMergedProps = (component: () => JSX.Element, ...rest: RouteProps[]) => {
	return React.createElement(component, { ...rest });
};

const LoginRoute = ({ component, ...props }: LoginRoutePropsI) => {
	const { state } = useContext(AppContext);
	const signedIn = state.appAuthState.appAuthState === 'signedIn';
	const studioLogin = window.sessionStorage.getItem('studioLogin');
	const isLoading = state.appAuthState.appAuthState === 'loading';

	const Main = styled('main')({
		flexGrow: 1,
	});

	return (
		<Route
			{...props}
			render={(routeProps) => {
				if (isLoading) {
					return (
						<Main>
							<LoginWrapper>
								<CssBaseline />
							</LoginWrapper>
						</Main>
					);
				} else if (studioLogin && signedIn) {
					return <StudioLogin />;
				} else if (signedIn) {
					return <Redirect to={'/projects'} />;
				} else {
					return renderMergedProps(component, routeProps, props);
				}
			}}
		/>
	);
};

export default LoginRoute;
