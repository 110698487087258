import React from 'react';

import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import { useDemoProjects } from '../../utilities/queries';
import DemoProjectsCard from './DemoProjectsCard';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

const DemoProjects = () => {
	const { data: demoProjects, isLoading } = useDemoProjects();

	if (isLoading) {
		return (
			<Container component={Box} paddingY={4}>
				<Skeleton variant='rectangular' height={70} sx={{ mb: 2, maxWidth: 500 }} />
				<Skeleton variant='rectangular' height={50} sx={{ mb: 2, maxWidth: 300 }} />
				<Grid container rowSpacing={2} columnSpacing={2} alignItems='stretch'>
					{new Array(9).fill(0).map((_, i) => (
						<Grid item xs={12} sm={6} md={4} key={i}>
							<Skeleton variant='rectangular' height={300} />
						</Grid>
					))}
				</Grid>
			</Container>
		);
	}

	return (
		<>
			<ScrollToTopOnMount />
			<Container component={Box} pb={8} pt={4}>
				<Box sx={{ py: 3, pb: 6 }}>
					<Typography variant='h1' gutterBottom>
						Demo Projects
					</Typography>
					<Typography variant='body1' color='text.secondary'>
						Get started fast with easy project templates for popular AR app formats
					</Typography>
				</Box>
				<Grid container rowSpacing={2} columnSpacing={2} alignItems='stretch'>
					{demoProjects?.map((project, i) => (
						<DemoProjectsCard key={i} {...project} />
					))}
				</Grid>
			</Container>
		</>
	);
};

export default DemoProjects;
