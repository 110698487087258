import React from 'react';

import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface SnappyAccordionProps {
	title: string;
	children: React.ReactNode;
}

const SnappyAccordion = ({ title, children }: SnappyAccordionProps) => {
	const [open, setOpen] = React.useState(false);

	return (
		<Box sx={{ borderBottom: '1px solid', borderColor: (theme) => theme.palette.divider }}>
			<Box onClick={() => setOpen(!open)} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', cursor: 'pointer', alignItems: 'center' }}>
				<Typography variant='body1'>{title}</Typography>
				<ExpandMoreIcon sx={{ transform: open ? 'rotate(0.5turn)' : '' }} />
			</Box>
			<Box sx={{ display: open ? 'block' : 'none', p: 2, pt: 0 }}>{children}</Box>
		</Box>
	);
};

export default SnappyAccordion;
