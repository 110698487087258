import React from 'react';
import { Link } from 'react-router-dom';

import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Container,
	FormControl,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	TableSortLabel,
	TablePagination,
} from '@mui/material';

import { AdminProjectI, useAccumulatedAdminProjects } from '../../utilities/queries';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { useHistory, useLocation } from 'react-router-dom';
import { getMonthString, lastMonthStrings } from '../../utilities/helpers';
import { PricePlanI, pricePlans, privatePricePlans } from '../../pricePlans';
import { projectNameFilter, projectUserEmailFilter, projectPlanFilter, projectValidityFilter, projectIssueFilter, projectMultiDomainAccessFilter } from './projectFilters';
import MauChart, { transformForMauChart } from '../Projects/Project/MauChart';

type ValidityFilterT = 'valid' | 'invalid';
const validityFilterOptions: FilterStatesI['projectValidity'][] = ['valid', 'invalid'];

export interface IssuesFilterI {
	text: string;
	value: 'no-issues' | 'mau-exceeded' | 'payment-error';
}
const issuesFilterOptions: IssuesFilterI[] = [
	{
		text: 'No Issues',
		value: 'no-issues',
	},
	{
		text: 'Mau Exceeded',
		value: 'mau-exceeded',
	},
	{
		text: 'Payment Error',
		value: 'payment-error',
	},
];

export interface MultiDomainAccessFilterI {
	text: string;
	value: 'no-multi-domain-access' | 'has-multi-domain-access';
}

const multiDomainAccessOptions: MultiDomainAccessFilterI[] = [
	{
		text: 'Has Multi-domain access',
		value: 'has-multi-domain-access',
	},
	{
		text: 'No Multi-domain access',
		value: 'no-multi-domain-access',
	},
];

interface FilterStatesI {
	projectNameSearch: string;
	projectEmailSearch: string;
	projectPlansSearch: PricePlanI[];
	projectValidity: ValidityFilterT | '';
	projectIssue: IssuesFilterI | null;
	projectMultiDomain: MultiDomainAccessFilterI | null,
	page: number;
}

const AdminProjects = () => {
	const history = useHistory();
	const useSearchParams = () => new URLSearchParams(useLocation().search);
    const searchParams = useSearchParams();

	const currentPath = useLocation().pathname;
	const currentSearchParams = useLocation().search;
	const page = parseInt(searchParams.get('page') || '1', 10);


	const [projectNameSearch, setProjectNameSearch] = React.useState<FilterStatesI['projectNameSearch']>('');
	const [projectEmailSearch, setProjectEmailSearch] = React.useState<FilterStatesI['projectEmailSearch']>('');
	const [projectPlansSearch, setProjectPlansSearch] = React.useState<FilterStatesI['projectPlansSearch']>([]);
	const [projectValidity, setProjectValidity] = React.useState<FilterStatesI['projectValidity']>('');
	const [projectIssue, setProjectIssue] = React.useState<FilterStatesI['projectIssue']>(null);
	const [projectMultiDomain, setProjectMultiDomain] = React.useState<FilterStatesI['projectMultiDomain']>(null);

	React.useEffect(() => {
		setProjectNameSearch(searchParams.get('name') || '');
		setProjectEmailSearch(searchParams.get('email') || '');
		const planIds = searchParams.get('plans')?.split('.');
		setProjectPlansSearch([...pricePlans, ...privatePricePlans].filter((p) => planIds?.includes(p.id)) || []);
		setProjectValidity((searchParams.get('validity') as ValidityFilterT) || '');
		setProjectIssue(issuesFilterOptions.find((issue) => issue.value === searchParams.get('issue')) || null);
		setProjectMultiDomain(multiDomainAccessOptions.find((multiDomainOption) => multiDomainOption.value === searchParams.get('multidomain')) || null);
		//eslint-disable-next-line
	}, [currentSearchParams]);

	const filterStates: FilterStatesI = {
		projectNameSearch,
		projectEmailSearch,
		projectPlansSearch,
		projectValidity,
		projectIssue,
		projectMultiDomain,
		page,
	};

	const { data, isLoading, isFetching } = useAccumulatedAdminProjects();

	const [mauMonthCount, setMauMonthCount] = React.useState(3);
	const [mauSortDescending, setMauSortDescending] = React.useState(true);
	const [rowsPerPage, setRowsPerPage] = React.useState(50);


	const monthStrings = React.useMemo(() => {
		return lastMonthStrings(mauMonthCount);
	}, [mauMonthCount]);

	const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			applyFilters(filterStates);
		}
	};

	const applyFilters = (filterStates: FilterStatesI) => {
		history.push({
			search:
				'?' +
				new URLSearchParams({
					...((filterStates.projectNameSearch && { name: filterStates.projectNameSearch }) || undefined),
					...((filterStates.projectEmailSearch && { email: filterStates.projectEmailSearch }) || undefined),
					...((filterStates.projectPlansSearch.length && {
						plans: filterStates.projectPlansSearch.map((p) => p.id).join('.'),
					}) ||
						undefined),
					...((filterStates.projectValidity && { validity: filterStates.projectValidity }) || undefined),
					...((filterStates.projectIssue && { issue: filterStates.projectIssue.value }) || undefined),
					...((filterStates.projectMultiDomain && { multidomain: filterStates.projectMultiDomain.value }) || undefined),
					...((filterStates.page > 1 && { page: filterStates.page.toString() }) || undefined),
				}).toString(),
		});
	};

	const handleUpdateSearchPlans = (e: React.SyntheticEvent<Element, Event>, value: PricePlanI[]) => {
		setProjectPlansSearch(value);
		applyFilters({
			...filterStates,
			projectPlansSearch: value,
		});
	};

	const handleUpdateValiditySearch = (value: FilterStatesI['projectValidity']) => {
		setProjectValidity(value);
		applyFilters({
			...filterStates,
			projectValidity: value,
		});
	};

	const handleUpdateIssuesSearch = (value: FilterStatesI['projectIssue']) => {
		setProjectIssue(value);
		applyFilters({
			...filterStates,
			projectIssue: value,
		});
	};

	const handleUpdateMultiDomainFilter = (value: FilterStatesI['projectMultiDomain']) => {
		setProjectMultiDomain(value);
		applyFilters({
			...filterStates,
			projectMultiDomain: value,
		});
	};

	const compareProjectsByMonthlyMau = (project1: AdminProjectI, project2: AdminProjectI, monthStrings: string[]) => {
		const sum1 = project1.Mau ? monthStrings.map((monthString) => (project1?.Mau && project1.Mau[monthString]) || 0).reduce((a, b) => a + b, 0) : 0;
		const sum2 = project2.Mau ? monthStrings.map((monthString) => (project2?.Mau && project2.Mau[monthString]) || 0).reduce((a, b) => a + b, 0) : 0;
		return sum1 - sum2;
	};

	const projectsFilter = (project: AdminProjectI) => {
		return (
			projectNameFilter(project, searchParams.get('name')) &&
			projectUserEmailFilter(project, searchParams.get('email')) &&
			projectPlanFilter(project, searchParams.get('plans')) &&
			projectValidityFilter(project, searchParams.get('validity')) &&
			projectIssueFilter(project, searchParams.get('issue') as IssuesFilterI['value']) && 
			projectMultiDomainAccessFilter(project, searchParams.get('multidomain') as MultiDomainAccessFilterI['value'])
		);
	};

	const shownProjects = React.useMemo(() => {
		const shownProjects = data?.filter(projectsFilter).sort((p1, p2) => compareProjectsByMonthlyMau(p1, p2, monthStrings));
		if (mauSortDescending) {
			return shownProjects?.reverse() || [];
		}
		return shownProjects || [];
		//eslint-disable-next-line
	}, [data, searchParams, mauSortDescending]);

	const shownProjectsPaged = React.useMemo(() => {
		return shownProjects.slice((page - 1) * rowsPerPage, page * rowsPerPage);
	}, [shownProjects, searchParams, rowsPerPage]);

	return (
		<Container component={Box} py={5}>
			<Stack py={2} spacing={1} direction='row' justifyContent='space-between' alignItems='center'>
				<Typography variant='h5' gutterBottom>
					Projects {isLoading || isFetching ? <CircularProgress size={18} /> : `(${shownProjects?.length})`}
				</Typography>
				<Stack py={2} spacing={1} direction='row' alignContent='baseline'>
					<TextField
						label='Months of MAU'
						variant='outlined'
						type='number'
						size='small'
						value={mauMonthCount}
						onChange={(e) => setMauMonthCount(parseInt(e.target.value))}
					/>
				</Stack>
			</Stack>
			<Stack py={2} spacing={1} direction={'row'} alignContent='baseline' alignItems={'center'}>
				<Box sx={{ flexGrow: 1 }}>
					<Stack direction='row' alignContent='baseline'>
						<TextField
							id='searchProjectName'
							label='Project name'
							size='small'
							variant='standard'
							value={projectNameSearch}
							onChange={(e) => setProjectNameSearch(e.target.value)}
							onKeyDown={handleEnter}
							sx={{  mr: 2  }}
						/>
						<TextField
							id='searchProjectEmail'
							label='Email'
							size='small'
							variant='standard'
							value={projectEmailSearch}
							onChange={(e) => setProjectEmailSearch(e.target.value)}
							onKeyDown={handleEnter}
							sx={{  mr: 2 }}
						/>
					</Stack>
					<Stack direction='row' alignContent='baseline'>
						<FormControl sx={{ m: 1, width: 300 }}>
							<Autocomplete
								multiple
								id='search-plans'
								options={[...pricePlans, ...privatePricePlans]}
								getOptionLabel={(option) => option.name}
								value={projectPlansSearch}
								onChange={handleUpdateSearchPlans}
								renderInput={(params) => <TextField {...params} variant='standard' label='Plans' />}
							/>
						</FormControl>
						<FormControl sx={{ m: 1, width: 200 }}>
							<Autocomplete
								id='search-validity'
								options={validityFilterOptions}
								value={projectValidity || null}
								onChange={(e, value) => handleUpdateValiditySearch(value || '')}
								renderInput={(params) => <TextField {...params} variant='standard' label='Validity' />}
							/>
						</FormControl>
						<FormControl sx={{ m: 1, width: 200 }}>
							<Autocomplete
								id='search-issues'
								options={issuesFilterOptions}
								value={projectIssue || null}
								isOptionEqualToValue={(option, value) => option.value === value.value}
								onChange={(e, value) => handleUpdateIssuesSearch(value)}
								getOptionLabel={(option) => option.text}
								renderInput={(params) => <TextField {...params} variant='standard' label='Issues' />}
							/>
						</FormControl>
						<FormControl sx={{ m: 1, width: 200 }}>
							<Autocomplete
								id='filter-multidomainaccess'
								options={multiDomainAccessOptions}
								value={projectMultiDomain || null}
								isOptionEqualToValue={(option, value) => option.value === value.value}
								onChange={(e, value) => handleUpdateMultiDomainFilter(value)}
								getOptionLabel={(option) => option.text}
								renderInput={(params) => <TextField {...params} variant='standard' label='Multi-domain' />}
							/>
						</FormControl>
					</Stack>
				</Box>
				<Button size='small' onClick={() => history.push(currentPath)}>
					Clear
				</Button>
			</Stack>
			<TableContainer component={Paper}>
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>Project Name</TableCell>
							<TableCell>User email</TableCell>
							<TableCell>Details</TableCell>
							<TableCell>Plan</TableCell>
							<TableCell>Valid</TableCell>
							<TableCell onClick={() => setMauSortDescending(!mauSortDescending)} sx={{ cursor: 'pointer' }}>
								<Stack direction='row' justifyContent='space-between'>
									<Box>
										MAU
										<Typography variant='body2' color='text.secondary'>
											{monthStrings.map((ms) => `${getMonthString(new Date(ms).getMonth())}`).join(', ')}
										</Typography>
									</Box>

									<TableSortLabel active direction={mauSortDescending ? 'desc' : 'asc'} />
								</Stack>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{shownProjectsPaged &&
							shownProjectsPaged.map((project, i) => (
								<TableRow
									component={Link}
									key={project.Id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
									to={`/admin/projects/${project.Id}`}
									hover
								>
									<TableCell component='th' scope='row' sx={{ maxWidth: '180px' }}>
										{project.Name}
									</TableCell>
									<TableCell>{project?.user?.Email}</TableCell>
									<TableCell>
										{project.InvalidMau ? 'Exceeded MAU limit (' + project.InvalidMauDay + ' days)' : ''}
										{project.InvalidMau && project.InvalidPayment ? ', ' : ''}
										{project.InvalidPayment ? 'Payment error (' + project.InvalidPaymentDay + ' days)' : ''}
									</TableCell>
									<TableCell>{`${project.PlanId} ${project.MultiDomainAccess ? '(multi-domain)' : ''}`}</TableCell>
									<TableCell>{project.Valid ? <CheckIcon color='success' /> : <WarningIcon color='warning' />}</TableCell>
									<TableCell sx={{ maxWidth: '100px' }}>
										<MauChart tiny data={transformForMauChart(monthStrings, project.Mau)} />
										{/* <Typography>{monthStrings.map((monthString, i) => project?.Mau?.[monthString] || 0).join(', ')}</Typography> */}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{/* <Box sx={{ w: 'full', display: 'flex', p: 4, justifyContent: 'center' }}>
				<Pagination count={Math.ceil(shownProjects.length / PER_PAGE)} />
			</Box> */}
			<TablePagination
				component='div'
				count={shownProjects.length}
				page={page - 1}
				onPageChange={(e, page) => applyFilters({ ...filterStates, page: page + 1 })}
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
				rowsPerPageOptions={[20, 50, 100]}
			/>
		</Container>
	);
};

export default AdminProjects;
