import React from 'react';

import { Box, Button, DialogContent, DialogProps, IconButton, LinearProgress, Link, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ProjectAppTypeI } from './ProjectApp/ProjectApp';
import { createProjectApp, ProjectI } from '../../../utilities/queries';
import { useMutation, useQueryClient } from 'react-query';
import ResponsiveDialog from '../../../components/ResponsiveDialog';
import trackEvent from '../../../tracking';
import { sendCustomGAEvent } from '../../../utilities/tracking';

interface CreateProjecAppDialogPropsI extends DialogProps {
	appType: ProjectAppTypeI;
	project?: ProjectI;
	closeDialog: () => void;
}

export interface CreateProjectAppFormI {
	projectId: string;
	appName: string;
	appId: string;
	platform: string;
}

const CreateProjectAppDialog = (props: CreateProjecAppDialogPropsI) => {
	const { appType, project, closeDialog, ...rest } = props;
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CreateProjectAppFormI>({
		defaultValues: {
			appName: appType.title,
			projectId: project?.Id,
			platform: appType.id,
		},
	});

	const queryClient = useQueryClient();

	const mutation = useMutation(createProjectApp, {
		onSuccess: async (_data, variables) => {
			trackEvent('App Created', { project: variables.projectId, appBundleId: variables.appId });
			sendCustomGAEvent({ category: 'Project App', action: 'Create', label: appType.id });
			queryClient.invalidateQueries(['project', project?.Id]);
			queryClient.invalidateQueries(['projects']);
			closeDialog();
		},
	});

	const handleCreateProjectApp: SubmitHandler<CreateProjectAppFormI> = async (data: CreateProjectAppFormI) => {
		try {
			await mutation.mutateAsync(data);
		} catch (error: any) {
			console.error(error);
		}
	};

	const appIdLabel = React.useMemo(() => {
		switch (appType.id) {
			case 'ios':
			case 'macos':
				return 'Bundle ID';
			case 'android':
				return 'App ID';
			case 'web':
				return 'Domain Name';
		}
	}, [appType]);

	const appIdHelp = React.useMemo(() => {
		switch (appType.id) {
			case 'ios':
			case 'macos':
				return (
					<Typography>
						What is a{' '}
						<Link href='https://cocoacasts.com/what-are-app-ids-and-bundle-identifiers/' target='_blank'>
							Bundle ID?
						</Link>
					</Typography>
				);
			case 'android':
				return (
					<Typography>
						What is an{' '}
						<Link href='https://cocoacasts.com/what-are-app-ids-and-bundle-identifiers/' target='_blank'>
							App ID?
						</Link>
					</Typography>
				);
			case 'web':
				return (
					<Typography>
						Please enter pure domain name not full url. Read more{' '}
						<Link href='https://docs.deepar.ai/deepar-sdk/platforms/web/getting-started#deepar-license-key' target='_blank'>
							here.
						</Link>
					</Typography>
				);
		}
	}, [appType]);

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{mutation.isLoading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Create {appType.title}</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack
						sx={{ mt: 1, textAlign: 'left', width: '100%' }}
						component='form'
						onSubmit={handleSubmit(handleCreateProjectApp)}
						direction='column'
						noValidate
					>
						<Box>
							<TextField
								{...register('appId', {
									required: {
										value: true,
										message: `Please enter ${appIdLabel}`,
									},
									pattern: {
										value: /^\S*$/,
										message: `Spaces aren't allowed in your ${appIdLabel}`,
									},
									validate: (value) => {

										if (appType.id === 'web') {
											// regex to check if value is a valid url without http(s)://
											const urlRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

											// regex to test if value is a wildcard subdomain url for web
											const wildcardSubdomainUrlRegex = /^\*\.([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

											// regex to check if value contains https:// or http://
											const httpRegex = /^(http|https):\/\//;

											if (httpRegex.test(value)) {
												return 'Please enter pure domain without https:// or http://';
											}

											if (!!wildcardSubdomainUrlRegex.test(value)) {
												if (!project?.MultiDomainAccess) {
													return 'Wildcard domains are not enabled for this project, please contact support.';
												}
											} else {
												if (!urlRegex.test(value)) {
													return 'Please enter a valid domain name.';
												}
											}
										}
									}
								})}
								margin='normal'
								required
								fullWidth
								id='appId'
								label={appIdLabel}
								name='appId'
								error={!!errors?.appId}
								helperText={!!errors?.appId && errors.appId.message}
								autoFocus
							/>
							{appIdHelp}
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' type='submit'>
								Continue
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default CreateProjectAppDialog;
