import { styled } from '@mui/system';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';

const SSOButton = styled(ButtonUnstyled)`
	font-family: Avenir, sans-serif;
	font-weight: bold;
	font-size: 1rem;
	text-transform: uppercase;
	background-color: #ffffff;
	padding: 10px 20px;
	border-radius: 4px;
	transition: all 150ms ease;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	border: 1px solid #b3b9c2;

	&:hover {
		background-color: #eaeaea;
	}

	&.${buttonUnstyledClasses.active} {
		background-color: #cdcdcd;
	}

	&.${buttonUnstyledClasses.focusVisible} {
		box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
		outline: none;
	}

	&.${buttonUnstyledClasses.disabled} {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

export default SSOButton;
