import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Template from '../Template/Template';

const NotFound = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				py: '20%',
				textAlign: 'center',
			}}
		>
			<Box sx={{ pb: 4 }}>
				<Typography variant='h1' gutterBottom>
					Page not found 🙈
				</Typography>
				<Typography variant='body1'>Oops, the page you are looking for doesn't exist or has been moved. Sorry about that!</Typography>
			</Box>
			<Stack direction='row' gap={2}>
				<Button variant='contained' component={Link} to='/'>
					Home
				</Button>
				<Button href='https://deepar.ai/contact/' target='_blank'>
					Contact us
				</Button>
			</Stack>
		</Box>
	);
};

export default NotFound;
