import React from 'react';

import { Box, CircularProgress, useTheme } from '@mui/material';
import { ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line } from 'recharts';
import { useAccumulatedAdminProjects } from '../../../utilities/queries';
import DateRange, { DateRangeI } from '../../../components/DateRange';

interface DateCountChartPropsI {
	date: string;
	count: number;
}

const NewProjectGraph = () => {
	const thirtyDaysAgo = new Date();
	thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

	const theme = useTheme();
	const [dateRange, setDateRange] = React.useState<DateRangeI>({
		startDate: thirtyDaysAgo,
		endDate: new Date(),
	});

	const { data: projects, isLoading, isFetching } = useAccumulatedAdminProjects();

	// prepare projects into daily signup counts for chart
	const dailySignupCounts = React.useMemo(() => {
		const counts: DateCountChartPropsI[] = [];

		if (!projects) {
			return counts;
		}

		const projectsSortedByDate = projects
			?.filter((p) => !!p?.CreatedAt)
			.sort((a, b) => {
				const aDate = new Date(a?.CreatedAt || 0);
				const bDate = new Date(b?.CreatedAt || 0);
				return aDate.getTime() - bDate.getTime();
			});

		const oldestDate = new Date(projectsSortedByDate[0]?.CreatedAt || 0);

		// create an array of dates from the oldest date to today
		const dateArray = Array.from({ length: Math.ceil((dateRange.endDate.getTime() - dateRange.startDate.getTime() + 1) / (1000 * 3600 * 24)) }, (_, i) => {
			const date = new Date(dateRange.startDate.getTime() + i * 1000 * 3600 * 24);
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${day}-${month}-${year}`;
		});

		const dateCounts = dateArray.reduce((aggregation, currentValue) => {
			const count = projectsSortedByDate.reduce((_aggregation, _currentValue) => {
				const date = new Date(_currentValue?.CreatedAt || 0);
				const day = date.getDate();
				const month = date.getMonth() + 1;
				const year = date.getFullYear();
				const dateString = `${day}-${month}-${year}`;
				if (dateString === currentValue) {
					return _aggregation + 1;
				}
				return _aggregation;
			}, 0);

			aggregation.push({ date: currentValue, count });
			return aggregation;
		}, [] as { date: string; count: number }[]);

		return dateCounts;
	}, [projects, dateRange]);

	return (
		<Box>
			<Box
				height='400px'
				width='100%'
				sx={{
					position: 'relative',
					'& .recharts-tooltip-label': {
						color: theme.palette.primary.dark,
					},
					my: 4,
				}}
			>
				<DateRange range={dateRange} onChange={(newRange) => setDateRange(newRange)} />
				{isLoading && <CircularProgress size={40} sx={{ position: 'absolute', left: '50%', top: '50%' }} />}
				<ResponsiveContainer width='100%' height='100%' minHeight='40px' minWidth='80px'>
					<LineChart data={dailySignupCounts} barCategoryGap={2}>
						<XAxis dataKey='date' />
						<YAxis />
						<CartesianGrid stroke={theme.palette.text.secondary} opacity={0.2} />
						<Tooltip />
						<Line dataKey='count' strokeWidth={2} fill={theme.palette.primary.main} dot={false} />
					</LineChart>
				</ResponsiveContainer>
			</Box>
		</Box>
	);
};

export default NewProjectGraph;
