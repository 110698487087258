import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { EffectTemplate } from './effectTemplates'

const EffectTemplatesCard = (props: EffectTemplate) => {
  return (
    <Grid item xs={12} sm={4} md={3}>
      <Card sx={{ borderRadius: 4 }}>
        <CardMedia
          component='img'
          height='220'
          image={props.imageURL}
          alt={props.title}
        />
        <CardContent>
          <Box paddingBottom={2}>
            <Typography gutterBottom variant='h3' component='div'>
              {props.title}
            </Typography>
          </Box>
          <Stack direction='row' spacing={0.5} paddingBottom={1}>
            {props.platforms.map((platform, i) => (
              <Chip label={platform.name} key={i} />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default EffectTemplatesCard
