import React from 'react';
import { Link as BrowserLink } from 'react-router-dom';

import { Box, Button, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import LoginWrapper from '../LoginWrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import CustomAlert, { CustomAlertPropsI } from '../../utilities/CustomAlert';

interface ForgotPasswordFormI {
	email: string;
}

const ForgotPassword = () => {
	const [loading, setLoading] = React.useState(false);
	const [alert, setAlert] = React.useState<CustomAlertPropsI>();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ForgotPasswordFormI>();

	const sendResetLink: SubmitHandler<ForgotPasswordFormI> = async (data: ForgotPasswordFormI) => {
		setLoading(true);
		const email = data.email.trim();

		try {
			await Auth.forgotPassword(email);
			setAlert({
				severity: 'success',
				message: `If an account exists for ${email}, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.`,
			});
		} catch (error: any) {
			setAlert({
				severity: 'success',
				message: `If an account exists for ${email}, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.`,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<LoginWrapper>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					padding: 4,
				}}
			>
				<Typography variant='h3' textAlign='center' gutterBottom>
					Enter your email to reset password
				</Typography>
				{alert && <CustomAlert {...alert} />}
				<Box sx={{ my: 1, width: '100%' }} component='form' onSubmit={handleSubmit(sendResetLink)}>
					{!alert && (
						<TextField
							{...register('email', { required: true })}
							margin='normal'
							required
							fullWidth
							id='email'
							label='Email Address'
							name='email'
							error={!!errors?.email}
							helperText={!!errors?.email && errors.email.message}
							autoComplete='email'
							autoFocus
						/>
					)}
					<Stack sx={{ my: 3 }} direction='column' spacing={1}>
						{!alert && (
							<Button type='submit' fullWidth variant='contained'>
								Reset password
							</Button>
						)}
						<Button component={BrowserLink} to='/login' fullWidth>
							{!alert ? 'Cancel' : 'Back to Log in'}
						</Button>
					</Stack>
				</Box>
			</Box>
		</LoginWrapper>
	);
};

export default ForgotPassword;
