import { IssuesFilterI, MultiDomainAccessFilterI } from './AdminProjects';
import { AdminProjectI } from '../../utilities/queries';

export const projectNameFilter = (project: AdminProjectI, searchString?: string | null) => {
	if (!searchString) {
		return true;
	}
	if (project.Name == null) {
		return false;
	}
	return project.Name.toLowerCase().includes(searchString.toLowerCase());
};

export const projectUserEmailFilter = (project: AdminProjectI, searchString?: string | null) => {
	if (!searchString) {
		return true;
	}
	if (project.user == null || project.user.Email == null) {
		return false;
	}
	return project.user.Email.toLowerCase().includes(searchString.toLowerCase());
};

export const projectPlanFilter = (project: AdminProjectI, searchString?: string | null) => {
	if (!searchString) {
		return true;
	}
	const planIds = searchString?.split('.') || [];
	return planIds.includes(project.PlanId);
};

export const projectValidityFilter = (project: AdminProjectI, searchString?: string | null) => {
	if (!searchString) {
		return true;
	}
	return project.Valid === (searchString === 'valid');
};

export const projectIssueFilter = (project: AdminProjectI, searchString: IssuesFilterI['value']) => {
	if (!searchString) {
		return true;
	} else if (searchString === 'mau-exceeded') {
		return project.InvalidMau;
	} else if (searchString === 'payment-error') {
		return project.InvalidPayment;
	} else {
		return !project.InvalidPayment && !project.InvalidMau;
	}
};

export const projectMultiDomainAccessFilter = (project: AdminProjectI, searchString: MultiDomainAccessFilterI['value']) => {
	if (!searchString) {
		return true;
	} else if (searchString === 'no-multi-domain-access') {
		return !project.MultiDomainAccess;
	} else if (searchString === 'has-multi-domain-access') {
		return !!project.MultiDomainAccess;
	} else {
		return false;
	}
};
