import React from 'react';

import { Box, Button, Grid, IconButton, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material';
import { ResourceSetI } from '../../utilities/queries';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ResourceListItem from './ResourceListItem';
import EditIcon from '@mui/icons-material/Edit';
import CreateEditResourceDialog from './CreateEditResourceDialog';
import CreateEditResourceSetDialog from './CreateEditResourceSetDialog';
import DeleteResourceSetConfirmationDialog from './DeleteResourceSetConfirmationDialog';

interface ResourceSetListItemPropsI {
	resourceSet: ResourceSetI;
}

const ResourceSetListItem = (props: ResourceSetListItemPropsI) => {
	const { resourceSet } = props;

	const [moreMenuElement, setMoreMenuElement] = React.useState<null | HTMLElement>(null);
	const [editResourceDialog, setEditResourceDialog] = React.useState<'new' | null>(null);
	const [editResourceSetOpen, setEditResourceSetOpen] = React.useState(false);
	const [deleteResourceSetConfirmationOpen, setDeleteResourceSetConfirmationOpen] = React.useState(false);

	const handleDeleteAppClick = () => {
		setDeleteResourceSetConfirmationOpen(true);
		setMoreMenuElement(null);
	};

	const handleEditAppClick = () => {
		setEditResourceSetOpen(true);
		setMoreMenuElement(null);
	};

	return (
		<Paper sx={{ padding: 3, my: 2 }}>
			<Stack direction='row' justifyContent='space-between' pb={2}>
				<Typography variant='h6'>{resourceSet.Name}</Typography>
				<Stack direction='row' spacing={2}>
					<Button startIcon={<AddIcon />} size='small' onClick={() => setEditResourceDialog('new')}>
						New resource
					</Button>
					<Box minWidth={4} sx={{ textAlign: 'center' }}>
						<IconButton
							aria-label='menu'
							onClick={(e) => setMoreMenuElement(e.currentTarget)}
							sx={{
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id='menu-resource-set'
							anchorEl={moreMenuElement}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={!!moreMenuElement}
							onClose={() => setMoreMenuElement(null)}
						>
							<MenuItem onClick={handleEditAppClick}>
								<Stack direction='row' spacing={1}>
									<EditIcon />
									<Typography textAlign='center'>Edit Resource Set</Typography>
								</Stack>
							</MenuItem>
							<MenuItem onClick={handleDeleteAppClick}>
								<Stack direction='row' spacing={1}>
									<DeleteIcon color='error' />
									<Typography textAlign='center'>Delete Resource Set</Typography>
								</Stack>
							</MenuItem>
						</Menu>
					</Box>
				</Stack>
			</Stack>
			<Grid container rowSpacing={2} columnSpacing={2}>
				{props.resourceSet?.ResourceSet?.resources.map((resource, i) => (
					<ResourceListItem resource={resource} key={i} resourceIndex={i} resourceSet={resourceSet} />
				))}
			</Grid>
			{editResourceDialog && (
				<CreateEditResourceDialog
					open
					onClose={() => setEditResourceDialog(null)}
					closeDialog={() => setEditResourceDialog(null)}
					resourceSet={resourceSet}
					_resource={editResourceDialog !== 'new' ? editResourceDialog : undefined}
				/>
			)}
			{editResourceSetOpen && (
				<CreateEditResourceSetDialog open closeDialog={() => setEditResourceSetOpen(false)} onClose={() => setEditResourceSetOpen(false)} resourceSet={resourceSet} />
			)}
			{deleteResourceSetConfirmationOpen && (
				<DeleteResourceSetConfirmationDialog
					open
					closeDialog={() => setDeleteResourceSetConfirmationOpen(false)}
					onClose={() => setDeleteResourceSetConfirmationOpen(false)}
					resourceSet={resourceSet}
				/>
			)}
		</Paper>
	);
};

export default ResourceSetListItem;
