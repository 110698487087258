import { Box, BoxProps, useTheme } from '@mui/material';
import React from 'react';

interface LogoPropsI extends BoxProps {
	inverted?: boolean;
}

const Logo = (props: LogoPropsI) => {
	const theme = useTheme();
	const { inverted = theme.palette.mode === 'dark' } = props;
	return (
		<Box {...props} sx={{ display: 'flex', alignItems: 'center' }}>
			<img src={inverted ? '/deepar-logo-inverted.svg' : '/deepar-logo.svg'} alt='logo' />
		</Box>
	);
};

export default Logo;
