import { Slider, Typography, Box, Stack, Chip } from '@mui/material';
import React from 'react';
import { PricePlanI, pricePlans } from '../../../pricePlans';
import { formatNumber } from '../../../utilities/helpers';
import PlanPrice from './PlanPrice';

interface PlanSelectorPropsI {
	selectedPlanId: string;
	setSelectedPlanId: React.Dispatch<React.SetStateAction<string>>;
	currentPlanId?: string;
	disabledPlanIds?: string[];
}

const PlanSelector = (props: PlanSelectorPropsI) => {
	const { selectedPlanId, setSelectedPlanId, currentPlanId, disabledPlanIds = [] } = props;

	const plans = Object.values(pricePlans);
	const [isAnnualPlan, setIsAnnualPlan] = React.useState(selectedPlanId.includes('annual'));

	const currentPlan = React.useMemo(() => {
		return plans.find((p) => p.id === selectedPlanId) || plans[0];
	}, [selectedPlanId, plans]);

	const getFormattedMauLimit = (plan?: PricePlanI) => {
		const mauLimit = formatNumber(plan?.mauLimit || 10);
		if (!plan) return;
		return plan.id === 'xxl-tier' ? mauLimit + '+' : `${mauLimit}`;
	};

	const marks = React.useMemo(() => {
		return plans
			.filter((p) => !p.private && !p.id.includes('annual') && !disabledPlanIds.includes(p.id))
			.map((p, i) => ({ value: p?.scaledValue || 10, label: getFormattedMauLimit(p) }));
	}, [plans, disabledPlanIds]);

	const handleAnnualIds = (planId: string, isAnnual: boolean) => {
		if (isAnnual && !planId.includes('annual') && planId !== 'free' && planId !== 'xxl-tier') {
			return `${planId}-annual`;
		} else if (!isAnnual && planId.includes('annual')) {
			return planId.split('-').slice(0, -1).join('-');
		} else {
			return planId;
		}
	};

	const handleSetIsAnnualPlan = (isAnnual: boolean) => {
		setIsAnnualPlan(isAnnual);
		setSelectedPlanId(handleAnnualIds(selectedPlanId, isAnnual));
	};

	const handlePlanChange = (event: Event, value: number | number[], activeThumb: number) => {
		setSelectedPlanId(handleAnnualIds(plans.find((p) => p.scaledValue === value)?.id || plans[0].id, isAnnualPlan));
	};

	return (
		<Box pt={2} sx={{ textAlign: 'left ' }}>
			<Stack direction='row' justifyContent='space-between' pb={1}>
				<Typography variant='h4' gutterBottom>
					Users per month
				</Typography>
				{currentPlanId === selectedPlanId && <Chip label='Current plan' color='info' />}
			</Stack>
			<Box px={2}>
				<Slider
					marks={marks}
					valueLabelDisplay='auto'
					min={10}
					max={marks.length * 10}
					step={10}
					valueLabelFormat={(v) => getFormattedMauLimit(plans.find((p) => p.scaledValue === v))}
					value={currentPlan.scaledValue}
					onChange={handlePlanChange}
				/>
			</Box>
			<PlanPrice plan={currentPlan} isAnnualPlan={isAnnualPlan} setIsAnnualPlan={handleSetIsAnnualPlan} />
		</Box>
	);
};

export default PlanSelector;
