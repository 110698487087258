import React from 'react';

import { LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useInvoices } from '../../utilities/queries';
import { getMonthString } from '../../utilities/helpers';

const Invoices = () => {
	const { data, isLoading } = useInvoices();

	return (
		<Box py={4}>
			<Typography variant='h5' gutterBottom>
				Invoices
			</Typography>
			<TableContainer component={Paper} sx={{ maxWidth: '90vw', overflow: 'auto' }}>
				{isLoading && <LinearProgress sx={{ width: '100%' }} />}
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>Project name</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Amount</TableCell>
							<TableCell>Invoice Number</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Invoice PDF</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.data?.map((invoice, i) => {
							const subscription = invoice.lines.data.find((line) => {
								return line.type === 'subscription';
							});
							const projectName = subscription?.metadata?.projectName || '';
							const date = new Date(invoice.created * 1000);
							return (
								<TableRow key={i}>
									<TableCell>{projectName}</TableCell>
									<TableCell>{`${getMonthString(date.getUTCMonth())} ${date.getDate()}, ${date.getFullYear()}`}</TableCell>
									<TableCell>{`US$${invoice.total / 100}`}</TableCell>
									<TableCell>{invoice.number}</TableCell>
									<TableCell>{invoice.status}</TableCell>
									<TableCell>
										<Link href={invoice.invoice_pdf}>Download</Link>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default Invoices;
