import React from 'react';
import { Box, Button, Container } from '@mui/material';

import { Link } from 'react-router-dom';

const StudioDeveloper = () => {
	return (
		<Container component={Box} pt={4}>
			<Button component={Link} to='/studio-developer/effect-templates'>
				Effect templates
			</Button>
		</Container>
	);
};

export default StudioDeveloper;
