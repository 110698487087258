import React from 'react';

import { Stack, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

interface ProjectAppMoreMenuPropsI {
	setDeleteAppDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectAppMoreMenu = (props: ProjectAppMoreMenuPropsI) => {
	const [moreMenuElement, setMoreMenuElement] = React.useState<null | HTMLElement>(null);

	const handleDeleteAppClick = () => {
		props.setDeleteAppDialogOpen(true);
		setMoreMenuElement(null);
	};

	return (
		<Box minWidth={4} sx={{ textAlign: 'center' }}>
			<IconButton
				aria-label='menu'
				onClick={(e) => setMoreMenuElement(e.currentTarget)}
				sx={{
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id='menu-project'
				anchorEl={moreMenuElement}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={!!moreMenuElement}
				onClose={() => setMoreMenuElement(null)}
			>
				<MenuItem onClick={handleDeleteAppClick}>
					<Stack direction='row' spacing={1}>
						<DeleteIcon color='error' />
						<Typography textAlign='center'>Delete App</Typography>
					</Stack>
				</MenuItem>
			</Menu>
		</Box>
	);
};

export default ProjectAppMoreMenu;
