import React from 'react';

import { Alert, Box, Button, DialogContent, DialogProps, IconButton, LinearProgress, Link, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryClient } from 'react-query';
import { useStripe } from '@stripe/react-stripe-js';
import { AppContext } from '../../../../utilities/StateProvider';
import { InvoiceI, ProjectI, useCards } from '../../../../utilities/queries';
import SelectOrCreateCard from '../../SelectOrCreateCard';
import ResponsiveDialog from '../../../../components/ResponsiveDialog';

interface InvalidPaymentDialogPropsI extends DialogProps {
	closeDialog: () => void;
	project: ProjectI;
	invoice: InvoiceI;
}

const InvalidPaymentDialog = (props: InvalidPaymentDialogPropsI) => {
	const { closeDialog, project, invoice, ...rest } = props;

	const [loading, setLoading] = React.useState(false);

	const { state, setGlobalAlertMessage } = React.useContext(AppContext);

	const stripe = useStripe();

	const stripeId = React.useMemo(() => {
		return state.appAuthState.currentUser?.attributes?.['custom:stripe_id'];
	}, [state.appAuthState.currentUser]);

	const { data: cardsData, isLoading: cardsLoading } = useCards(!!stripeId);
	const [createNewCard, setCreateNewCard] = React.useState(false);
	const [selectedCard, setSelectedCard] = React.useState(invoice?.payment_intent?.last_payment_error?.payment_method?.id || '');

	const queryClient = useQueryClient();

	const tryAgain = async () => {
		setLoading(true);
		try {
			if (invoice?.payment_intent?.client_secret) {
				const response = await stripe?.confirmCardPayment(invoice.payment_intent.client_secret, {
					payment_method: selectedCard,
				});
				console.log('response', response);
				if (response?.error) {
					setGlobalAlertMessage({
						message: response?.error?.message || 'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
						severity: 'error',
					});
				} else {
					setGlobalAlertMessage({
						message: 'Payment successful',
					});
					queryClient.invalidateQueries(['project', project?.Id]);
					queryClient.invalidateQueries('invoices');
				}
			}
		} catch (error: any) {
			console.log(error.message);
		} finally {
			setLoading(false);
			closeDialog();
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Pay invoice</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} direction='column'>
						{invoice?.hosted_invoice_url && (
							<Alert sx={{ mb: 3 }} severity='warning'>
								<Box>
									<Typography variant='body2'>
										If you are experiencing payment issues, please try paying directly <Link href={invoice.hosted_invoice_url} target='_blank' rel='noopener'>here</Link>.
									</Typography>
								</Box>
							</Alert>
						)}
						<Box>
							<SelectOrCreateCard
								cards={cardsData?.data}
								loading={cardsLoading || loading}
								createNewCard={createNewCard}
								setCreateNewCard={setCreateNewCard}
								selectedCard={selectedCard}
								setSelectedCard={setSelectedCard}
							/>
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' onClick={tryAgain}>
								Retry payment
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default InvalidPaymentDialog;
