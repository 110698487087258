import ReactGA from 'react-ga4';

export const sendCustomGAEvent = (params: {
	category: string;
	action: string;
	label?: string;
	value?: number;
	nonInteraction?: boolean;
	transport?: 'beacon' | 'xhr' | 'image';
}) => {
	ReactGA.event({
		...params,
	});
};
