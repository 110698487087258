import React from 'react';

import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line } from 'recharts';
import { useAccumulatedAdminProjects } from '../../../utilities/queries';

const ActiveProjectGraph = () => {
	const theme = useTheme();

	const { data: projects, isLoading } = useAccumulatedAdminProjects();

	const monthlyData = React.useMemo(() => {
		// prepare projects into amount with MAU over 1 each month
		const monthlyData: { date: string; mau1: number; mau5: number; mau100: number; mau1000: number }[] = [];

		if (!projects) {
			return monthlyData;
		}

		for (let project of projects) {
			for (let mauMonth of Object.entries(project?.Mau || {})) {
				const date = new Date(mauMonth[0]);
				const mau = mauMonth[1];

				const month = date.getMonth() + 1;
				const year = date.getFullYear();
				const dateString = `${year}-${month}`;

				const mauTiers = {
					mau1: mau >= 1 ? 1 : 0,
					mau5: mau >= 5 ? 1 : 0,
					mau100: mau >= 100 ? 1 : 0,
					mau1000: mau >= 1000 ? 1 : 0,
				};

				if (monthlyData.find((d) => d.date === dateString)) {
					monthlyData.find((d) => d.date === dateString)!.mau1 += mauTiers.mau1;
					monthlyData.find((d) => d.date === dateString)!.mau5 += mauTiers.mau5;
					monthlyData.find((d) => d.date === dateString)!.mau100 += mauTiers.mau100;
					monthlyData.find((d) => d.date === dateString)!.mau1000 += mauTiers.mau1000;
				} else {
					monthlyData.push({ date: dateString, ...mauTiers });
				}
			}
		}

		monthlyData.sort((a, b) => {
			const aDate = new Date(a.date);
			const bDate = new Date(b.date);
			return aDate.getTime() - bDate.getTime();
		});

		return monthlyData;
	}, [projects]);

	return (
		<Box>
			<Box
				height='400px'
				width='100%'
				sx={{
					position: 'relative',
					my: 12,
					'& .recharts-tooltip-label': {
						color: theme.palette.primary.dark,
					},
				}}
			>
				<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 2 }}>
					<Typography variant='h3'>Active Projects by MAU</Typography>
				</Stack>
				{isLoading && <CircularProgress size={40} sx={{ position: 'absolute', left: '50%', top: '50%' }} />}
				<ResponsiveContainer width='100%' height='100%' minHeight='40px' minWidth='80px'>
					<LineChart data={monthlyData} barCategoryGap={2}>
						<XAxis dataKey='date' />
						<YAxis />
						<CartesianGrid stroke={theme.palette.text.secondary} opacity={0.2} />
						<Tooltip />
						<Line dataKey='mau1' strokeWidth={2} stroke={theme.palette.warning.main} dot={false} />
						<Line dataKey='mau5' strokeWidth={2} stroke={theme.palette.success.main} dot={false} />
						<Line dataKey='mau100' strokeWidth={2} stroke={theme.palette.secondary.main} dot={false} />
						<Line dataKey='mau1000' strokeWidth={2} stroke={theme.palette.primary.main} dot={false} />
					</LineChart>
				</ResponsiveContainer>
			</Box>
		</Box>
	);
};

export default ActiveProjectGraph;
