import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const SiteTerms = () => {
	return (
		<Container component={Box} py={6}>
			<Typography variant='h1' gutterBottom>
				DeepAR Site Terms
			</Typography>
			<Typography variant='h5'>Last Updated: 12 April, 2022.</Typography>
			<br />

			<Typography variant='h6'>
				Welcome to the DeepAR developer site (the“DeepAR Site”). I Love IceCream Ltd. and/or its affiliates (“DeepAR”)
				provides the DeepAR Site to you subject to the following terms of use(“Site Terms”). By visiting the DeepAR
				Site, you accept the Site Terms. Please read them carefully. In addition, when you use any current or future
				DeepAR services, content or other materials, you also will be subject to the{' '}
				<Link to='/customerAgreement'>DEEPAR Customer Agreement</Link> or other agreement governing your use of our
				services (the“Agreement”).
			</Typography>
			<br />

			<Typography variant='h3'>PRIVACY</Typography>
			<p>
				Please review our <Link to='/privacyNotice'>Privacy Policy</Link>, which also governs your visit to the DeepAR
				Site, to understand our practices.
			</p>
			<br />

			<Typography variant='h3'>ELECTRONIC COMMUNICATIONS</Typography>
			<p>
				When you visit the DeepAR Site or send e-mails to us, you are communicating with us electronically. You consent
				to receive communications from us electronically. We will communicate with you by e-mail or by posting notices
				on the DeepAR Site. You agree that all agreements, notices, disclosures and other communications that we provide
				to you electronically satisfy any legal requirement that such communicationsbe in writing.
			</p>
			<br />

			<Typography variant='h3'>COPYRIGHT</Typography>
			<p>
				All content included on the DeepAR Site, such as text, graphics, logos, button icons, images, audio clips,
				digital downloads, data compilations, and software, is the property of DeepAR or its content suppliers and
				protected by United States, English and international copyright DeepAR. The compilation of all content on the
				DeepAR Site is the exclusive property of DeepAR and protected by U.S., English and international copyright
				DeepAR. All software used on the DeepAR Site is the propertyof DeepAR or its software suppliers and protected by
				United States, English and international copyright DeepAR.
			</p>
			<br />

			<Typography variant='h3'>TRADEMARKS</Typography>
			<p>
				“DeepAR”, “DeepAR.ai” and other DeepAR graphics, logos, page Typographys, button icons, scripts, and service
				names are trademarks, registered trademarks or trade dress of DeepAR in the U.S. and/or other countries.
				DeepAR’s trademarks and trade dress may not be used in connection with any product or service that is not
				DeepAR’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or
				discredits DeepAR. All other trademarks ot owned by DeepAR that appear on this Site are the property of their
				respective owners, who may or may not be affiliated with, connected to, or sponsored by DeepAR.
			</p>
			<br />

			<Typography variant='h3'>PATENTS</Typography>
			<p>
				One or more patents owned by I Love IceCream Ltd. or its affiliates may apply to the DeepAR Site and to the
				features and services accessible via the DeepAR Site. Portions of the DeepAR Site may operate under license of
				one or more patents.
			</p>
			<br />

			<Typography variant='h3'>LICENCE AND SITE ACCESS</Typography>
			<p>
				DeepAR grants you a limited license to access and make personal use of the DeepARSite and not to download (other
				than page caching) or modify it, or any portion of it, except with express written consent of DeepAR. This
				license does not include any resale or commercial use of the DeepAR Site or its contents; any derivative use of
				the DeepAR Site or its contents; any downloading or copying of account information; or any use of data mining,
				robots, or similar data gathering and extraction tools. Unless otherwise specified by DeepAR in a separate
				license, your right to use any software, data, documentation or other materials that you access or download
				through the DeepAR Site is subject to these Site Terms or, if you have an DeepAR account, the Agreement.
			</p>
			<p>
				The DeepAR Site or any portion of the DeepAR Site may not be reproduced, duplicated, copied, sold, resold,
				visited, or otherwise exploited for any commercial purpose without express written consent of DeepAR. You may
				not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information
				(including images, text, page layout, or form) of DeepAR without express written consent. You may not use any
				meta tags or any other “hidden text” utilizing DeepAR’s name or trademarks without the express written consent
				of DeepAR. Any unauthorized use terminates the permission or license granted by DeepAR. You are granted a
				limited, revocable, and nonexclusive right to create a hyperlink to the home page of the DeepAR Site, so long as
				the link does not portray DeepAR, or its products or services in a false, misleading, derogatory, or otherwise
				offensive matter.You may not use any DeepAR logo or other proprietary graphic or trademark as part of the link
				without express written permission.
			</p>
			<br />

			<Typography variant='h3'>YOUR ACCOUNT</Typography>
			<p>
				If you use the DeepAR Site, you are responsible for maintaining the confidentiality of your DeepAR account and
				password and for restricting access to your computer, andyou agree to accept responsibility for all activities
				that occur under your account or password. DeepAR reserves the right to refuse service, terminate accounts,
				remove or edit content in its sole discretion.
			</p>
			<br />

			<Typography variant='h3'>COPYRIGHT COMPLAINTS</Typography>
			<p>
				DeepAR respects the intellectual property of others. If you believe that your work hasbeen copied in a way that
				constitutes copyright infringement, please follow ourNotice and Procedure for Making Claims of Copyright
				Infringement.
			</p>
			<br />

			<Typography variant='h3'>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</Typography>
			<p>
				THE DEEPAR SITE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE) AND SERVICES INCLUDED
				ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED BY DEEPAR ON AN “AS IS” AND “AS AVAILABLE”
				BASIS, UNLESS OTHERWISE SPECIFIED IN THE AGREEMENT. DEEPAR MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
				EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
				ANY SOFTWARE) OR SERVICES INCLUDED ON OROTHERWISE MADE AVAILABLE TO YOU THROUGH THE DEEPAR SITE, UNLESS
				OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL
				EXTENT PERMISSIBLE BY APPLICABLE LAW, DEEPAR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
				LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. DEEPAR DOES NOT WARRANT
				THAT THIS SITE; INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE) OR SERVICES INCLUDED ON OR
				OTHERWISE MADE AVAILABLE TO YOU THROUGH THE DEEPAR SITE; ITS SERVERS; OR E-MAIL SENT FROM DEEPAR ARE FREE OF
				VIRUSES OR OTHER HARMFUL COMPONENTS. DEEPAR WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE
				OF THE DEEPAR SITE OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES
				INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE DEEPAR SITE, INCLUDING, BUT NOT LIMITED TO DIRECT,
				INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN THE AGREEMENT. CERTAIN
				STATE LDEEPAR DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
				IF THESE LDEEPAR APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO
				YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
			</p>
			<br />

			<Typography variant='h3'>APPLICABLE LAW</Typography>
			<p>
				By visiting the DeepAR Site, you agree that the laws of England and Wales, without regard to principles of
				conflict of DeepAR, will govern these Site Terms and any dispute of any sort that might arise between you and
				DeepAR.
			</p>
			<br />

			<Typography variant='h3'>DISPUTES</Typography>
			<p>
				Any dispute relating in any way to your visit to the DeepAR Site or to services provided by DeepAR or through
				the DeepAR Site in which the aggregate total claim for relief sought on behalf of one or more parties exceeds
				$7,500 shall be adjudicated in any court in England, United Kingdom, and you consent to exclusive jurisdiction
				and venue in such courts.
			</p>
			<br />

			<Typography variant='h3'>SITE POLICIES, MODIFICATION, AND SEVERABILITY</Typography>
			<p>
				Please review our other policies on the DeepAR Site. These policies also govern your visit to the DeepAR Site.
				We reserve the right to make changes to the DeepAR Site, policies, and these Site Terms at any time. If any of
				these conditions shall be deemedinvalid, void, or for any reason unenforceable, that condition shall be deemed
				severable and shall not affect the validity and enforceability of any remaining condition.
			</p>
			<br />

			<Typography variant='h3'>OUR ADDRESS</Typography>
			<p>
				I Love IceCream Ltd
				<br />9 Percy St
				<br />
				London W1T 1DL
				<br />
				<a href='https://deepar.ai' target='_blank' rel='noopener noreferrer'>
					https://deepar.ai
				</a>
			</p>
			<br />

			<Typography variant='h3'>NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</Typography>
			<p>
				If you believe that your work has been copied in a way that constitutes copyright infringement, please provide
				our copyright agent the written information specified below. Please note that this procedure is exclusively for
				notifying DeepAR that your copyrighted material has been infringed.
			</p>
			<ul>
				<li>
					An electronic or physical signature of the person authorized to act on behalf ofthe owner of the copyright
					interest;
				</li>
				<li>A description of the copyrighted work that you claim has been infringed upon;</li>
				<li>A description of where the material that you claim is infringing is located on the Site;</li>
				<li>Your address, telephone number, and e-mail address;</li>
				<li>
					A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright
					owner, its agent, or the law;
				</li>
				<li>
					A statement by you, made under penalty of perjury, that the above information in your notice is accurate and
					that you are the copyright owner or authorized to act on the copyright owner’s behalf.
				</li>
			</ul>
			<p>DeepAR’s Copyright Agent for notice of claims of copyright infringement can be reached as follows:</p>
			<p>
				DeepAR Legal Department
				<br />I Love IceCream Ltd
				<br />9 Percy StLondon W1T 1DL
				<br />
				<a href='https://deepar.ai' target='_blank' rel='noopener noreferrer'>
					https://deepar.ai
				</a>
			</p>
		</Container>
	);
};

export default SiteTerms;
