import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button, Container, Paper, Typography, useTheme } from '@mui/material';
import PlanSelector from './PlanSelector/PlanSelector';
import SnappyAccordion from '../../components/SnappyAccordion';

const Pricing = () => {
	const theme = useTheme();
	const history = useHistory();

	const [selectedPlan, setSelectedPlan] = React.useState('free');

	return (
		<Box
			sx={{
				backgroundImage: theme.palette.mode === 'light' ? 'url(/assets/bg-grid.svg)' : 'url(/assets/bg-grid-dark.svg)',
				backgroundRepeat: 'repeat',
				height: '94vh',
			}}
		>
			<Box
				sx={{
					backgroundImage: 'url(/assets/pricing-gradient.svg)',
					backgroundRepeat: 'no-repeat',
					backgroundSize: '100% auto',
					backgroundPosition: 'left top',
					minHeight: '94vh',
					width: '100%',
				}}
			>
				<Container component={Box} pb={8} pt={10}>
					<Box pb={6} sx={{ textAlign: 'center' }}>
						<Typography variant='h1' gutterBottom>
							Pricing
						</Typography>
						<Typography variant='h4'>Flexible plans, pay as you grow.</Typography>
					</Box>
					<Paper sx={{ maxWidth: '600px', margin: 'auto', padding: 4, textAlign: 'center' }}>
						<PlanSelector selectedPlanId={selectedPlan} setSelectedPlanId={setSelectedPlan} />
						<Box pt={2}>
							{selectedPlan === 'xxl-tier' ? (
								<Button href='https://deepar.ai/contact#demo-form' target='__blank' variant='contained'>
									Contact sales
								</Button>
							) : (
								<Button variant='contained' onClick={() => history.push(`/createproject/${selectedPlan}`)}>
									Get started
								</Button>
							)}
						</Box>
					</Paper>
					<Paper sx={{ maxWidth: '600px', margin: 'auto', mt: 4 }}>
						<SnappyAccordion title='What are monthly active users (MAU) and how are they calculated?'>
							<Typography>
								All subscription plans are based on the amount of monthly active users your app has. Every user that opens your app and uses DeepAR features is
								counted as an active user for that month.
							</Typography>
						</SnappyAccordion>

						<SnappyAccordion title='Can I change my subscription at any time?'>
							<Typography>
								Yes. You can upgrade, downgrade or cancel your subscription at any time. There are no refunds for the active time period for your subscription.
							</Typography>
						</SnappyAccordion>

						<SnappyAccordion title='Can I try DeepAR SDK for free?'>
							<Typography>
								Yep{' '}
								<span role='img' aria-label='Sunglasses emoji'>
									😎
								</span>
								! You can try all features for free, with up to 10 users per month.
							</Typography>
						</SnappyAccordion>

						<SnappyAccordion title='How many projects does one subscription include?'>
							<Typography>
								One subscription is valid for one project which includes one iOS, one Android, one HTML5 and one macOS application. All applications contribute
								to the same monthly active user (MAU) count.
							</Typography>
						</SnappyAccordion>
					</Paper>
				</Container>
			</Box>
		</Box>
	);
};

export default Pricing;
