import React from 'react';

import { Box, Button, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { getMonthString } from '../../../../utilities/helpers';
import { InvoiceI, ProjectI, useInvoices } from '../../../../utilities/queries';
import InvalidPaymentDialog from './InvalidPaymentDialog';

interface InvalidPaymentPropsI {
	project?: ProjectI;
}

const InvalidPayment = (props: InvalidPaymentPropsI) => {
	const { project } = props;

	const { data, isLoading } = useInvoices();

	const [invoiceToPay, setInvoiceToPay] = React.useState<InvoiceI | null>(null);

	const unpaidInvoices = React.useMemo(() => {
		return data?.data?.filter((invoice, i) => invoice.status === 'open' && project?.SubscriptionId === invoice.subscription) || [];
	}, [project, data]);

	return (
		<Box py={4}>
			<Typography variant='h5' gutterBottom>
				Unpaid invoices
			</Typography>
			<TableContainer component={Paper}>
				{isLoading && <LinearProgress sx={{ width: '100%' }} />}
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Amount</TableCell>
							<TableCell>Invoice Number</TableCell>
							<TableCell>Invoice PDF</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{unpaidInvoices.map((invoice, i) => {
							const date = new Date(invoice.created * 1000);
							return (
								<TableRow key={i}>
									<TableCell>{`${getMonthString(date.getUTCMonth())} ${date.getDate()}, ${date.getFullYear()}`}</TableCell>
									<TableCell>{`US$${invoice.total / 100}`}</TableCell>
									<TableCell>{invoice.number}</TableCell>
									<TableCell>
										<Link href={invoice.invoice_pdf}>Download</Link>
									</TableCell>
									<TableCell>
										<Button variant='outlined' onClick={() => setInvoiceToPay(invoice)}>
											Pay invoice
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{!!invoiceToPay && !!project && (
				<InvalidPaymentDialog open onClose={() => setInvoiceToPay(null)} closeDialog={() => setInvoiceToPay(null)} invoice={invoiceToPay} project={project} />
			)}
		</Box>
	);
};

export default InvalidPayment;
