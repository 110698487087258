import { UserAttributesI } from './store';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import { CreateProjectFormI } from '../pages/Projects/CreateProjectDialog';
import { CreateProjectAppFormI } from '../pages/Projects/Project/CreateProjectAppDialog';
import { EditAdminProjectFormI } from '../pages/Admin/EditAdminProjectDialog';
import { Card, PaymentIntent } from '@stripe/stripe-js';
import { CreateEditResourceFormI } from '../pages/Resources/CreateEditResourceDialog';
import { DemoProject } from '../Models/demoProjects';

const environment = process.env.REACT_APP_STAGE || 'dev';

export interface DownloadReleaseI {
	type: string;
	version: string;
	platform: string;
	downloadUrl: string;
	lastModifiedUtcMs: number;
	sizeInBytes: number;
}

export enum DownloadName {
	android = 'DeepAR Android',
	ios = 'DeepAR iOS',
	web = 'DeepAR Web',
	macos = 'DeepAR MacOS',
	studio = 'DeepAR Studio',
	filterPack = 'Free Studio filters pack',
}

export interface DownloadI {
	name: DownloadName;
	platform?: string;
	latestStable?: DownloadReleaseI[];
	latestUnstable?: DownloadReleaseI[];
}

export interface DownloadsI {
	sdkDownloads: DownloadI[];
	studioDownloads: DownloadI[];
}

export interface ProjectAppI {
	AppId: string;
	Id: string;
	Key: string;
	Platform: string;
	ProjectId: string;
	Valid: boolean;
	Mau?: MonthlyActiveUsers;
}

export interface MonthlyActiveUsers {
	[key: string]: number;
}

export interface ProjectI {
	Expires: number;
	Id: string;
	InvalidResponse: number;
	MauLimit: number;
	Name: string;
	PlanId: string;
	Strict: number;
	Type: 'trial';
	UserId: string;
	UserPoolUserId: string;
	Valid: boolean;
	ValidResponse: number;
	apps: ProjectAppI[];
	Mau?: MonthlyActiveUsers;
	SubscriptionId?: string;
	SubscriptionStatus?: 'active' | 'unpaid' | 'incomplete';
	InvalidPayment?: boolean;
	InvalidPaymentDay?: number;
	InvalidMau?: boolean;
	InvalidMauDay?: number;
	CreatedAt?: number;
	MultiDomainAccess?: boolean;
}

export interface AdminUserI {
	AgreedToTermOfUse: boolean;
	Attribution: string;
	Email: string;
	UserPoolUserId: string;
}

export interface AdminSingleUserAttributeI {
	Name: string;
	Value: string;
}

export interface AdminSingleUserI {
	Enabled: boolean;
	UserAttributes: AdminSingleUserAttributeI[];
	UserAtts: UserAttributesI;
	UserCreateDate: string;
	UserLastModifiedDate: string;
	UserStatus: string;
	Username: string;
}

export interface AdminProjectI extends ProjectI {
	user: AdminUserI;
}

export interface AdminAccountI {
	Attributes: {
		Name: string;
		Value: string;
	}[];
	Enabled: boolean;
	UserCreateDate: string;
	UserLastModifiedDate: string;
	UserStatus: string;
	Username: string;
	numbers: {
		apps: number;
		hasBasic: boolean;
		hasPro1m: boolean;
		hasPro200k: boolean;
		hasPro500k: boolean;
		hasProfessional: boolean;
		hasStartup: boolean;
		hasXLtier: boolean;
		hasXXLtier: boolean;
		mau: number;
		projects: number;
	};
}

export interface SingleAdminAccountI {
	// Basically the same as AdminAccountI but the backend had some different prop names
	UserAttributes: {
		Name: string;
		Value: string;
	}[];
	UserAtts: UserAttributesI;
	UserCreateDate: string;
	UserLastModifiedDate: string;
	UserStatus: string;
	Username: string;
	Enabled: boolean;
}

export interface StudioDeveloperEffectTemplateI {
	Id: string;
	Name: {
		[language: string]: string;
	};
	Description: {
		[language: string]: string;
	};
	Categories: string[];
	SupportedPlatforms: string[];
	MinStudioVersion: number[];
	MaxStudioVersion: number[] | null;
	ZipUrl: string;
	ZipSizeInBytes: number;
	ImageUrl: string;
	WebmUrl?: string;
	GifUrl?: string;
}

export interface StudioDeveloperEffectTemplateProjectI {
	DownloadUrl: string;
	LastModifiedUtcMs: number;
	SizeInBytes: number;
}

export interface StudioDeveloperEffectTemplateImageI {
	DownloadUrl: string;
	LastModifiedUtcMs: number;
	SizeInBytes: number;
}

export interface StudioDeveloperEffectTemplateWebmI {
	DownloadUrl: string;
	LastModifiedUtcMs: number;
	SizeInBytes: number;
}

export interface StudioDeveloperEffectTemplateGifI {
	DownloadUrl: string;
	LastModifiedUtcMs: number;
	SizeInBytes: number;
}

export interface BillingInfo {
	email: string;
	address: string;
	city: string;
	zipCode: string;
	firstName: string;
	lastName: string;
	country: string;
	vatId: string;
	companyName: string;
	token?: string;
	cardId?: string;
}

export interface NewFreeProjectRequest {
	project: CreateProjectFormI;
	AgreedToTermOfUse: boolean;
}

export interface NewPaidProjectRequest {
	project: CreateProjectFormI;
	billingInfo: BillingInfo;
	couponId?: string;
	AgreedToTermOfUse: boolean;
}

export interface SubscriptionChangeRequestI {
	newPlanId: string;
	oldPlanId: string;
	subscriptionId: string;
	projectId: string;
	projectName: string;
	billingInfo: BillingInfo;
}

export interface InvoiceLine {
	type: string;
	metadata?: {
		projectName: string;
	};
	// plus more that we don't handle it in the UI
}

export interface InvoiceI {
	created: number;
	invoice_pdf: string;
	lines: {
		data: InvoiceLine[];
		has_more: boolean;
		object: string;
		total_count: number;
		url: string;
	};
	status: string;
	total: number;
	number: string;
	subscription: string;
	payment_intent: PaymentIntent;
	hosted_invoice_url?: string;
	// plus more that we don't handle it in the UI
}

export interface ResourceSetGroupI {
	default: string;
	id: string;
	title: string;
}

export interface ResourceI {
	enabled: boolean;
	name: string;
	url: string;
	version: number;
	meta: {
		groupId: string;
		isPremium: boolean;
		thumbUrl: string;
	};
	action?: 'edit' | 'delete';
}

export interface ResourceSetI {
	Id: string;
	Name: string;
	UserId: string;
	ResourceSet: {
		info: {
			groups: ResourceSetGroupI[];
		};
		resources: ResourceI[];
	};
}

// Create
export const createFreeProject = async (data: NewFreeProjectRequest): Promise<ProjectI> => {
	const request = {
		body: {
			...data,
		},
		headers: { 'Content-Type': 'application/json' },
	};
	return API.post('AppsApi', '/projects', request);
};

export const createPaidProject = async (data: NewPaidProjectRequest): Promise<{ item?: ProjectI; pay_int?: PaymentIntent & { source: string } }> => {
	const request = {
		body: {
			...data,
		},
		headers: { 'Content-Type': 'application/json' },
	};
	console.log('Request is:', request);
	return API.post('AppsApi', '/projects', request);
};

export const createProjectApp = async (data: CreateProjectAppFormI) => {
	const request = {
		body: data,
		headers: { 'Content-Type': 'application/json' },
	};
	return API.post('AppsApi', '/apps', request);
};

// Read
export const getDownloads = async (): Promise<DownloadsI> => {
	return await API.get('AppsApi', '/downloads', {});
};

export const getSDKNames = async (): Promise<{ sdkNames: string[] }> => {
	return await API.get('AppsApi', '/sdkNames', {});
};

export const getProjects = async (): Promise<ProjectI[]> => {
	const projects = (await API.get('AppsApi', '/projectsWithApps', {})) as ProjectI[];

	if (environment === 'prod') {
		var paidProjects = projects.filter((p) => p.PlanId !== 'free');
		var paidProjectCount = paidProjects ? paidProjects.length : 0;
		window.Intercom('update', {
			project_count: projects.length,
			paid_project_count: paidProjectCount,
		});
	}

	return projects;
};

export const getDemoProjects = async (): Promise<DemoProject[]> => {
	const demoProjects = await API.get('AppsApi', '/demoProjects', {});
	return demoProjects;
};

export const getDemoProjectById = async (id: string): Promise<DemoProject> => {
	const demoProject = await API.get('AppsApi', `/demoProjects/${id}`, {});
	return demoProject;
};

export const accumulateProjects = async (accumulator: ProjectI[], paginationToken: string): Promise<ProjectI[]> => {
	let data;
	try {
		data = await API.get('AppsApi', '/admin/projects?paginationToken=' + paginationToken, {});
	} catch (error: any) {
		console.log(error.message);
	}

	const { projects, nextPaginationToken } = data;
	const newAccumulator = accumulator.concat(projects);

	if (nextPaginationToken != null && nextPaginationToken !== '') {
		return accumulateProjects(newAccumulator, nextPaginationToken);
	}
	return newAccumulator;
};

export const accumulateUsers = async (accumulator: AdminUserI[], paginationToken: string): Promise<AdminUserI[]> => {
	let data;
	try {
		data = await API.get('AppsApi', '/admin/users?paginationToken=' + paginationToken, {});
	} catch (error: any) {
		console.log(error.message);
	}
	const { users, nextPaginationToken } = data;
	const newAccumulator = accumulator.concat(users);

	if (nextPaginationToken != null && nextPaginationToken !== '') {
		return accumulateUsers(newAccumulator, nextPaginationToken);
	}

	return newAccumulator;
};

const getAccumulatedProjectsAndUsers = async (): Promise<AdminProjectI[]> => {
	const users = await accumulateUsers([], '');
	const usersMap = users.reduce((map: { [key: string]: AdminUserI }, user) => {
		map[user.UserPoolUserId] = user;
		return map;
	}, {});
	const projects = await accumulateProjects([], '');
	const projectsAndUsers: AdminProjectI[] = projects.map((project) => ({
		...project,
		user: usersMap[project.UserPoolUserId],
	}));
	return projectsAndUsers;
};

const getAccumulatedUsers = async (): Promise<AdminUserI[]> => {
	return await accumulateUsers([], '');
};

export const getProjectAndAppsById = async (id: string): Promise<ProjectI> => {
	return API.get('AppsApi', `/projects/${id}`, {});
};

export const getProjectAndUserAsAdmin = async (projectId: string): Promise<{ project: AdminProjectI; user: AdminSingleUserI }> => {
	const project = await API.get('AppsApi', `/admin/projects/${projectId}`, {});
	const user = await API.get('AppsApi', `/admin/singleAccount/${project.UserPoolUserId}`, {});
	return {
		project,
		user,
	};
};

export const getAccountAsAdmin = async (userId?: string): Promise<SingleAdminAccountI> => {
	return API.get('AppsApi', '/admin/singleAccount/' + userId, {});
};

export const getAccountProjectsAsAdmin = async (userId?: string): Promise<ProjectI[]> => {
	return API.get('AppsApi', '/admin/accounts/' + userId + '/projects/', {});
};

export const getEffectTemplatesAsStudioDeveloper = async (): Promise<StudioDeveloperEffectTemplateI[]> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplates', {});
};

export const getEffectTemplateProjectsAsStudioDeveloper = async (): Promise<StudioDeveloperEffectTemplateProjectI[]> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplateProjects', {});
};

export const getEffectTemplateProjectSignedUrlAsStudioDeveloper = async (fileName: string): Promise<{ signedUrl: string; uploadUrl: string }> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplateProjects/signedUploadUrl?projectName=' + fileName, {});
};

export const getEffectTemplateImagesAsStudioDeveloper = async (): Promise<StudioDeveloperEffectTemplateImageI[]> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplateImages', {});
};

export const getEffectTemplateImageSignedUrlAsStudioDeveloper = async (fileName: string): Promise<{ signedUrl: string; uploadUrl: string }> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplateImages/signedUploadUrl?imageName=' + fileName, {});
};

export const getEffectTemplateWebmsAsStudioDeveloper = async (): Promise<StudioDeveloperEffectTemplateWebmI[]> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplateWebms', {});
};

export const getEffectTemplateWebmSignedUrlAsStudioDeveloper = async (fileName: string): Promise<{ signedUrl: string; uploadUrl: string }> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplateWebms/signedUploadUrl?webmName=' + fileName, {});
};

export const getEffectTemplateGifsAsStudioDeveloper = async (): Promise<StudioDeveloperEffectTemplateGifI[]> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplateGifs', {});
};

export const getEffectTemplateGifSignedUrlAsStudioDeveloper = async (fileName: string): Promise<{ signedUrl: string; uploadUrl: string }> => {
	return API.get('AppsApi', '/studioDeveloper/effectTemplateGifs/signedUploadUrl?gifName=' + fileName, {});
};

interface CardResponseI {
	data: Card[];
	has_more: boolean;
	object: string;
	url: string;
}

export const getCards = async (): Promise<CardResponseI> => {
	return API.get('AppsApi', '/cardsList/', {});
};

export const getUserInvoices = async (): Promise<{
	data: InvoiceI[];
	has_more: boolean;
	object: string;
	url: string;
}> => {
	return API.get('AppsApi', '/invoices/', {});
};

export const getResouceSets = async (): Promise<ResourceSetI[]> => {
	return API.get('AppsApi', '/resourceSets', {});
};

// Update
export const updateAdminProject = async (data: EditAdminProjectFormI) => {
	var body = {
		body: {
			PlanId: data.PlanId,
			MauLimit: data.MauLimit,
			Valid: data.Valid,
			ValidResponse: data.ValidResponse,
			InvalidResponse: data.InvalidResponse,
			MultiDomainAccess: data.MultiDomainAccess,
			UserPoolUserId: data.UserPoolUserId,
			Expires: data.Expires,
		},
		headers: { 'Content-Type': 'application/json' },
	};
	return API.put('AppsApi', `/admin/projects/${data.projectId}`, body);
};

export const updateProjectSubscription = async (data: SubscriptionChangeRequestI) => {
	const request = {
		body: data,
		headers: { 'Content-Type': 'application/json' },
	};
	return API.post('AppsApi', '/subsplan/change', request);
};

export const editResourceForm = async ({
	resource,
	resourceIndex,
	resourceList,
	resourceSet,
}: {
	resource: CreateEditResourceFormI;
	resourceIndex: number;
	resourceList: ResourceI[];
	resourceSet: ResourceSetI;
}) => {
	if (typeof resourceIndex !== 'number') {
		return;
	}
	const newResourceList = [...resourceList];
	const newResourceSetResources: ResourceI[] = [...newResourceList];
	const resourceToEdit = newResourceSetResources.splice(resourceIndex, 1)[0];
	const editedResource: ResourceI = {
		...resourceToEdit,
		enabled: resource.enabled,
		name: resource.name,
		version: resource.version,
		meta: {
			...resourceToEdit.meta,
			groupId: resource.groupId,
			isPremium: resource.isPremium,
		},
	};
	newResourceList[resourceIndex] = editedResource;
	const newResourceSet: ResourceSetI = {
		...resourceSet,
		ResourceSet: {
			...resourceSet.ResourceSet,
			resources: newResourceList,
		},
	};
	var data = {
		body: newResourceSet,
		headers: { 'Content-Type': 'application/json' },
	};
	try {
		await API.put('AppsApi', '/resourceSets/' + resourceSet?.Id, data);
	} catch (error: any) {
		console.log(error.message);
	}
};

export const editResource = async ({
	resource,
	resourceIndex,
	resourceList,
	resourceSet,
}: {
	resource: ResourceI;
	resourceIndex: number;
	resourceList: ResourceI[];
	resourceSet: ResourceSetI;
}) => {
	if (typeof resourceIndex !== 'number') {
		return;
	}
	const newResourceList = [...resourceList];
	const newResourceSetResources: ResourceI[] = [...newResourceList];
	const resourceToEdit = newResourceSetResources.splice(resourceIndex, 1)[0];
	const editedResource: ResourceI = {
		...resourceToEdit,
		...resource,
		meta: {
			...resourceToEdit.meta,
			...resource.meta,
		},
	};
	newResourceList[resourceIndex] = editedResource;
	const newResourceSet: ResourceSetI = {
		...resourceSet,
		ResourceSet: {
			...resourceSet.ResourceSet,
			resources: newResourceList,
		},
	};
	var data = {
		body: newResourceSet,
		headers: { 'Content-Type': 'application/json' },
	};
	try {
		await API.put('AppsApi', '/resourceSets/' + resourceSet?.Id, data);
	} catch (error: any) {
		console.log(error.message);
	}
};

export const editResourceSet = async ({ resourceSet }: { resourceSet: ResourceSetI }) => {
	var data = {
		body: resourceSet,
		headers: { 'Content-Type': 'application/json' },
	};
	try {
		await API.put('AppsApi', '/resourceSets/' + resourceSet?.Id, data);
	} catch (error: any) {
		console.log(error.message);
	}
};

export const editEffectTemplate = async (effectTemplate: StudioDeveloperEffectTemplateI) => {
	try {
		await API.put('AppsApi', '/studioDeveloper/effectTemplates/' + effectTemplate.Id, {
			body: effectTemplate,
			headers: { 'Content-Type': 'application/json' },
		});
	} catch (error: any) {
		console.log(error.message);
	}
};

// Delete
export const deleteProject = async (projectId: string) => {
	return API.del('AppsApi', '/projects/' + projectId, {});
};

export const deleteApp = async (appId: string) => {
	return API.del('AppsApi', '/apps/' + appId, {});
};

// Custom hooks
export const useSDKNames = () => useQuery('sdkNames', getSDKNames);
export const useDownloads = () => useQuery('downloads', getDownloads);
export const useProjects = (enabled?: boolean) => useQuery('projects', getProjects, { staleTime: 50000, enabled: enabled !== undefined ? enabled : true });
export const useDemoProjects = () => useQuery('demoProjects', getDemoProjects, { staleTime: Infinity });
export const useDemoProject = (id: string) => useQuery(['demoProject', id], () => getDemoProjectById(id), { staleTime: Infinity });
export const useResourceSets = () => useQuery('resourceSets', getResouceSets, { staleTime: 50000 });
export const useAccumulatedAdminProjects = () => useQuery('accumulatedAdminProjects', getAccumulatedProjectsAndUsers, { staleTime: Infinity });
export const useAccumulatedAdminUsers = () => useQuery('accumulatedAdminUsers', getAccumulatedUsers, { staleTime: Infinity });
export const useProjectAndApps = (id: string) => useQuery(['project', id], () => getProjectAndAppsById(id), { staleTime: 50000, retry: 1 });
export const useAccountAsAdmin = (userId?: string) => useQuery(['account', userId], () => getAccountAsAdmin(userId), { staleTime: 50000 });
export const useAccountProjectsAsAdmin = (userId?: string) =>
	useQuery(['accountProjects', userId], () => getAccountProjectsAsAdmin(userId), { staleTime: 50000 });
export const useProjectAndUserAsAdmin = (projectId: string) =>
	useQuery(['adminProject', projectId], () => getProjectAndUserAsAdmin(projectId), { staleTime: 50000 });
export const useEffectTemplatesAsStudioDeveloper = () =>
	useQuery('studioDeveloperEffectTemplates', getEffectTemplatesAsStudioDeveloper, { staleTime: Infinity });
export const useEffectTemplateProjectsAsStudioDeveloper = () =>
	useQuery('studioDeveloperEffectTemplateProjects', getEffectTemplateProjectsAsStudioDeveloper, { staleTime: Infinity });
export const useEffectTemplateImagesAsStudioDeveloper = () =>
	useQuery('studioDeveloperEffectTemplateImages', getEffectTemplateImagesAsStudioDeveloper, { staleTime: Infinity });
export const useEffectTemplateWebmsAsStudioDeveloper = () =>
	useQuery('studioDeveloperEffectTemplateWebms', getEffectTemplateWebmsAsStudioDeveloper, { staleTime: Infinity });
export const useEffectTemplateGifsAsStudioDeveloper = () =>
	useQuery('studioDeveloperEffectTemplateGifs', getEffectTemplateGifsAsStudioDeveloper, { staleTime: Infinity });
export const useCards = (enabled?: boolean) => useQuery('cards', getCards, { enabled: enabled !== undefined ? enabled : true });
export const useInvoices = () => useQuery('invoices', getUserInvoices);
