import {
	Box,
	Breadcrumbs,
	Container,
	Typography,
	Link,
	Stack,
	Skeleton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Avatar,
	IconButton,
	Menu,
	MenuItem,
} from '@mui/material';
import React from 'react';
import { useParams, Link as RouterLink, useHistory } from 'react-router-dom';
import { useAccountAsAdmin, useAccountProjectsAsAdmin } from '../../utilities/queries';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import MauChart, { transformForMauChart } from '../Projects/Project/MauChart';
import { getMonthString, lastMonthStrings, stringAvatar } from '../../utilities/helpers';
import PrivatePlansPermissionsDialog from './PrivatePlansPermissionsDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface MatchParams {
	userId?: string;
}

const AdminAccount = () => {
	const { userId } = useParams<MatchParams>();
	const history = useHistory();

	const { data: account, isLoading: accountLoading } = useAccountAsAdmin(userId);
	const { data: projects, isLoading: projectsLoading } = useAccountProjectsAsAdmin(userId);

	const [privatePermissionsDialogOpen, setPrivatePermissionsDialogOpen] = React.useState(false);
	const [moreMenuElement, setMoreMenuElement] = React.useState<null | HTMLElement>(null);

	const attrs = account?.UserAttributes;
	const given_name = attrs?.find((item) => item.Name === 'given_name')?.Value || '';
	const family_name = attrs?.find((item) => item.Name === 'family_name')?.Value || '';
	const email = attrs?.find((item) => item.Name === 'email')?.Value || '';
	const company = attrs?.find((item) => item.Name === 'custom:company_name')?.Value || '';

	const phone = attrs?.find((item) => item.Name === 'custom:phone')?.Value || '';
	const companyWebsite = attrs?.find((item) => item.Name === 'custom:company_website')?.Value || '';

	const address = attrs?.find((item) => item.Name === 'address')?.Value || '';
	const zipCode = attrs?.find((item) => item.Name === 'custom:zip_code')?.Value || '';
	const city = attrs?.find((item) => item.Name === 'custom:city')?.Value || '';
	const country = attrs?.find((item) => item.Name === 'custom:country')?.Value || '';

	const vatID = attrs?.find((item) => item.Name === 'custom:vat_id')?.Value || '';

	const createdAt = new Date(account?.UserCreateDate || '');

	function dateDiffInMonths(a: Date, b: Date) {
		const _MS_PER_DAY = 1000 * 60 * 60 * 24 * 30;
		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

		return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	}

	const monthStrings = React.useMemo(() => {
		return lastMonthStrings(3);
	}, []);

	const formattedMonthString = (userCreatedAt: Date) => {
		const dateDiff = dateDiffInMonths(userCreatedAt, new Date());
		if (dateDiff > 1) {
			return `${dateDiff} months`;
		} else if (dateDiff === 1) {
			return `${dateDiff} month`;
		} else {
			return 'Less than a month';
		}
	};

	const handleOpenPermissionsDialog = () => {
		setPrivatePermissionsDialogOpen(true);
		setMoreMenuElement(null);
	};

	if (accountLoading || projectsLoading) {
		return (
			<Container component={Box} paddingY={4}>
				<Stack direction='row' spacing={4}>
					<Skeleton variant='rectangular' height={50} />
				</Stack>
				<Stack direction='column' spacing={2}>
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
				</Stack>
			</Container>
		);
	}

	return (
		<Container component={Box} pt={6} pb={12}>
			<Stack direction='row' alignItems='center' justifyContent='space-between'>
				<Breadcrumbs aria-label='breadcrumb'>
					<Link underline='hover' color='inherit' component={RouterLink} to='/admin'>
						Admin
					</Link>
					<Typography color='text.primary'> Users</Typography>
					<Typography color='text.primary'>{`${given_name} ${family_name}`}</Typography>
				</Breadcrumbs>
				<Box minWidth={4} sx={{ textAlign: 'center' }}>
					<IconButton
						aria-label='menu'
						onClick={(e) => setMoreMenuElement(e.currentTarget)}
						sx={{
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id='menu-project'
						anchorEl={moreMenuElement}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={!!moreMenuElement}
						onClose={() => setMoreMenuElement(null)}
					>
						<MenuItem onClick={handleOpenPermissionsDialog}>
							<Stack direction='row' spacing={1}>
								<AttachMoneyIcon color='disabled' />
								<Typography textAlign='center'>Change private plans access</Typography>
							</Stack>
						</MenuItem>
					</Menu>
				</Box>
			</Stack>
			<Stack direction='row' sx={{ py: 4 }} justifyContent='space-between'>
				<Stack direction='row' spacing={3} alignItems='center' sx={{ flex: 1 }}>
					<Avatar alt={email} {...stringAvatar(email, 100)} />
					<Box>
						<Typography variant='h5'>{email}</Typography>
						<Typography variant='h6' color='text.secondary'>
							{company}
						</Typography>
						<Typography variant='body2' color='text.secondary'>{`User since ${getMonthString(
							createdAt.getUTCMonth()
						)} ${createdAt.getFullYear()} (${formattedMonthString(createdAt)})`}</Typography>
					</Box>
				</Stack>
				<Box sx={{ flex: 1 }}>
					{phone && (
						<Stack direction='row'>
							<Box sx={{ minWidth: '120px' }}>
								<Typography variant='body1' color='text.secondary'>
									Phone:
								</Typography>
							</Box>
							<Typography variant='body1'>{phone}</Typography>
						</Stack>
					)}
					{companyWebsite && (
						<Stack direction='row'>
							<Box sx={{ minWidth: '120px' }}>
								<Typography variant='body1' color='text.secondary'>
									Website:
								</Typography>
							</Box>
							<Typography variant='body1'>{companyWebsite}</Typography>
						</Stack>
					)}
					{address && (
						<Stack direction='row'>
							<Box sx={{ minWidth: '120px' }}>
								<Typography variant='body1' color='text.secondary'>
									Address:
								</Typography>
							</Box>
							<Typography variant='body1'>{address}</Typography>
						</Stack>
					)}
					{zipCode && (
						<Stack direction='row'>
							<Box sx={{ minWidth: '120px' }}>
								<Typography variant='body1' color='text.secondary'>
									Zip/Post Code:
								</Typography>
							</Box>
							<Typography variant='body1'>{zipCode}</Typography>
						</Stack>
					)}
					{city && (
						<Stack direction='row'>
							<Box sx={{ minWidth: '120px' }}>
								<Typography variant='body1' color='text.secondary'>
									City:
								</Typography>
							</Box>
							<Typography variant='body1'>{city}</Typography>
						</Stack>
					)}
					{country && (
						<Stack direction='row'>
							<Box sx={{ minWidth: '120px' }}>
								<Typography variant='body1' color='text.secondary'>
									Country:
								</Typography>
							</Box>
							<Typography variant='body1'>{country}</Typography>
						</Stack>
					)}
					{vatID && (
						<Stack direction='row'>
							<Box sx={{ minWidth: '120px' }}>
								<Typography variant='body1' color='text.secondary'>
									Vat ID:
								</Typography>
							</Box>
							<Typography variant='body1'>{vatID}</Typography>
						</Stack>
					)}
				</Box>
			</Stack>

			<TableContainer component={Paper}>
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>Project Name</TableCell>
							<TableCell>Details</TableCell>
							<TableCell>Plan</TableCell>
							<TableCell>Valid</TableCell>
							<TableCell>MAU</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{projects?.map((project, i) => (
							<TableRow
								key={project.Name}
								sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
								onClick={() => history.push(`/admin/projects/${project.Id}`)}
								hover
							>
								<TableCell>{project.Name}</TableCell>
								<TableCell>
									{project.InvalidMau ? 'Exceeded MAU limit (' + project.InvalidMauDay + ' days)' : ''}
									{project.InvalidMau && project.InvalidPayment ? ', ' : ''}
									{project.InvalidPayment ? 'Payment error (' + project.InvalidPaymentDay + ' days)' : ''}
								</TableCell>
								<TableCell>{project.PlanId}</TableCell>
								<TableCell>{project.Valid ? <CheckIcon color='success' /> : <WarningIcon color='warning' />}</TableCell>
								<TableCell sx={{ maxWidth: '100px' }}>
									<MauChart tiny data={transformForMauChart(monthStrings, project.Mau)} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{privatePermissionsDialogOpen && account && (
				<PrivatePlansPermissionsDialog
					open
					onClose={() => setPrivatePermissionsDialogOpen(false)}
					closeDialog={() => setPrivatePermissionsDialogOpen(false)}
					account={account}
				/>
			)}
		</Container>
	);
};

export default AdminAccount;
