import { Box, Paper } from '@mui/material';
import React from 'react';

interface LoginProps {
	children: React.ReactNode;
}

const LoginWrapper = (props: LoginProps) => {
	return (
		<Box
			sx={{
				height: '100vh',
				width: '100vw',
				backgroundImage: 'url(/assets/dynamic-login-gradient.svg)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Paper
				sx={{
					width: {
						xs: '100vw',
						sm: '450px',
					},
					borderRadius: {
						xs: 0,
						sm: 2,
					},
					height: {
						xs: '100vh',
						sm: 'auto',
					},
					maxHeight: '100vh',
					overflow: 'auto',
				}}
			>
				{props.children}
			</Paper>
		</Box>
	);
};

export default LoginWrapper;
