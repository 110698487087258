import { Box, Button, Container, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useProjects } from '../../utilities/queries';
import { AppContext } from '../../utilities/StateProvider';
import AddIcon from '@mui/icons-material/Add';
import CreateProjectDialog from './CreateProjectDialog';
import ProjectListItem from './ProjectListItem';
import { Elements } from '@stripe/react-stripe-js';
import { useHistory, useParams } from 'react-router-dom';

const Projects = () => {
	const { data, isLoading } = useProjects();
	const { state, stripePromise } = React.useContext(AppContext);
	const history = useHistory();

	const params = useParams<{ plan: string }>();
	const createNewProject = !!params?.plan;

	const [createProjectOpen, setCreateProjectOpen] = React.useState(createNewProject);

	const loading = state.appAuthState.appAuthState === 'loading' || isLoading;

	const handleCloseCreateProjectDialog = (isOpen: boolean) => {
		setCreateProjectOpen(isOpen);
		history.replace('/projects');
	};

	return (
		<Container component={Box} pt={4} pb={8}>
			<Stack paddingY={2} direction='row' justifyContent='space-between' mb={3} flexWrap='wrap'>
				<Box sx={{ marginBottom: 2 }}>
					<Typography variant='h5' gutterBottom>
						Projects {!loading && `(${data?.length})`}
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						Your DeepAR SDK instances. Each project can contain one app for each major platform.
					</Typography>
				</Box>
				<Button variant='text' startIcon={<AddIcon />} onClick={() => setCreateProjectOpen(true)}>
					New project
				</Button>
			</Stack>
			{loading ? (
				<Stack spacing={2}>
					{[1, 2, 3, 4].map((v, i) => (
						<Skeleton variant='rectangular' height={200} key={i} />
					))}
				</Stack>
			) : (
				<Box>
					{!data?.length && (
						<Box sx={{ textAlign: 'center', py: 10 }}>
							<Typography variant='h3' mb={4}>
								Create a project to begin using the DeepAR SDK
							</Typography>
							<Button variant='contained' onClick={() => setCreateProjectOpen(true)}>
								{' '}
								Create a project
							</Button>
						</Box>
					)}
					{data?.map((project, i) => (
						<ProjectListItem project={project} key={i} />
					))}
				</Box>
			)}
			{createProjectOpen && (
				<Elements stripe={stripePromise}>
					<CreateProjectDialog
						open
						onClose={() => handleCloseCreateProjectDialog(false)}
						setCreateProjectOpen={handleCloseCreateProjectDialog}
						defaultPlanId={params?.plan}
					/>
				</Elements>
			)}
		</Container>
	);
};

export default Projects;
