import React from 'react';

import {
	Box,
	Button,
	Checkbox,
	DialogContent,
	DialogProps,
	FormControlLabel,
	FormHelperText,
	IconButton,
	LinearProgress,
	Link,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PlanSelector from '../Pricing/PlanSelector/PlanSelector';
import { AppContext } from '../../utilities/StateProvider';
import { createFreeProject, createPaidProject, NewPaidProjectRequest, useCards } from '../../utilities/queries';
import { useMutation, useQueryClient } from 'react-query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { API } from 'aws-amplify';
import CustomProjectRequestConfirmation from './CustomProjectRequestConfirmation';
import { userDataHandler } from '../../utilities/helpers';
import SelectOrCreateCard from './SelectOrCreateCard';
import { useHistory } from 'react-router-dom';
import PaidProjectTotal from './PaidProjectTotal';
import CouponCode from './Project/CouponCode';
import { getCurrUser } from '../../utilities/actions';
import CustomAlert from '../../utilities/CustomAlert';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import trackEvent from '../../tracking';
import { sendCustomGAEvent } from '../../utilities/tracking';
import { pricePlans } from '../../pricePlans';

export interface CreateProjectFormI {
	name: string;
	message?: string;
	planId: string;
	userEmail: string;
	agreed?: boolean;
}

interface CreateProjectDialogPropsI extends DialogProps {
	setCreateProjectOpen: (isOpen: boolean) => void;
	defaultPlanId?: string;
	redirect?: boolean;
	newProjectId?: (id: string) => void;
}

const CreateProjectDialog = (props: CreateProjectDialogPropsI) => {
	const stripe = useStripe();
	const elements = useElements();

	const { state, setGlobalAlertMessage, dispatch } = React.useContext(AppContext);
	const { setCreateProjectOpen, defaultPlanId, redirect = true, newProjectId, ...rest } = props;
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
	} = useForm<CreateProjectFormI>({
		defaultValues: {
			planId: defaultPlanId || 'free',
			agreed: state.appAuthState.currentUserAttributes?.AgreedToTermOfUse,
		},
	});
	const queryClient = useQueryClient();
	const history = useHistory();

	const [loading, setLoading] = React.useState(false);
	const [submittedCustomProjectRequest, setSubmittedCustomProjectRequest] = React.useState(false);

	const stripeId = React.useMemo(() => {
		return state.appAuthState.currentUser?.attributes?.['custom:stripe_id'];
	}, [state]);

	const { data: cardsData, isLoading: cardsLoading } = useCards(!!stripeId);
	const [createNewCard, setCreateNewCard] = React.useState(false);
	const [selectedCard, setSelectedCard] = React.useState('');
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	const [couponCode, setCouponCode] = React.useState('');

	const mutationFree = useMutation(createFreeProject, {
		onSuccess: async (_data, variables) => {
			trackEvent('Project Created', { plan: variables.project.planId, name: variables.project.name });
			sendCustomGAEvent({ category: 'Project', action: 'Create', label: variables.project.planId, value: 0 });
			queryClient.invalidateQueries('projects');
		},
		onError: (error: any) => {
			console.log('Mutation error', error);
			setServerErrorMessage(error.message);
		},
	});

	const mutationPaid = useMutation(createPaidProject, {
		onSuccess: async (data, variables) => {
			trackEvent('Project Created', { plan: variables.project.planId, name: variables.project.name });
			const plans = Object.values(pricePlans);
			const selectedPlanPrice = plans.find((p) => p.id === variables.project.planId)?.price;
			sendCustomGAEvent({ category: 'Project', action: 'Create', label: variables.project.planId, value: selectedPlanPrice });

			queryClient.invalidateQueries('projects');
			queryClient.invalidateQueries('cards');

			// refresh user in case of stripeID change
			const appAuthState = await getCurrUser(state.appAuthState);
			dispatch({ type: 'CHANGE_AUTH_STATE', payload: { ...appAuthState } });

			if (!data.pay_int?.next_action) {
				data.item?.Id && newProjectId?.(data.item?.Id);
				if (redirect) {
					history.push(`projects/${data.item?.Id}`);
				} else {
					setCreateProjectOpen(false);
				}
				setGlobalAlertMessage({ message: 'Project created successfully' });
			} else {
				if (data.pay_int.client_secret && data.pay_int.source) {
					await stripe?.confirmCardPayment(data.pay_int.client_secret, {
						payment_method: data.pay_int.source,
					});

					if (redirect) {
						history.push(`projects/${data.item?.Id}`);
					} else {
						setCreateProjectOpen(false);
					}
					setGlobalAlertMessage({ message: 'Project created with errors', severity: 'warning' });
				}
			}
		},
		onError: (error: any) => {
			console.log('Error: error', error);
		},
	});

	const isCustomPlan = watch().planId === 'xxl-tier';
	const isFreePlan = watch().planId === 'free';
	const isTermsAgreed = state.appAuthState.currentUserAttributes?.AgreedToTermOfUse;

	const handleCreateProject: SubmitHandler<CreateProjectFormI> = async (data: CreateProjectFormI) => {
		setLoading(true);
		setServerErrorMessage('');

		if (data.planId === 'xxl-tier') {
			// handle send custom project request
			const request = {
				body: { message: data.message, name: data.name },
				headers: { 'Content-Type': 'application/json' },
			};

			try {
				await API.post('AppsApi', '/customProject', request);
				sendCustomGAEvent({ category: 'Project', action: 'Request', label: data.planId, value: 0 });
				setSubmittedCustomProjectRequest(true);
			} catch (error) {
				setServerErrorMessage('There was a problem sending your request. Please try again.');
			} finally {
				setLoading(false);
			}
		} else if (data.planId === 'free') {
			// handle free project
			try {
				const createdProject = await mutationFree.mutateAsync({
					project: {
						...data,
					},
					AgreedToTermOfUse: true,
				});
				createdProject.Id && newProjectId?.(createdProject.Id);
				if (redirect) {
					history.push(`projects/${createdProject.Id}`);
				} else {
					setCreateProjectOpen(false);
				}
			} catch (error: any) {
				setServerErrorMessage(error?.response?.data?.error?.message);
			} finally {
				setLoading(false);
			}
		} else {
			// handle paid project
			const user = userDataHandler(state.appAuthState.currentUser);

			try {
				if (createNewCard) {
					// use new card
					if (stripe && elements) {
						const stripeCardElement = elements.getElement(CardElement);
						if (stripeCardElement) {
							// get token from Stripe
							const { token, error } = await stripe.createToken(stripeCardElement);

							const paidProjectData: NewPaidProjectRequest = {
								project: {
									...data,
								},
								AgreedToTermOfUse: true,
								billingInfo: {
									email: user?.email,
									address: user?.address,
									city: user?.city,
									zipCode: user?.zipCode,
									firstName: user?.firstName,
									lastName: user?.lastName,
									country: user?.country.value,
									vatId: user?.vatId,
									companyName: user?.companyName,
									token: token?.id,
								},
								...(!!couponCode ? { couponId: couponCode } : undefined),
							};

							if (error) {
								throw new Error(error.message);
							}

							if (token) {
								await mutationPaid.mutateAsync(paidProjectData);
							}
						}
					}
				} else {
					// use selected card
					const paidProjectData: NewPaidProjectRequest = {
						project: {
							...data,
						},
						AgreedToTermOfUse: true,
						billingInfo: {
							email: user?.email,
							address: user?.address,
							city: user?.city,
							zipCode: user?.zipCode,
							firstName: user?.firstName,
							lastName: user?.lastName,
							country: user?.country.value,
							vatId: user?.vatId,
							companyName: user?.companyName,
							cardId: selectedCard,
						},
						...(!!couponCode ? { couponId: couponCode } : undefined),
					};

					await mutationPaid.mutateAsync(paidProjectData);
				}
			} catch (error: any) {
				console.error(error);
				setServerErrorMessage(error?.response ? error?.response.data.message : error.message ? error?.message : 'Credit card error.');
			} finally {
				setLoading(false);
			}
		}
	};

	const isPaidProject = !isCustomPlan && !isFreePlan;

	return (
		<ResponsiveDialog
			{...rest}
			sx={{
				'& .MuiDialog-scrollPaper': {
					alignItems: 'start',
				},
			}}
		>
			<Box sx={{ height: '10px' }}>{(mutationPaid.isLoading || mutationFree.isLoading || loading) && <LinearProgress />}</Box>
			<DialogContent
				sx={{
					maxWidth: '450px',
					margin: 'auto',
				}}
			>
				<Box>
					<Box sx={{ p: 1 }} textAlign='center'>
						<Typography variant='h5'>{submittedCustomProjectRequest ? 'Thank you' : 'Create Project'}</Typography>
						<IconButton
							aria-label='close'
							onClick={() => setCreateProjectOpen(false)}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box>{!!serverErrorMessage && <CustomAlert severity='warning' message={serverErrorMessage} />}</Box>
					{!submittedCustomProjectRequest && (
						<Stack
							sx={{ mt: 1, textAlign: 'left', width: '100%' }}
							component='form'
							onSubmit={handleSubmit((data) => handleCreateProject({ ...data, userEmail: state.appAuthState.currentUserAttributes?.Email || '' }))}
							direction='column'
						>
							<Box>
								<TextField
									{...register('name', { required: true })}
									margin='normal'
									required
									fullWidth
									id='name'
									label='Project Name'
									name='name'
									error={!!errors?.name}
									helperText={!!errors?.name && errors.name.message}
									autoFocus
								/>
								<Controller
									control={control}
									name='planId'
									render={({ field: { value, onChange } }) => <PlanSelector selectedPlanId={value} setSelectedPlanId={onChange} />}
								/>

								{isPaidProject && (
									<SelectOrCreateCard
										cards={cardsData?.data}
										loading={cardsLoading}
										createNewCard={createNewCard}
										setCreateNewCard={setCreateNewCard}
										selectedCard={selectedCard}
										setSelectedCard={setSelectedCard}
									/>
								)}

								{isPaidProject && <CouponCode pricePlanId={watch().planId} couponCode={couponCode} setCouponCode={setCouponCode} />}

								{isPaidProject && <PaidProjectTotal pricePlanId={watch().planId} couponCode={couponCode} />}

								{isCustomPlan && (
									<TextField
										{...register('message', { required: isCustomPlan })}
										margin='normal'
										required
										multiline
										fullWidth
										id='message'
										label='What would you like to build?'
										name='message'
										error={!!errors?.message}
										helperText={!!errors?.message && errors.message.message}
										autoFocus
									/>
								)}
								{!isTermsAgreed && (
									<>
										<FormControlLabel
											control={
												<Controller
													name='agreed'
													control={control}
													rules={{ required: true }}
													render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
												/>
											}
											sx={{ px: 1 }}
											label={
												<Stack direction='row' spacing={0.8}>
													<Typography variant='body1'>I agree to the</Typography>
													<Link href='https://www.deepar.ai/terms' target='_blank'>
														Terms and Conditions
													</Link>
												</Stack>
											}
										/>
										{!!errors.agreed && <FormHelperText>Please agree to the DeepAR Terms and Conditions</FormHelperText>}
									</>
								)}
							</Box>
							<Stack direction='row' spacing={1} mt={3}>
								<Button variant='text' fullWidth size='large' onClick={() => setCreateProjectOpen(false)}>
									Cancel
								</Button>
								<Button variant='contained' fullWidth size='large' type='submit'>
									Continue
								</Button>
							</Stack>
						</Stack>
					)}
					{submittedCustomProjectRequest && <CustomProjectRequestConfirmation onClose={() => setCreateProjectOpen(false)} />}
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default CreateProjectDialog;
