import React from 'react';
import ReactGA from 'react-ga4';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { loadStripe, Stripe } from '@stripe/stripe-js';

import { getCurrUser } from './actions';
import { ActionT, GlobalStateI, reducer } from './store';
import { Alert, AlertProps, createTheme, IconButton, PaletteMode, Snackbar, ThemeProvider } from '@mui/material';
import { getComponentOptions, getPalette, typographyOptions } from '../styles/theme';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory, useLocation } from 'react-router-dom';
import { updateUserInIntercom } from './helpers';

const initialState: GlobalStateI = {
	appAuthState: {
		isAdmin: false,
		isStudioDeveloper: false,
		currentUser: null,
		currentUserAttributes: null,
		appAuthState: 'loading',
	},
};

export interface GlobalSnackbarPropsI {
	message: string;
	severity?: AlertProps['severity'];
}

export const AppContext = React.createContext<{
	state: GlobalStateI;
	dispatch: React.Dispatch<ActionT>;
	stripePromise: Promise<Stripe | null>;
	setGlobalAlertMessage: React.Dispatch<React.SetStateAction<GlobalSnackbarPropsI | null>>;
}>({
	state: initialState,
	dispatch: () => null,
	stripePromise: new Promise(() => null),
	setGlobalAlertMessage: () => null,
});
const { Provider } = AppContext;

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const environment = process.env.REACT_APP_STAGE || 'dev';
const stripeApiKey = environment === 'prod' ? 'pk_live_QC3FgeAjCPav1LlQtqB5t49M' : 'pk_test_BmMEm6XXbl3nyG21Vm2CzKYU';
const stripePromise = loadStripe(stripeApiKey);
const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

const StateProvider: React.FC = ({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const [mode, setMode] = React.useState<PaletteMode>(darkThemeMq.matches ? 'dark' : 'light');
	const [globalAlertMessage, setGlobalAlertMessage] = React.useState<GlobalSnackbarPropsI | null>(null);

	const user_id = state.appAuthState.currentUser?.username;
	const user = state.appAuthState.currentUser;
	React.useEffect(() => {
		if (user_id) {
			ReactGA.set({ user_id });
			if (user) {
				updateUserInIntercom(user,'boot');
			}
		}
	}, [user_id]);

	const location = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		// look for attribution ID and store it
		const urlParams = new URLSearchParams(location?.search);
		const attributionId = urlParams.get('attributionId');
		if (attributionId) {
			window.localStorage.setItem('attributionId', attributionId);
			urlParams.delete('attributionId');
		}

		// look for studio login flag and store it
		const studioLogin = urlParams.get('studioLogin');
		if (studioLogin != null) {
			window.sessionStorage.setItem('studioLogin', '1');
			urlParams.delete('studioLogin');
		}

		if (attributionId || studioLogin != null) {
			history.replace({
				pathname: location?.pathname,
				search: '?' + urlParams.toString(),
			});
		}
		//eslint-disable-next-line
	}, [location]);

	const queryClient = React.useMemo(() => {
		return new QueryClient();
	}, []);

	React.useEffect(() => {
		const fetchUser = async () => {
			const appAuthState = await getCurrUser(state.appAuthState);
			dispatch({ type: 'CHANGE_AUTH_STATE', payload: { ...appAuthState } });
		};

		fetchUser();
		//eslint-disable-next-line
	}, []);

	const colorMode = React.useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
			},
		}),
		[]
	);

	const theme = React.useMemo(
		() =>
			createTheme({
				typography: typographyOptions,
				palette: {
					...getPalette(mode),
				},
				components: getComponentOptions(mode),
			}),
		[mode]
	);

	const snackbarAction = (
		<React.Fragment>
			<IconButton size='small' aria-label='close' color='inherit' onClick={() => setGlobalAlertMessage(null)}>
				<CloseIcon fontSize='small' />
			</IconButton>
		</React.Fragment>
	);

	return (
		<QueryClientProvider client={queryClient} contextSharing>
			<ColorModeContext.Provider value={colorMode}>
				<ThemeProvider theme={theme}>
					<Provider value={{ state, dispatch, stripePromise, setGlobalAlertMessage }}>{children}</Provider>
					<Snackbar open={!!globalAlertMessage} onClose={() => setGlobalAlertMessage(null)} action={snackbarAction} autoHideDuration={6000}>
						<Alert onClose={() => setGlobalAlertMessage(null)} severity={globalAlertMessage?.severity || 'success'} sx={{ width: '100%' }}>
							{globalAlertMessage?.message}
						</Alert>
					</Snackbar>
				</ThemeProvider>
			</ColorModeContext.Provider>
			<ReactQueryDevtools initialIsOpen />
		</QueryClientProvider>
	);
};

export default StateProvider;
