import { Box, Button, Dialog, DialogContent, DialogProps, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import { deleteApp, ProjectAppI, ProjectI } from '../../../../utilities/queries';
import { ProjectAppTypeI } from './ProjectApp';
import ResponsiveDialog from '../../../../components/ResponsiveDialog';

interface DeleteProjectAppConfirmationDialogPropsI extends DialogProps {
	project: ProjectI;
	app: ProjectAppI;
	appType: ProjectAppTypeI;
	closeDialog: () => void;
}

const DeleteProjectAppConfirmationDialog = (props: DeleteProjectAppConfirmationDialogPropsI) => {
	const { app, project, closeDialog, ...rest } = props;

	const queryClient = useQueryClient();

	const mutation = useMutation(deleteApp, {
		onSuccess: async () => {
			if (!!project) {
				queryClient.setQueryData<ProjectI>(['project', project.Id], () => {
					const newApps = project?.apps.filter((a) => a.Id !== app.Id) || [];
					return {
						...project,
						apps: newApps,
					};
				});
			}
			closeDialog();
		},
	});

	const handleDeleteProjectApp = async (appId: string) => {
		try {
			await mutation.mutateAsync(appId);
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{mutation.isLoading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '600px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Delete {props.appType.title}?</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box py={2}>
						<Typography>This application and it's SDK key will be deleted. Delete anyway?</Typography>
					</Box>
					<Stack direction='row' spacing={1} mt={3}>
						<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
							Cancel
						</Button>
						<Button variant='contained' color='error' fullWidth size='large' onClick={() => handleDeleteProjectApp(app.Id)}>
							Delete Application
						</Button>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default DeleteProjectAppConfirmationDialog;
