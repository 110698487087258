import React from 'react';

import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogProps,
	FormControlLabel,
	IconButton,
	LinearProgress,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { editResourceForm, ResourceI, ResourceSetI } from '../../utilities/queries';
import { API } from 'aws-amplify';
import { AppContext } from '../../utilities/StateProvider';
import ResponsiveDialog from '../../components/ResponsiveDialog';

interface CreateEditResourcePropsI extends DialogProps {
	closeDialog: () => void;
	_resource?: ResourceI;
	resourceSet: ResourceSetI;
	resourceIndex?: number;
}

export interface CreateEditResourceFormI {
	name: string;
	version: number;
	enabled: boolean;
	isPremium: boolean;
	groupId: string;
}

const groupOptions = [
	{ key: 'filters', text: 'Filters', value: 'filters' },
	{ key: 'effects', text: 'Effects', value: 'effects' },
	{ key: 'emojis', text: 'Emojis', value: 'emojis' },
];

const CreateEditResourceDialog = (props: CreateEditResourcePropsI) => {
	const { closeDialog, resourceSet, _resource: resource, resourceIndex, ...rest } = props;

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<CreateEditResourceFormI>({
		defaultValues: {
			name: resource ? resource.name : undefined,
			version: resource ? resource.version : 1,
			enabled: resource ? resource.enabled : true,
			isPremium: resource ? resource.meta.isPremium : false,
			groupId: resource ? resource.meta.groupId : groupOptions[0].value,
		},
	});

	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const queryClient = useQueryClient();

	const memoizedResourceList = React.useMemo(() => {
		return resourceSet?.ResourceSet?.resources;
	}, [resourceSet]);

	const createNewResource = async (resource: CreateEditResourceFormI) => {
		const newResource: ResourceI = {
			enabled: resource.enabled,
			name: resource.name,
			version: resource.version,
			url: '',
			meta: {
				groupId: resource.groupId,
				isPremium: resource.isPremium,
				thumbUrl: '',
			},
		};
		const newResourceSetResources: ResourceI[] = [...(resourceSet?.ResourceSet?.resources || []), newResource];
		const newResourceSet: ResourceSetI = {
			...resourceSet,
			ResourceSet: {
				...resourceSet.ResourceSet,
				resources: newResourceSetResources,
			},
		};
		var data = {
			body: newResourceSet,
			headers: { 'Content-Type': 'application/json' },
		};

		try {
			await API.put('AppsApi', '/resourceSets/' + resourceSet?.Id, data);
		} catch (error: any) {
			console.log(error.message);
		}
	};

	const createResourceMutation = useMutation(createNewResource, {
		onSuccess: async () => {
			queryClient.invalidateQueries('resourceSets');
			setGlobalAlertMessage({ message: 'New resource created' });
			closeDialog();
		},
	});

	const editResourceMutation = useMutation(editResourceForm, {
		onSuccess: async () => {
			queryClient.invalidateQueries('resourceSets');
			setGlobalAlertMessage({ message: 'Resource saved successfully' });
			closeDialog();
		},
	});

	const handleSaveResource: SubmitHandler<CreateEditResourceFormI> = async (data: CreateEditResourceFormI) => {
		try {
			if (resource && typeof resourceIndex === 'number') {
				editResourceMutation.mutateAsync({
					resource: data,
					resourceIndex,
					resourceList: memoizedResourceList,
					resourceSet,
				});
			} else {
				createResourceMutation.mutateAsync(data);
			}
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{createResourceMutation.isLoading || (editResourceMutation.isLoading && <LinearProgress />)}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>{resource ? 'Edit' : 'New'} Resource</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} component='form' onSubmit={handleSubmit(handleSaveResource)} direction='column'>
						<Box>
							<Stack direction='row' alignItems='center'>
								<TextField
									{...register('name', { required: true })}
									margin='normal'
									required
									fullWidth
									id='name'
									label='Name'
									name='name'
									error={!!errors?.name}
									helperText={!!errors?.name && errors.name.message}
									autoFocus
								/>
								<TextField
									{...register('version', { required: true })}
									sx={{ ml: 1 }}
									margin='normal'
									required
									type='number'
									id='version'
									label='Version'
									name='version'
									error={!!errors?.version}
									helperText={!!errors?.version && errors.version.message}
								/>
							</Stack>
							<Stack direction='row' spacing={2} pb={2}>
								<FormControlLabel
									control={
										<Controller
											name='enabled'
											control={control}
											render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
										/>
									}
									label='Enabled'
								/>
								<FormControlLabel
									control={
										<Controller
											name='isPremium'
											control={control}
											render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
										/>
									}
									label='Premium'
								/>
							</Stack>
							<Controller
								name='groupId'
								control={control}
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										value={groupOptions.find((o) => o.value === value)}
										onChange={(e, v) => onChange(v?.value)}
										id='groupId'
										getOptionLabel={(option) => option.text}
										isOptionEqualToValue={(option, value) => option.key === value.key}
										options={groupOptions}
										sx={{ width: 300 }}
										renderInput={(params) => <TextField {...params} label='Group ID' error={!!errors.groupId} helperText={!!errors.groupId?.message} />}
									/>
								)}
							/>
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' type='submit'>
								Save
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default CreateEditResourceDialog;
