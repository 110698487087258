import { Box, Button, Typography } from '@mui/material';
import React from 'react';

interface CustomProjectRequestConfirmationPropsI {
	onClose: () => void;
}

const CustomProjectRequestConfirmation = (props: CustomProjectRequestConfirmationPropsI) => {
	return (
		<>
			<Typography>Our team has received your request and will reply soon.</Typography>
			<Box py={2}>
				<Button variant='contained' fullWidth size='large' onClick={props.onClose}>
					OK
				</Button>
			</Box>
		</>
	);
};

export default CustomProjectRequestConfirmation;
