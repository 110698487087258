import { Box, Card, CardContent, CardMedia, Chip, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { DemoProject } from '../../Models/demoProjects';

const DemoProjectsCard = ({ ImageUrl, Title, Description, Id, Code }: DemoProject) => {
	return (
		<Grid item xs={12} sm={6} lg={4} component={Link} to={`/demo-projects/${Id}`}>
			<Card sx={{ borderRadius: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
				<CardMedia component='img' height='300' image={ImageUrl} alt={Title} />
				<CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pb: 0, ':last-child': { pb: 2 } }}>
					<Typography gutterBottom variant='h3' component='div'>
						{Title}
					</Typography>
					<Box sx={{ flexGrow: 1, pb: 3 }}>
						<Typography variant='body2' color='text.secondary' gutterBottom>
							{Description}
						</Typography>
					</Box>
					<Stack direction='row' paddingBottom={1} flexWrap='wrap'>
						{Code.map((code, i) => (
							<Chip label={`${code.Platform} (${code.Language})`} key={i} sx={{ mt: 1, mr: 1 }} />
						))}
					</Stack>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default DemoProjectsCard;
