import React from 'react';
import { PricePlanI } from '../../../pricePlans';
import { Box, Stack, Switch, Typography } from '@mui/material';

interface PlanPricePropsI {
	plan: PricePlanI;
	isAnnualPlan: boolean;
	setIsAnnualPlan: (isAnnual: boolean) => void;
}

const PlanPrice = (props: PlanPricePropsI) => {
	const { plan } = props;

	return (
		<Stack direction='row' alignItems='center' flexWrap='wrap'>
			<Box padding={1} flex={1} sx={{ textAlign: 'center' }}>
				{plan.id === 'free' && (
					<Typography variant='h3'>
						Free{' '}
						<span role='img' aria-label='sunglasses emoji'>
							😎
						</span>
					</Typography>
				)}
				{plan.id === 'xxl-tier' && (
					<Box sx={{ textAlign: 'left', mt: 1 }}>
						<Typography variant='h5' gutterBottom>
							We work with numerous high volume clients like you
						</Typography>
						<Typography variant='body1' color='text.secondary'>
							Get in touch with amazing Sales team. We reply super quickly. 💙
						</Typography>
					</Box>
				)}
				{plan.id !== 'xxl-tier' && plan.id !== 'free' && (
					<Box>
						<Stack direction='row' alignItems='baseline' justifyContent='center'>
							<Typography variant='h3'>${plan.price}</Typography>
							<Typography variant='body2' color='text.secondary'>
								/{plan.id.includes('annual') ? 'year' : 'month'}
							</Typography>
						</Stack>
						<Stack direction='row' alignItems='center' pt={1}>
							<Typography color='text.secondary'>Monthly</Typography>
							<Switch checked={props.isAnnualPlan} onChange={() => props.setIsAnnualPlan(!props.isAnnualPlan)} />
							<Typography color='text.secondary'>Annual</Typography>
						</Stack>
					</Box>
				)}
			</Box>
			{plan.id !== 'xxl-tier' && (
				<Box padding={1} flex={2}>
					<ul>
						<li>
							<Typography variant='body1' color='text.secondary'>
								{plan.row1}
							</Typography>
						</li>
						<li>
							<Typography variant='body1' color='text.secondary'>
								{plan.row2}
							</Typography>
						</li>
						<li>
							<Typography variant='body1' color='text.secondary'>
								{plan.row3}
							</Typography>
						</li>
					</ul>
				</Box>
			)}
		</Stack>
	);
};

export default PlanPrice;
