import React from 'react';

import { Alert, Box, Button, DialogContent, DialogProps, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { ProjectI, SubscriptionChangeRequestI, updateProjectSubscription, useCards } from '../../../utilities/queries';
import PlanSelector from '../../Pricing/PlanSelector/PlanSelector';
import SelectOrCreateCard from '../SelectOrCreateCard';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { getUpgradedPricePlan, userDataHandler } from '../../../utilities/helpers';
import { AppContext } from '../../../utilities/StateProvider';
import CouponCode from './CouponCode';
import PaidProjectTotal from '../PaidProjectTotal';
import { useHistory } from 'react-router-dom';
import ResponsiveDialog from '../../../components/ResponsiveDialog';
import trackEvent from '../../../tracking';

interface ChangeProjectSubscriptionDialogPropsI extends DialogProps {
	closeDialog: () => void;
	project?: ProjectI;
}

export interface ChangeProjectSubscriptionDialogFormI {
	newPlanId: string;
}

const ChangeProjectSubscriptionDialog = (props: ChangeProjectSubscriptionDialogPropsI) => {
	const { closeDialog, project, ...rest } = props;

	const history = useHistory();

	const { handleSubmit, watch, control } = useForm<ChangeProjectSubscriptionDialogFormI>({
		defaultValues: {
			newPlanId: getUpgradedPricePlan(project?.PlanId || '')?.id || project?.PlanId,
		},
	});

	const [loading, setLoading] = React.useState(false);

	const { state } = React.useContext(AppContext);

	const stripe = useStripe();
	const elements = useElements();

	const stripeId = React.useMemo(() => {
		return state.appAuthState.currentUser?.attributes?.['custom:stripe_id'];
	}, [state.appAuthState.currentUser]);

	const { data: cardsData, isLoading: cardsLoading } = useCards(!!stripeId);
	const [createNewCard, setCreateNewCard] = React.useState(false);
	const [selectedCard, setSelectedCard] = React.useState('');
	const [couponCode, setCouponCode] = React.useState('');

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	const queryClient = useQueryClient();

	const mutation = useMutation(updateProjectSubscription, {
		onSuccess: async (data, variables) => {
			if (variables.oldPlanId !== 'free' && variables.newPlanId === 'free') {
				trackEvent('Subscription Canceled', { project: project?.Id });
			}
			queryClient.invalidateQueries(['project', project?.Id]);
			if (!data.pay_int?.next_action) {
				closeDialog();
			} else {
				if (data.pay_int.client_secret && data.pay_int.source) {
					await stripe?.confirmCardPayment(data.pay_int.client_secret, {
						payment_method: data.pay_int.source,
					});
					closeDialog();
				}
			}
		},
	});

	const handleChangeSubscription: SubmitHandler<ChangeProjectSubscriptionDialogFormI> = async (data: ChangeProjectSubscriptionDialogFormI) => {
		setLoading(true);

		try {
			const user = userDataHandler(state.appAuthState.currentUser);

			if (createNewCard && project) {
				// use new card
				if (stripe && elements) {
					const stripeCardElement = elements.getElement(CardElement);
					if (stripeCardElement) {
						// get token from Stripe
						const { token, error } = await stripe.createToken(stripeCardElement);

						if (error) {
							throw new Error(error.message);
						}

						const newPlanData: SubscriptionChangeRequestI = {
							newPlanId: data.newPlanId,
							oldPlanId: project?.PlanId,
							subscriptionId: project?.SubscriptionId || '',
							projectId: project?.Id,
							projectName: project?.Name,
							billingInfo: {
								email: user?.email,
								address: user?.address,
								city: user?.city,
								zipCode: user?.zipCode,
								firstName: user?.firstName,
								lastName: user?.lastName,
								country: user?.country.value,
								vatId: user?.vatId,
								companyName: user?.companyName,
								token: token?.id,
							},
							...(!!couponCode ? { couponId: couponCode } : undefined),
						};

						if (token) {
							await mutation.mutateAsync(newPlanData);
						}
					}
				}
			} else if (project) {
				// use selected card
				const newPlanData: SubscriptionChangeRequestI = {
					newPlanId: data.newPlanId,
					oldPlanId: project?.PlanId,
					subscriptionId: project?.SubscriptionId || '',
					projectId: project?.Id,
					projectName: project?.Name,
					billingInfo: {
						email: user?.email,
						address: user?.address,
						city: user?.city,
						zipCode: user?.zipCode,
						firstName: user?.firstName,
						lastName: user?.lastName,
						country: user?.country.value,
						vatId: user?.vatId,
						companyName: user?.companyName,
						cardId: selectedCard,
					},
					...(!!couponCode ? { couponId: couponCode } : undefined),
				};

				await mutation.mutateAsync(newPlanData);
			}
		} catch (error: any) {
			console.error(error);
			setServerErrorMessage(error?.response ? error?.response.data.message : error.message ? error?.message : 'Credit card error.');
		} finally {
			setLoading(false);
		}
	};

	const isFreePlan = watch().newPlanId === 'free';
	const newPlanSelected = watch().newPlanId !== project?.PlanId;

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Change subscription</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					{!!serverErrorMessage && <Alert severity='warning'>{serverErrorMessage}</Alert>}
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} component='form' onSubmit={handleSubmit(handleChangeSubscription)} direction='column'>
						<Box>
							<Controller
								control={control}
								name='newPlanId'
								render={({ field: { value, onChange } }) => (
									<PlanSelector selectedPlanId={value} setSelectedPlanId={onChange} currentPlanId={project?.PlanId} disabledPlanIds={['xxl-tier']} />
								)}
							/>
							{!isFreePlan && newPlanSelected && (
								<SelectOrCreateCard
									cards={cardsData?.data}
									loading={cardsLoading}
									createNewCard={createNewCard}
									setCreateNewCard={setCreateNewCard}
									selectedCard={selectedCard}
									setSelectedCard={setSelectedCard}
								/>
							)}

							{!isFreePlan && newPlanSelected && <CouponCode pricePlanId={watch().newPlanId} couponCode={couponCode} setCouponCode={setCouponCode} />}

							{!isFreePlan && newPlanSelected && <PaidProjectTotal pricePlanId={watch().newPlanId} couponCode={couponCode} />}
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' type='submit' disabled={!newPlanSelected}>
								{newPlanSelected ? 'Change plan' : 'Retry payment'}
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default ChangeProjectSubscriptionDialog;
