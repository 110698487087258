import { PricePlanI, pricePlans, privatePricePlans } from './../pricePlans';
import { GlobalStateI, CognitoUserExtended } from './store';
import { AccountDetailsFormI } from '../pages/Account/AccountForm';
import { countryOptions } from './countriesData';
import { AdminSingleUserI, ProjectI } from './queries';
import { Stripe } from '@stripe/stripe-js';
import { API } from 'aws-amplify';

var stage = process.env.REACT_APP_STAGE || 'dev';

declare global {
	interface Window {
		Intercom: any;
		APP_ID: string;
	}
}

// From official HTML specifications: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const htmlValidationExpression =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export function stringAvatar(email?: string, size?: number) {
	if (!email) {
		return {};
	}
	return {
		sx: {
			bgcolor: stringToColor(email),
			height: size ? size : undefined,
			width: size ? size : undefined,
			fontSize: size ? `${size / 40}rem` : undefined,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: '7%',
		},
		children: `${email.slice(0, 1).toUpperCase()}`,
	};
}

export const formatNumber = (number: number, short = false) => {
	if (short && number >= 1000) {
		return Math.floor(number / 1000) + 'k';
	} else {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
};

export function userDataHandler(user: GlobalStateI['appAuthState']['currentUser']) {
	let userData: AccountDetailsFormI = {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		address: '',
		zipCode: '',
		country: { key: '', text: '', value: '' },
		city: '',
		sendNotifications: true,
		companyName: '',
		companyWebsite: '',
		vatId: '',
		stripeId: '',
	};

	if (user) {
		userData.firstName = user.attributes?.given_name ? user.attributes.given_name : '';
		userData.lastName = user.attributes?.family_name ? user.attributes.family_name : '';
		userData.email = user.attributes?.email ? user.attributes.email : '';
		userData.address = user.attributes?.address ? user.attributes.address : '';
		userData.phone = user.attributes?.['custom:phone'] ? user.attributes['custom:phone'] : '';
		userData.zipCode = user.attributes?.['custom:zip_code'] ? user.attributes['custom:zip_code'] : '';
		userData.country = countryOptions.find((c) => c.value === user.attributes?.['custom:country']) || {
			key: '',
			text: '',
			value: '',
		};
		userData.city = user.attributes?.['custom:city'] ? user.attributes['custom:city'] : '';
		userData.sendNotifications = user.attributes?.['custom:send_notifications'] ? user.attributes['custom:send_notifications'] === 'true' : false;
		userData.companyName = user.attributes?.['custom:company_name'] ? user.attributes['custom:company_name'] : '';
		userData.companyWebsite = user.attributes?.['custom:company_website'] ? user.attributes['custom:company_website'] : '';
		userData.vatId = user.attributes?.['custom:vat_id'] || '';
		userData.stripeId = user.attributes?.['custom:stripe_id'] || '';
	}

	return userData;
}

export const lastMonthStrings = (count: number) => {
	const safeCount = count > 0 ? count : 1;
	const now = new Date();
	return [...Array(safeCount).keys()]
		.reverse()
		.map((monthDiff) => {
			return new Date(now.getFullYear(), now.getMonth() - monthDiff, 1);
		})
		.map((date) => {
			return `${date.getFullYear()}-${date.getMonth() + 1}`;
		});
};

export const getAdminPricePlans = (AccessToPrivatePlans: string[]) => {
	const adminPlans = [...pricePlans];
	const lastOne = adminPlans.pop();
	privatePricePlans.forEach((pp) => {
		if (AccessToPrivatePlans.includes(pp.name)) {
			adminPlans.push(pp);
		}
	});
	lastOne && adminPlans.push(lastOne);
	return adminPlans;
};

export const getAdminPricePlansForDropdown = (AccessToPrivatePlans: string[]) => {
	return getPricePlansForDropdowns(getAdminPricePlans(AccessToPrivatePlans), true);
};

const getMauLimitString = (mau: number) => {
	let mauString = '';
	if (mau < 1000) {
		mauString = `${mau}`;
	} else if (mau >= 1000000) {
		mauString = `${mau / 1000000}m`;
	} else {
		mauString = `${mau / 1000}k`;
	}
	return mauString;
};

export function getPricePlansForDropdowns(plans: PricePlanI[], withCustom?: boolean) {
	const data: { text: string; key: string; value: string; details: string }[] = [];
	plans.forEach((p) => {
		if (withCustom || p.id !== 'xxl-tier') {
			const isAnnual = p.id.includes('annual');
			const mau = getMauLimitString(p.mauLimit);
			let details = '';

			if (p.id !== 'xxl-tier') {
				details = `${p.price}/${isAnnual ? 'year' : 'month'}, up to ${mau} MAU`;
			} else {
				details = ``;
			}

			data.push({
				text: p.name,
				details,
				key: p.id,
				value: p.id,
			});
		}
	});
	return data;
}

export const getAccountName = (project?: ProjectI, user?: AdminSingleUserI) => {
	let accountName = '';
	if (project && user) {
		const given_name = user.UserAttributes.find?.((item) => item.Name === 'given_name')?.Value || '';
		const family_name = user.UserAttributes.find?.((item) => item.Name === 'family_name')?.Value || '';

		accountName = `${given_name} ${family_name}`;

		if (accountName === ' ') {
			accountName = user.UserAttributes.find((item) => item.Name === 'email')?.Value || '';
		}
	}
	return accountName;
};

export const handle3DIfNeeded = async ({
	clientSecret,
	source,
	stripe,
	setErrorMessage,
	onSuccess,
}: {
	clientSecret: string;
	source: string;
	stripe: Stripe | null;
	setErrorMessage: (message: string) => void;
	onSuccess: () => void;
}) => {
	if (clientSecret && source) {
		console.log('Got to here 1');
		try {
			console.log('Got to here 2. Stripe is:', stripe);
			const response = await stripe?.confirmCardPayment(clientSecret, {
				payment_method: source,
			});
			if (response?.error) {
				setErrorMessage(
					response?.error?.message || 'We are unable to authenticate your payment method. Please choose a different payment method and try again.'
				);
			} else {
				console.log('Got to here 3');
				onSuccess();
			}
		} catch (error: any) {
			console.error(error);
			setErrorMessage(error?.response ? error?.response.data.message : error.message ? error?.message : 'Credit card error.');
		}
	}
};

export const getUpgradedPricePlan = (currentPricePlanId: string) => {
	const sortedPricePlans = pricePlans.filter((p) => !p.id.includes('annual') && p.id !== 'xxl-tier').sort((a, b) => a.mauLimit - b.mauLimit);

	let currentPricePlanIdWithoutAnnual = currentPricePlanId;
	if (currentPricePlanId.includes('annual')) {
		currentPricePlanIdWithoutAnnual = currentPricePlanId.split('-').slice(0, -1).join('-');
	}
	const curPlan = sortedPricePlans.find((p) => p.id === currentPricePlanIdWithoutAnnual) || sortedPricePlans[0];
	const curPlanIndex = sortedPricePlans.indexOf(curPlan);
	if (curPlanIndex - 1 > sortedPricePlans.length) {
		return null;
	}
	return sortedPricePlans[curPlanIndex + 1];
};

export const getMonthString = (i: number) => {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	return months[i];
};

export const updateUserInIntercom = async (user: CognitoUserExtended, mode: 'update' | 'boot' = 'update') => {
	if (stage === 'prod') {
		const userHashData = await API.get('AppsApi', '/intercomUserHash', {});

		let name = '';
		if (user.attributes?.given_name && user.attributes.family_name) {
			name = user.attributes.given_name + ' ' + user.attributes.family_name;
		}

		window.Intercom(mode, {
			app_id: window?.APP_ID,
			email: user.attributes?.email,
			user_id: user.username,
			user_hash: userHashData.userHash,
			name: name,
			company: {
				company_id: user.username,
				name: user.attributes?.['custom:company_name'],
				website: user.attributes?.['custom:company_website'],
			},
			phone: user.attributes?.['custom:phone'],
		});
	}
};

export function formatBytes(bytes: number, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
