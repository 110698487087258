import React from 'react';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { Divider, Stack } from '@mui/material';
import { Auth } from 'aws-amplify';
import SSOButton from './SSOButton';

const handleContinueWithGoogle = () => {
	window.localStorage.setItem('lastFederatedSignInProvider', CognitoHostedUIIdentityProvider.Google);
	Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
};

const handleContinueWithGithub = () => {
	window.localStorage.setItem('lastFederatedSignInProvider', 'GitHubShim');
	Auth.federatedSignIn({ provider: 'GitHubShim' as CognitoHostedUIIdentityProvider });
};

const SSOLinks = () => {
	return (
		<Stack direction='column' spacing={2} sx={{ py: 2 }}>
			<SSOButton onClick={handleContinueWithGoogle}>
				<img src='/icons/google.svg' alt='Google Icon' style={{ width: '24px', height: '24px' }} />
				Continue with Google
			</SSOButton>
			<SSOButton onClick={handleContinueWithGithub}>
				<img src='/icons/github.svg' alt='GitHub Icon' style={{ width: '24px', height: '24px' }} />
				Continue with GitHub
			</SSOButton>
			<Divider>or</Divider>
		</Stack>
	);
};

export default SSOLinks;
