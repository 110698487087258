import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import React from 'react';

interface PaidProjectTotalI {
	pricePlanId: string;
	couponCode: string;
}

export interface ProjectPriceI {
	couponName?: string;
	name?: string;
	price: string;
	rawPrice: string;
	tax: string | number;
}

const PaidProjectTotal = (props: PaidProjectTotalI) => {
	const { pricePlanId, couponCode } = props;

	const [loading, setLoading] = React.useState(false);
	const [price, setPrice] = React.useState<ProjectPriceI>({
		price: '',
		rawPrice: '',
		tax: '',
	});

	React.useEffect(() => {
		const getPrice = async (pricePlanId: string) => {
			setLoading(true);
			try {
				var data = {
					body: { couponId: couponCode },
					headers: { 'Content-Type': 'application/json' },
				};
				const response: ProjectPriceI = await API.post('AppsApi', '/calculatePrice/' + pricePlanId, data);
				setPrice(response);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		getPrice(pricePlanId);
	}, [pricePlanId, couponCode]);

	const priceString = React.useMemo(() => {
		const subscriptionPeriod = pricePlanId.includes('annual') ? 'year' : 'month';
		if (!price.price) {
			return;
		} else if (price.tax === 0 || price.tax === '0.00') {
			return {
				price: `$${price.price} per ${subscriptionPeriod}`,
			};
		} else if (price.tax !== '0.00') {
			return {
				price: `$${price.price} per ${subscriptionPeriod}`,
				detail: `( plan $${price.rawPrice} + ${price.name} $${price.tax} )`,
			};
		}
	}, [price, pricePlanId]);

	return (
		<Stack direction='row' justifyContent='space-between' py={2}>
			<Typography variant='h5'>Total:</Typography>
			{loading ? (
				<CircularProgress size={20} />
			) : (
				priceString && (
					<Box>
						<Typography variant='h5'>{priceString?.price}</Typography>
						{priceString?.detail && (
							<Typography variant='body2' color='text.secondary'>
								{priceString?.detail}
							</Typography>
						)}
					</Box>
				)
			)}
		</Stack>
	);
};

export default PaidProjectTotal;
