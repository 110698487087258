import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ClickAwayListener, Typography, Box, Stack, Tooltip } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ProjectAppI } from '../../../../utilities/queries';

interface LicenceKeyPropsI {
	app: ProjectAppI;
}

const LicenceKey = ({ app }: LicenceKeyPropsI) => {
	const [copiedTooltipOpen, setCopiedTooltipOpen] = React.useState(false);

	const handleKeyCopy = () => {
		if (app) {
			setCopiedTooltipOpen(true);
		}
	};

	return (
		<ClickAwayListener onClickAway={() => setCopiedTooltipOpen(false)}>
			<Tooltip
				PopperProps={{
					disablePortal: true,
				}}
				onClose={() => setCopiedTooltipOpen(false)}
				open={copiedTooltipOpen}
				disableFocusListener
				disableHoverListener
				title={
					<Typography variant='body1'>
						<span role='img' aria-label='Sunglasses emoji'>
							😎
						</span>{' '}
						Copied to clipboard
					</Typography>
				}
			>
				<Box>
					<CopyToClipboard text={app?.Key || ''} onCopy={handleKeyCopy}>
						<Box
							padding={2}
							sx={{
								backgroundColor: (theme) => theme.palette.background.default,
								borderRadius: 2,
								cursor: 'pointer',
								maxWidth: '80vw',
								overflow: 'auto',
							}}
						>
							<Stack direction='row' alignItems='center' spacing={1}>
								<ContentCopyIcon color='disabled' fontSize='large' />
								<Box>
									<Typography variant='body2' color='text.secondary'>
										DeepAR App Key
									</Typography>
									<Typography variant='body1' sx={{ wordWrap: 'break-word', maxWidth: '100%' }}>
										{app?.Key}
									</Typography>
								</Box>
							</Stack>
						</Box>
					</CopyToClipboard>
				</Box>
			</Tooltip>
		</ClickAwayListener>
	);
};

export default LicenceKey;
