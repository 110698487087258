import React from 'react';

import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Client from './Client';
import { Link } from 'react-router-dom';

const Hero = () => {
	return (
		<Box
			sx={{
				backgroundImage: 'url(/assets/rainbow-gradient.svg)',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'auto 100%',
				backgroundPosition: 'left top',
			}}
		>
			<Container sx={{ paddingBottom: 5 }}>
				<Box
					sx={{
						paddingTop: 10,
						paddingBottom: 10,
					}}
				>
					<Grid container spacing={4} alignItems='center' wrap='wrap-reverse'>
						<Grid item xs={12} md={6} flexWrap='wrap'>
							<Typography variant='h1' marginBottom={4}>
								Powerful AR on any platform
							</Typography>
							<Typography variant='h5' marginBottom={4}>
								Build face filters, virtual try-on features, hair & background segmentation and emotion detection into any project with one SDK
							</Typography>
							<Stack direction='row' spacing={2}>
								<Button variant='contained' component={Link} to='/projects'>
									Get started
								</Button>
								<Button variant='outlined' target='__blank' href='https://deepar.ai/contact#demo-form'>
									Contact sales
								</Button>
							</Stack>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box
								component='video'
								autoPlay
								loop
								src='https://uploads-ssl.webflow.com/6139ee313144e527fc2c2fdb/61446080cb5b0b77cafddc5a_funny_filters_optimised-transcode.mp4'
								sx={{
									maxWidth: '100%',
									borderRadius: 4,
									objectFit: 'cover',
								}}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ textAlign: 'center' }}>
					<Typography variant='overline' color='text.secondary' gutterBottom>
						Trusted by:
					</Typography>
					<Stack direction='row' flexWrap='wrap'>
						<Client name='Coca Cola' logoUrl='https://uploads-ssl.webflow.com/613b7b21cfaac7287728a106/613b7bb642a53c1abf02e73c_coca-cola-logo.png' />
						<Client name='Google' logoUrl='https://uploads-ssl.webflow.com/613b7b21cfaac7287728a106/613b7bca89be9563097bcb90_google-logo.png' />
						<Client
							name='Sky'
							logoUrl='https://uploads-ssl.webflow.com/613b7b21cfaac7287728a106/6149b00b0b45e6793121c04e_2020_sky_logo-removebg-preview-p-500.png'
						/>
						<Client name='Ray Ban' logoUrl='https://uploads-ssl.webflow.com/613b7b21cfaac7287728a106/613b7bf2cf77f024fc35e9ab_rayban-logo-p-500.png' />
						<Client
							name='Warner Bros. Games'
							logoUrl='https://uploads-ssl.webflow.com/613b7b21cfaac7287728a106/613b7c34934830ac014f1520_warner-bros-logo-p-500.png'
						/>
					</Stack>
				</Box>
			</Container>
		</Box>
	);
};

export default Hero;
