import React from 'react';

import { Container, Box, Stack, Skeleton, Paper, Typography, IconButton, Menu, MenuItem, Link, Breadcrumbs } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useParams, Link as RouterLink } from 'react-router-dom';
import { pricePlans } from '../../pricePlans';
import { formatNumber, getAccountName } from '../../utilities/helpers';
import ProjectApp, { appTypes } from '../Projects/Project/ProjectApp/ProjectApp';
import TotalMauChart, { mauDataIntoYears } from '../Projects/Project/TotalMauChart';
import EditAdminProjectDialog from './EditAdminProjectDialog';
import { useProjectAndUserAsAdmin } from '../../utilities/queries';
import ChangeProjectOwnerDialog from './ChangeProjectOwnerDialog';

interface MatchParams {
	projectId?: string;
}

const AdminProject = () => {
	const { projectId } = useParams<MatchParams>();
	const { data, isLoading } = useProjectAndUserAsAdmin(projectId || '');
	const [moreMenuElement, setMoreMenuElement] = React.useState<null | HTMLElement>(null);
	const [editProjectDialogOpen, setEditProjectModalOpen] = React.useState(false);
	const [changeOwnerDialogOpen, setChangeOwnerDialogOpen] = React.useState(false);

	const currentProject = data?.project;

	const { years } = React.useMemo(() => {
		const mauSortedIntoYears = mauDataIntoYears(currentProject?.Mau || {});

		return {
			mauSortedIntoYears,
			years: Object.keys(mauSortedIntoYears),
		};
	}, [currentProject?.Mau]);

	const [activeYear, setActiveYear] = React.useState(years[years.length - 1] || new Date().getFullYear());

	const handelEditProject = () => {
		setEditProjectModalOpen(true);
		setMoreMenuElement(null);
	};

	const handelChangeOwner = () => {
		setChangeOwnerDialogOpen(true);
		setMoreMenuElement(null);
	};

	const accountName = React.useMemo(() => {
		return getAccountName(data?.project, data?.user);
	}, [data]);

	if (isLoading) {
		return (
			<Container component={Box} paddingY={4}>
				<Stack direction='row' spacing={4}>
					<Skeleton variant='rectangular' height={50} />
				</Stack>
				<Stack direction='column' spacing={2}>
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
				</Stack>
			</Container>
		);
	}

	return (
		<Container component={Box} pt={6} pb={12}>
			<Stack direction='row' justifyContent='space-between'>
				<Box>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link underline='hover' color='inherit' component={RouterLink} to='/admin'>
							Admin
						</Link>
						<Link underline='hover' color='inherit' component={RouterLink} to='/admin/projects'>
							Projects
						</Link>
						<Typography color='text.primary'>{currentProject?.Name}</Typography>
					</Breadcrumbs>
					<Box sx={{ mt: 2 }}>
						<Link component={RouterLink} to={`/admin/accounts/${data?.user.UserAtts.UserPoolUserId}`}>
							<Typography variant='h5'>{accountName}</Typography>
						</Link>
					</Box>
					<Box sx={{ mt: 2 }}>
						<Typography variant='body1' color='text.secondary'>
							Created: {new Date(data?.project?.CreatedAt || '').toLocaleDateString()}
						</Typography>
					</Box>
				</Box>
				<Paper sx={{ padding: 2 }}>
					<Stack direction='row' justifyContent='space-between'>
						<Stack direction='row' spacing={1} alignItems='center' px={1}>
							<Box px={2}>
								<Typography variant='h6'>{pricePlans.find((p) => p.id === currentProject?.PlanId)?.name}</Typography>
							</Box>
							<Box>
								<Typography variant='h5'>{formatNumber(currentProject?.MauLimit || 10)}</Typography>
								<Typography variant='body2' color='text.secondary'>
									Max users per month
								</Typography>
							</Box>
						</Stack>
						<Box minWidth={4} sx={{ textAlign: 'center' }}>
							<IconButton
								aria-label='menu'
								onClick={(e) => setMoreMenuElement(e.currentTarget)}
								sx={{
									color: (theme) => theme.palette.grey[500],
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id='menu-project'
								anchorEl={moreMenuElement}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={!!moreMenuElement}
								onClose={() => setMoreMenuElement(null)}
							>
								<MenuItem onClick={handelEditProject}>
									<Typography textAlign='center'>Edit project</Typography>
								</MenuItem>
								<MenuItem onClick={handelChangeOwner}>
									<Typography textAlign='center'>Change owner</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Stack>
				</Paper>
			</Stack>
			<Stack direction='column' spacing={2} mt={3}>
				<TotalMauChart mau={currentProject?.Mau || {}} activeYear={activeYear} setActiveYear={setActiveYear} />
				{appTypes.map((appType, i) => (
					<ProjectApp appType={appType} key={i} project={currentProject} readonly activeYear={activeYear} />
				))}
			</Stack>
			{editProjectDialogOpen && currentProject && (
				<EditAdminProjectDialog
					open
					closeDialog={() => setEditProjectModalOpen(false)}
					onClose={() => setEditProjectModalOpen(false)}
					project={currentProject}
					user={data.user}
				/>
			)}
			{changeOwnerDialogOpen && currentProject && (
				<ChangeProjectOwnerDialog
					open
					closeDialog={() => setChangeOwnerDialogOpen(false)}
					onClose={() => setChangeOwnerDialogOpen(false)}
					project={currentProject}
					user={data.user}
				/>
			)}
		</Container>
	);
};

export default AdminProject;
