import React from 'react';

import { Box, Button, Card, CardMedia, Chip, FormControlLabel, Grid, IconButton, Menu, MenuItem, Stack, Switch, Typography } from '@mui/material';
import { editResource, ResourceI, ResourceSetI } from '../../utilities/queries';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteResourceConfirmationDialog from './DeleteResourceConfirmationDialog';
import CreateEditResourceDialog from './CreateEditResourceDialog';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../utilities/StateProvider';
import UploadFileDialog, { UploadFileDialogPropsI } from './UploadFileDialog';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';

interface ResourceListItemPropsI {
	resource: ResourceI;
	resourceIndex: number;
	resourceSet: ResourceSetI;
}

const ResourceListItem = (props: ResourceListItemPropsI) => {
	const { resource, resourceIndex, resourceSet } = props;

	const [moreMenuElement, setMoreMenuElement] = React.useState<null | HTMLElement>(null);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
	const [editResourceDialog, setEditResourceDialog] = React.useState<ResourceI | null>(null);
	const [uploadFileDialog, setUploadFileDialog] = React.useState<UploadFileDialogPropsI['mode'] | null>(null);
	const [enabled, setEnabled] = React.useState(resource.enabled);

	const handleDeleteResourceClick = () => {
		setDeleteConfirmationOpen(true);
		setMoreMenuElement(null);
	};

	const handleEditResourceClick = () => {
		setEditResourceDialog(resource);
		setMoreMenuElement(null);
	};

	const queryClient = useQueryClient();
	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const editResourceMutation = useMutation(editResource, {
		onSuccess: async () => {
			queryClient.invalidateQueries('resourceSets');
			setGlobalAlertMessage({ message: 'Resource saved successfully' });
		},
		onError: () => {
			setGlobalAlertMessage({ message: 'Error saving resource', severity: 'error' });
		},
	});

	const handleToggleEnabled = (checked: boolean) => {
		setEnabled(checked);
		try {
			if (resource && typeof resourceIndex === 'number') {
				editResourceMutation.mutateAsync({
					resource: {
						...resource,
						enabled: checked,
					},
					resourceIndex,
					resourceList: resourceSet.ResourceSet.resources,
					resourceSet,
				});
			}
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<Grid item xs={12} sm={4} md={4}>
			<Card sx={{ borderRadius: 4, maxWidth: '300px' }}>
				<CardMedia
					component='div'
					sx={{
						backgroundImage: `url("${resource.meta?.thumbUrl}")`,
						backgroundColor: 'background.default',
						height: '280px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						position: 'relative',
					}}
				>
					<Box
						sx={{
							position: 'absolute',
							opacity: 0,
							left: 0,
							right: 0,
							top: 0,
							bottom: 0,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							'&:hover': { backgroundColor: 'rgba(0,0,0,0.5)', opacity: 1 },
						}}
					>
						<Button startIcon={<FileUploadIcon />} onClick={() => setUploadFileDialog('thumb')}>
							Upload
						</Button>
					</Box>
					{!resource.meta?.thumbUrl && <Box component='img' src='/studio-logo.png' sx={{ height: '50%' }} />}
				</CardMedia>

				<Box sx={{ pl: 1, pt: 2, pb: 1 }}>
					<Stack paddingBottom={2} direction='row' justifyContent='space-between'>
						<Stack direction='row' spacing={1}>
							<IconButton
								aria-label='upload-file'
								onClick={(e) => setUploadFileDialog('file')}
								sx={{
									color: (theme) => theme.palette.grey[500],
									width: '48px',
									height: '48px',
								}}
							>
								{!!resource.url ? <FilePresentIcon color='success' /> : <UploadFileIcon />}
							</IconButton>
							<Box>
								<Typography gutterBottom variant='body1' component='div'>
									{props.resource.name}
								</Typography>
								<Stack direction='row' spacing={1}>
									{resource.meta.isPremium && (
										<Typography variant='body2' color='text.secondary'>
											Premium
										</Typography>
									)}
									{resource.meta.isPremium && (
										<Typography variant='body2' color='text.secondary'>
											•
										</Typography>
									)}
									<Typography variant='body2' color='text.secondary'>
										{resource.meta.groupId}
									</Typography>
								</Stack>
							</Box>
						</Stack>

						<Chip color='info' label={`V${resource.version}`} variant='outlined' sx={{ mr: 2 }} />
					</Stack>
					<Stack direction='row' justifyContent='space-between' sx={{ px: 1, pl: 1.4 }}>
						<FormControlLabel control={<Switch checked={enabled} onChange={(e, checked) => handleToggleEnabled(checked)} />} label='Enabled' />
						<Box minWidth={4} sx={{ textAlign: 'center' }}>
							<IconButton
								aria-label='menu'
								onClick={(e) => setMoreMenuElement(e.currentTarget)}
								sx={{
									color: (theme) => theme.palette.grey[500],
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id='menu-resource'
								anchorEl={moreMenuElement}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={!!moreMenuElement}
								onClose={() => setMoreMenuElement(null)}
							>
								<MenuItem onClick={handleEditResourceClick}>
									<Stack direction='row' spacing={1}>
										<EditIcon />
										<Typography textAlign='center'>Edit</Typography>
									</Stack>
								</MenuItem>
								<MenuItem onClick={handleDeleteResourceClick}>
									<Stack direction='row' spacing={1}>
										<DeleteIcon color='error' />
										<Typography textAlign='center'>Delete</Typography>
									</Stack>
								</MenuItem>
							</Menu>
						</Box>
					</Stack>
				</Box>
			</Card>
			{deleteConfirmationOpen && (
				<DeleteResourceConfirmationDialog
					open
					onClose={() => setDeleteConfirmationOpen(false)}
					closeDialog={() => setDeleteConfirmationOpen(false)}
					_resource={resource}
					resourceIndex={resourceIndex}
					resourceSet={resourceSet}
				/>
			)}
			{editResourceDialog && (
				<CreateEditResourceDialog
					open
					onClose={() => setEditResourceDialog(null)}
					closeDialog={() => setEditResourceDialog(null)}
					resourceSet={resourceSet}
					_resource={editResourceDialog}
					resourceIndex={resourceIndex}
				/>
			)}
			{!!uploadFileDialog && (
				<UploadFileDialog
					open
					onClose={() => setUploadFileDialog(null)}
					closeDialog={() => setUploadFileDialog(null)}
					resourceSet={resourceSet}
					_resource={resource}
					resourceIndex={resourceIndex}
					mode={uploadFileDialog}
				/>
			)}
		</Grid>
	);
};

export default ResourceListItem;
