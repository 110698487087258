import React from 'react';
import { useParams, Link as BrowserLink, Redirect } from 'react-router-dom';

import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import LoginWrapper from '../LoginWrapper';
import CustomAlert, { CustomAlertPropsI } from '../../utilities/CustomAlert';
import { Auth } from 'aws-amplify';
import { AppContext } from '../../utilities/StateProvider';

const ConfirmSignup = () => {
	const { code } = useParams<{ code: string }>();
	const { state, dispatch } = React.useContext(AppContext);
	const [loading, setLoading] = React.useState(true);
	const [alert, setAlert] = React.useState<CustomAlertPropsI>();
	const [userEmail, setUserEmail] = React.useState('');

	React.useEffect(() => {
		let isMounted = true;
		const getCode = () => {
			const data = code.split('$Cd');
			try {
				const _userEmail = window.atob(data[0]);
				const _extractedCode = data[1];
				setUserEmail(_userEmail);

				const confirmAccount = async (email: string, code: string) => {
					setLoading(true);

					try {
						await Auth.confirmSignUp(email, code);
						// Ideally we would now sign the user in, but as of Mar 2022 Cognito still doesn't allow this: https://github.com/aws-amplify/amplify-js/issues/6320
						dispatch({
							type: 'CHANGE_AUTH_STATE',
							payload: {
								appAuthState: 'signedUp',
								currentUserAttributes: {
									Email: email,
									AgreedToTermOfUse: state.appAuthState.currentUserAttributes?.AgreedToTermOfUse || false,
								},
							},
						});
					} catch (error: any) {
						console.error(error.code);
						setAlert({ severity: 'warning', message: 'Invalid confirmation link. Please try again.' });
					} finally {
						setLoading(false);
					}
				};

				confirmAccount(_userEmail, _extractedCode);
			} catch (error: any) {
				setAlert({ severity: 'warning', message: 'Confirmation link is not valid. Please try again.' });
			}
		};

		if (isMounted) {
			getCode();
		}

		return () => {
			isMounted = false;
		};
		//eslint-disable-next-line
	}, []);

	const resendLink = async () => {
		setLoading(true);

		try {
			await Auth.resendSignUp(userEmail);
		} catch (error: any) {
			setAlert({ severity: 'warning', message: error.message });
		} finally {
			setLoading(false);
		}
	};

	if (state.appAuthState.appAuthState === 'signedUp') {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: { showVerifiedMessage: true },
				}}
			/>
		);
	}

	return (
		<LoginWrapper>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					padding: 4,
				}}
			>
				<Typography variant='h3' textAlign='center' gutterBottom>
					Confirming account
				</Typography>
				{alert && <CustomAlert {...alert} />}

				<Stack sx={{ my: 3 }} direction='column' spacing={1}>
					{!!alert && (
						<Button fullWidth variant='contained' onClick={resendLink}>
							Resend link
						</Button>
					)}
					<Button component={BrowserLink} to='/login' fullWidth>
						Back to login
					</Button>
				</Stack>
			</Box>
		</LoginWrapper>
	);
};

export default ConfirmSignup;
