import React from 'react';

import { Container, Box, Stack, Autocomplete, TextField, Skeleton, Paper, Typography, IconButton, Menu, MenuItem, Link, Alert } from '@mui/material';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useProjectAndApps, useProjects } from '../../../utilities/queries';
import ProjectApp, { appTypes, ProjectAppTypeI } from './ProjectApp/ProjectApp';
import CreateProjectAppDialog from './CreateProjectAppDialog';
import { formatNumber, getUpgradedPricePlan } from '../../../utilities/helpers';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { pricePlans } from '../../../pricePlans';
import DeleteProjectConfirmationDialog from './DeleteProjectConfirmationDialog';
import TotalMauChart, { mauDataIntoYears } from './TotalMauChart';
import ChangeProjectSubscriptionDialog from './ChangeProjectSubscriptionDialog';
import { Elements } from '@stripe/react-stripe-js';
import { AppContext } from '../../../utilities/StateProvider';
import IncompleteSubscription from './IncompleteSubscription';
import InvalidPayment from './InvalidPayment';
import ChangePaymentMethodDialog from './ChangePaymentMethodDialog';

interface MatchParams {
	projectId?: string;
}

const Project = () => {
	const { projectId } = useParams<MatchParams>();
	const { data: currentProject, isLoading, error } = useProjectAndApps(projectId || '');
	const { data: projects, isLoading: isLoadingProjects } = useProjects();
	const { push } = useHistory();
	const [createAppDialogType, setCreateAppDialogType] = React.useState<ProjectAppTypeI | null>(null);
	const [deleteProjectDialogOpen, setDeleteProjectDialogOpen] = React.useState(false);
	const [changeSubscriptionDialogOpen, setChangeSubscriptionDialogOpen] = React.useState(false);
	const [changePaymentMethodDialogOpen, setChangePaymentMethodDialogOpen] = React.useState(false);
	const [moreMenuElement, setMoreMenuElement] = React.useState<null | HTMLElement>(null);
	const { stripePromise } = React.useContext(AppContext);

	const nextPricePlan = React.useMemo(() => {
		return getUpgradedPricePlan(currentProject?.PlanId || '');
	}, [currentProject]);

	const handleOpenDeleteConfirmation = () => {
		setDeleteProjectDialogOpen(true);
		setMoreMenuElement(null);
	};

	const handleOpenChangeSubscriptionDialog = () => {
		setChangeSubscriptionDialogOpen(true);
		setMoreMenuElement(null);
	};

	const handleOpenChangePaymentMethodModal = () => {
		setChangePaymentMethodDialogOpen(true);
		setMoreMenuElement(null);
	};

	const subscriptionIncomplete = currentProject?.SubscriptionStatus === 'incomplete';
	const isPaidProject = currentProject?.PlanId !== 'free' && currentProject?.PlanId !== 'xxl-tier';
	const currentPricePlan = pricePlans.find((p) => p.id === currentProject?.PlanId);

	const { years } = React.useMemo(() => {
		const mauSortedIntoYears = mauDataIntoYears(currentProject?.Mau || {});

		return {
			mauSortedIntoYears,
			years: Object.keys(mauSortedIntoYears),
		};
	}, [currentProject?.Mau]);

	const [activeYear, setActiveYear] = React.useState(years[years.length - 1] || new Date().getFullYear());

	const invalidProjectMessage = React.useMemo(() => {
		if (currentProject?.InvalidMau && currentProject?.InvalidPayment) {
			return 'This project exceeded Monthly Active Users limit and has a payment error. Please check if your payment method has sufficient funds and click "Pay invoice" button.';
		} else if (currentProject?.InvalidMau && isPaidProject) {
			return 'This project exceeded Monthly Active Users limit. Please upgrade your subscription plan.';
		} else if (currentProject?.InvalidPayment) {
			return 'This project has payment error. Please check if your payment method has sufficient funds and click "Pay invoice" button.';
		}
	}, [currentProject, isPaidProject]);

	if (!isLoading && error) {
		return <Redirect to='/projects' />;
	}

	if (isLoading) {
		return (
			<Container component={Box} paddingY={4}>
				<Stack direction='row' spacing={4}>
					<Skeleton variant='rectangular' height={50} />
				</Stack>
				<Stack direction='column' spacing={2}>
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
					<Skeleton variant='rectangular' height={100} />
				</Stack>
			</Container>
		);
	}
	return (
		<Container component={Box} pt={6} pb={12}>
			<Elements stripe={stripePromise}>
				<Stack direction='row' justifyContent='space-between' flexWrap='wrap'>
					<Box sx={{ marginBottom: 2 }}>
						<Autocomplete
							value={currentProject}
							onChange={(e, value) => push(`/projects/${value.Id}`)}
							disablePortal
							options={projects || []}
							getOptionLabel={(option) => option.Name}
							isOptionEqualToValue={(option, value) => option.Id === value.Id}
							sx={{ width: 300 }}
							renderInput={(params) => <TextField {...params} label='Project' />}
							disableClearable
							loading={isLoadingProjects}
						/>
						<Box sx={{ mt: 2 }}>
							<Typography variant='body1' color='text.secondary'>
								Created: {new Date(currentProject?.CreatedAt || '').toLocaleDateString()}
							</Typography>
						</Box>
					</Box>
					<Paper sx={{ padding: 2 }}>
						<Stack direction='row' justifyContent='space-between' pb={2}>
							<Stack direction='row' spacing={1} alignItems='center' px={1}>
								<Box px={2}>
									<Typography variant='h6'>{currentPricePlan?.name}</Typography>
								</Box>
								<Box>
									<Typography variant='h5'>{formatNumber(currentProject?.MauLimit || 10)}</Typography>
									<Typography variant='body2' color='text.secondary'>
										Max users per month
									</Typography>
									{!!nextPricePlan && !subscriptionIncomplete && currentProject && nextPricePlan.mauLimit > currentProject.MauLimit && (
										<Link onClick={handleOpenChangeSubscriptionDialog} sx={{ cursor: 'pointer' }}>
											<Typography variant='body2'>Upgrade to {formatNumber(nextPricePlan.mauLimit)} users per month</Typography>
										</Link>
									)}
								</Box>
							</Stack>
							<Box minWidth={4} sx={{ textAlign: 'center' }}>
								<IconButton
									aria-label='menu'
									onClick={(e) => setMoreMenuElement(e.currentTarget)}
									sx={{
										color: (theme) => theme.palette.grey[500],
									}}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id='menu-project'
									anchorEl={moreMenuElement}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={!!moreMenuElement}
									onClose={() => setMoreMenuElement(null)}
								>
									<MenuItem onClick={handleOpenChangeSubscriptionDialog}>
										<Stack direction='row' spacing={1}>
											<AttachMoneyIcon color='disabled' />
											<Typography textAlign='center'>Change subscription</Typography>
										</Stack>
									</MenuItem>
									{isPaidProject && !subscriptionIncomplete && (
										<MenuItem onClick={handleOpenChangePaymentMethodModal}>
											<Stack direction='row' spacing={1}>
												<CreditCardIcon color='disabled' />
												<Typography textAlign='center'>Change payment method</Typography>
											</Stack>
										</MenuItem>
									)}
									<MenuItem onClick={handleOpenDeleteConfirmation}>
										<Stack direction='row' spacing={1}>
											<DeleteIcon color='error' />
											<Typography textAlign='center'>Delete Project</Typography>
										</Stack>
									</MenuItem>
								</Menu>
							</Box>
						</Stack>
						{subscriptionIncomplete && <IncompleteSubscription project={currentProject} />}
					</Paper>
				</Stack>
				<Stack direction='column' spacing={2} mt={3}>
					{invalidProjectMessage && <Alert severity='warning'>{invalidProjectMessage}</Alert>}
					{currentProject?.InvalidPayment && <InvalidPayment project={currentProject} />}
					<TotalMauChart mau={currentProject?.Mau || {}} maxMau={currentProject?.MauLimit} activeYear={activeYear} setActiveYear={setActiveYear} />
					{appTypes.map((appType, i) => (
						<ProjectApp
							appType={appType}
							key={i}
							setCreateAppDialogType={setCreateAppDialogType}
							project={currentProject}
							readonly={subscriptionIncomplete}
							activeYear={activeYear}
						/>
					))}
				</Stack>
				{!!createAppDialogType && (
					<CreateProjectAppDialog
						open
						appType={createAppDialogType}
						onClose={() => setCreateAppDialogType(null)}
						closeDialog={() => setCreateAppDialogType(null)}
						project={currentProject}
					/>
				)}
				{!!deleteProjectDialogOpen && currentProject && (
					<DeleteProjectConfirmationDialog
						open
						onClose={() => setDeleteProjectDialogOpen(false)}
						closeDialog={() => setDeleteProjectDialogOpen(false)}
						project={currentProject}
					/>
				)}
				{!!changeSubscriptionDialogOpen && (
					<ChangeProjectSubscriptionDialog
						open
						onClose={() => setChangeSubscriptionDialogOpen(false)}
						closeDialog={() => setChangeSubscriptionDialogOpen(false)}
						project={currentProject}
					/>
				)}
				{!!changePaymentMethodDialogOpen && currentProject && (
					<ChangePaymentMethodDialog
						open
						onClose={() => setChangePaymentMethodDialogOpen(false)}
						closeDialog={() => setChangePaymentMethodDialogOpen(false)}
						project={currentProject}
					/>
				)}
			</Elements>
		</Container>
	);
};

export default Project;
