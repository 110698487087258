export interface PricePlanI {
	id: string;
	name: string;
	description: string;
	shortName: string;
	mauLimit: number;
	scaledValue?: number;
	price: number;
	row1: string;
	row2: string;
	row3: string;
	row4: string;
	row5: string;
	row6: string;
	disabledInProd: boolean;
	private: boolean;
}

export const pricePlans: PricePlanI[] = [
	{
		id: 'free',
		name: 'Free',
		description: 'Free plan',
		shortName: 'Free',
		mauLimit: 10,
		scaledValue: 10,
		price: 0,
		row1: 'up to 10 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'DeepAR watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'basic',
		name: 'Basic',
		description: 'Without DeepAR watermark, limited to 1000 monthly active users',
		shortName: 'Basic',
		mauLimit: 1000,
		scaledValue: 20,
		price: 25,
		row1: 'up to 1,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'basic-annual',
		name: 'Basic annual',
		description: 'Without DeepAR watermark, limited to 1000 monthly active users',
		shortName: 'Basic',
		mauLimit: 1000,
		scaledValue: 20,
		price: 250,
		row1: 'up to 1,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'startup',
		name: 'Startup',
		description: 'Without DeepAR watermark, limited to 5000 monthly active users',
		shortName: 'Startup',
		mauLimit: 5000,
		scaledValue: 30,
		price: 100,
		row1: 'up to 5,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'startup-annual',
		name: 'Startup annual',
		description: 'Without DeepAR watermark, limited to 5000 monthly active users',
		shortName: 'Startup',
		mauLimit: 5000,
		scaledValue: 30,
		price: 1000,
		row1: 'up to 5,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'professional',
		name: 'Growth',
		description: 'Without DeepAR watermark, limited to 30k monthly active users',
		shortName: 'Growth',
		mauLimit: 30000,
		scaledValue: 40,
		price: 500,
		row1: 'up to 30,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'professional-annual',
		name: 'Growth annual',
		description: 'Without DeepAR watermark, limited to 30k monthly active users',
		shortName: 'Growth',
		mauLimit: 30000,
		scaledValue: 40,
		price: 5000,
		row1: 'up to 30,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'xl-tier',
		name: 'Pro',
		description: 'Without DeepAR watermark, limited to 100k monthly active users',
		shortName: 'Pro',
		mauLimit: 100000,
		scaledValue: 50,
		price: 1000,
		row1: 'up to 100,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'xl-tier-annual',
		name: 'Pro annual',
		description: 'Without DeepAR watermark, limited to 100k monthly active users',
		shortName: 'Pro',
		mauLimit: 100000,
		scaledValue: 50,
		price: 10000,
		row1: 'up to 100,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
	{
		id: 'xxl-tier',
		name: 'Custom',
		description: 'Without DeepAR watermark, limited to 100k monthly active users',
		shortName: 'Custom',
		mauLimit: 100000,
		scaledValue: 60,
		price: 0,
		row1: 'more than 100,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: false,
	},
];

export const privatePricePlans: PricePlanI[] = [
	{
		id: 'pro-200k',
		name: 'Pro 200k',
		description: 'Without DeepAR watermark, limited to 200k monthly active users',
		shortName: 'Pro 200k',
		mauLimit: 200000,
		price: 2000,
		row1: 'up to 200,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: true,
	},
	{
		id: 'pro-200k-annual',
		name: 'Pro 200k annual',
		description: 'Without DeepAR watermark, limited to 200k monthly active users',
		shortName: 'Pro 200k',
		mauLimit: 200000,
		price: 20000,
		row1: 'up to 200,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: true,
	},
	{
		id: 'pro-500k',
		name: 'Pro 500k',
		description: 'Without DeepAR watermark, limited to 500k monthly active users',
		shortName: 'Pro 500k',
		mauLimit: 500000,
		price: 4000,
		row1: 'up to 500,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: true,
	},
	{
		id: 'pro-500k-annual',
		name: 'Pro 500k annual',
		description: 'Without DeepAR watermark, limited to 500k monthly active users',
		shortName: 'Pro 500k',
		mauLimit: 500000,
		price: 40000,
		row1: 'up to 500,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: true,
	},
	{
		id: 'pro-1m',
		name: 'Pro 1m',
		description: 'Without DeepAR watermark, limited to 1m monthly active users',
		shortName: 'Pro 1m',
		mauLimit: 1000000,
		price: 8000,
		row1: 'up to 1,000,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: true,
	},
	{
		id: 'pro-1m-annual',
		name: 'Pro 1m annual',
		description: 'Without DeepAR watermark, limited to 1m monthly active users',
		shortName: 'Pro 1m',
		mauLimit: 1000000,
		price: 80000,
		row1: 'up to 1,000,000 MAU',
		row2: 'tracking up to 4 faces',
		row3: 'iOS, Android, HTML5 or macOS SDK',
		row4: 'access to DeepAR Studio',
		row5: 'HD image and video capture',
		row6: 'no watermark',
		disabledInProd: false,
		private: true,
	},
];
