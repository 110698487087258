import React from 'react';

import { Box, Button, Dialog, DialogContent, DialogProps, IconButton, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { editResourceSet, ResourceSetI } from '../../utilities/queries';
import { API } from 'aws-amplify';
import { AppContext } from '../../utilities/StateProvider';
import ResponsiveDialog from '../../components/ResponsiveDialog';

interface CreateEditResourceSetPropsI extends DialogProps {
	closeDialog: () => void;
	resourceSet?: ResourceSetI;
}

export interface CreateEditResourceSetFormI {
	name: string;
}

const CreateEditResourceSetDialog = (props: CreateEditResourceSetPropsI) => {
	const { closeDialog, resourceSet, ...rest } = props;

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CreateEditResourceSetFormI>({
		defaultValues: {
			name: resourceSet ? resourceSet.Name : '',
		},
	});

	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const queryClient = useQueryClient();

	const createNewResourceSet = async (resource: CreateEditResourceSetFormI) => {
		const newResourceSet: Pick<ResourceSetI, 'Name'> = {
			Name: resource.name,
		};
		var data = {
			body: newResourceSet,
			headers: { 'Content-Type': 'application/json' },
		};
		return await API.post('AppsApi', '/resourceSets', data);
	};

	const createResourceSetMutation = useMutation(createNewResourceSet, {
		onSuccess: async () => {
			queryClient.invalidateQueries('resourceSets');
			setGlobalAlertMessage({ message: 'New resource set created' });
			closeDialog();
		},
	});

	const editResourceSetMutation = useMutation(editResourceSet, {
		onSuccess: async () => {
			queryClient.invalidateQueries('resourceSets');
			setGlobalAlertMessage({ message: 'Resource set saved successfully' });
			closeDialog();
		},
	});

	const handleSaveResource: SubmitHandler<CreateEditResourceSetFormI> = async (data: CreateEditResourceSetFormI) => {
		try {
			if (resourceSet) {
				editResourceSetMutation.mutateAsync({
					resourceSet: {
						...resourceSet,
						Name: data.name,
					},
				});
			} else {
				createResourceSetMutation.mutateAsync(data);
			}
		} catch (error: any) {
			setGlobalAlertMessage({ message: error.message, severity: 'error' });
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{createResourceSetMutation.isLoading || (editResourceSetMutation.isLoading && <LinearProgress />)}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>{resourceSet ? 'Edit' : 'New'} Resource Set</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} component='form' onSubmit={handleSubmit(handleSaveResource)} direction='column'>
						<Box>
							<TextField
								{...register('name', { required: true })}
								margin='normal'
								required
								fullWidth
								id='name'
								label='Name'
								name='name'
								error={!!errors?.name}
								helperText={!!errors?.name && errors.name.message}
								autoFocus
							/>
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' type='submit'>
								Save
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default CreateEditResourceSetDialog;
