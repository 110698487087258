import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import DemoProjects from './Sections/DemoProjects/DemoProjects';
import EffectTemplates from './Sections/EffectTemplates/EffectTemplates';
import Hero from './Sections/Hero/Hero';

const Home = () => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				backgroundImage: theme.palette.mode === 'light' ? 'url(/assets/bg-grid.svg)' : 'url(/assets/bg-grid-dark.svg)',
				backgroundRepeat: 'repeat',
			}}
		>
			<Hero />
			<DemoProjects />
			<EffectTemplates />
		</Box>
	);
};

export default Home;
