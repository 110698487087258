import React from 'react';

import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogProps,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { AdminProjectI, AdminSingleUserI, updateAdminProject } from '../../utilities/queries';
import { getAdminPricePlansForDropdown } from '../../utilities/helpers';
import ResponsiveDialog from '../../components/ResponsiveDialog';

interface EditAdminProjectDialogPropsI extends DialogProps {
	closeDialog: () => void;
	project: AdminProjectI;
	user: AdminSingleUserI;
}

export interface EditAdminProjectFormI {
	PlanId: string;
	MauLimit: number;
	Valid: boolean;
	ValidResponse: number;
	InvalidResponse: number;
	MultiDomainAccess?: boolean;
	UserPoolUserId: string;
	projectId: string;
	// Not used in the UI
	Expires: number;
}

const EditAdminProjectDialog = (props: EditAdminProjectDialogPropsI) => {
	const { closeDialog, project, user, ...rest } = props;
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<EditAdminProjectFormI>({
		defaultValues: {
			PlanId: project.PlanId,
			MauLimit: project.MauLimit,
			Valid: project.Valid,
			ValidResponse: project.ValidResponse,
			InvalidResponse: project.InvalidResponse,
			MultiDomainAccess: project.MultiDomainAccess,
			UserPoolUserId: project.UserPoolUserId,
			projectId: project.Id,
			Expires: project.Expires,
		},
	});

	const validityRes = Array.from(new Array(9), (val, idx) => {
		return { text: `${idx}`, value: idx, key: `${idx}` };
	});

	const queryClient = useQueryClient();

	const mutation = useMutation(updateAdminProject, {
		onSuccess: async () => {
			queryClient.invalidateQueries(['adminProject', project?.Id]);
			closeDialog();
		},
	});

	const handleCreateProjectApp: SubmitHandler<EditAdminProjectFormI> = async (data: EditAdminProjectFormI) => {
		try {
			await mutation.mutateAsync(data);
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{mutation.isLoading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box>
					<Box sx={{ p: 1, textAlign: 'center' }}>
						<Typography variant='h5'>Edit project '{project.Name}'</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Alert severity='warning'>
						<Typography>Plan changes (from admin) will not create/delete stripe subscription.</Typography>
						<Typography>
							Change from paid plan to 'free' or 'xxl-tier' plan will remove SubscriptionId and SubscriptionStatus from project in db (but not from stripe!)
						</Typography>
					</Alert>
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} component='form' onSubmit={handleSubmit(handleCreateProjectApp)} direction='column'>
						<Box>
							<FormControl fullWidth margin='normal'>
								<InputLabel id='attributionOptionLabel'>Plan</InputLabel>
								<Controller
									rules={{ required: true }}
									control={control}
									name='PlanId'
									render={({ field }) => (
										<Select {...field} label='Plan'>
											{getAdminPricePlansForDropdown(user?.UserAtts?.AccessToPrivatePlans || []).map((plan, i) => (
												<MenuItem value={plan.value} key={plan.key}>
													<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
														<Typography>{plan.text}</Typography>
														<Typography color='text.secondary'>{plan.details}</Typography>
													</Stack>
												</MenuItem>
											))}
										</Select>
									)}
								/>
							</FormControl>
							<TextField
								{...register('MauLimit', { required: true })}
								margin='normal'
								required
								type='number'
								id='MauLimit'
								label='MAU Limit'
								name='MauLimit'
								error={!!errors?.MauLimit}
								helperText={!!errors?.MauLimit && errors.MauLimit.message}
								autoFocus
							/>
							<Box>
								<FormControlLabel
									control={
										<Controller
											name='Valid'
											control={control}
											render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
										/>
									}
									label='Project is valid'
								/>
							</Box>
							<Stack direction='row' spacing={1} alignContent='center'>
								<FormControl fullWidth>
									<InputLabel>Valid response</InputLabel>
									<Controller
										rules={{ required: true }}
										control={control}
										name='ValidResponse'
										render={({ field }) => (
											<Select {...field} label='Valid response'>
												{validityRes.map((res, i) => (
													<MenuItem value={res.value} key={res.key}>
														<Typography>{res.text}</Typography>
													</MenuItem>
												))}
											</Select>
										)}
									/>
								</FormControl>
								<FormControl fullWidth>
									<InputLabel>Invalid response</InputLabel>
									<Controller
										rules={{ required: true }}
										control={control}
										name='InvalidResponse'
										render={({ field }) => (
											<Select {...field} label='Invalid response'>
												{validityRes.map((res, i) => (
													<MenuItem value={res.value} key={res.key}>
														<Typography>{res.text}</Typography>
													</MenuItem>
												))}
											</Select>
										)}
									/>
								</FormControl>
							</Stack>
							<Box>
								<FormControlLabel
									control={
										<Controller
											name='MultiDomainAccess'
											control={control}
											render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
										/>
									}
									label='Project supports multi-domain Web apps'
								/>
							</Box>
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' type='submit'>
								Continue
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default EditAdminProjectDialog;
