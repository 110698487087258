import React from 'react';

import { Box, Button, Popover, Stack, Typography } from '@mui/material';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import { ArrowDropDown } from '@mui/icons-material';

import { startOfDay, isSameDay } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main style file
import './DateRange.css'; // theme css file

const calculatedDates = {
	getDaysAgo: (days: number) => startOfDay(new Date(new Date().setDate(new Date().getDate() - days))),
	startOfToday: startOfDay(new Date()),
	oneYearAgo: startOfDay(new Date(new Date().setFullYear(new Date().getFullYear() - 1))),
	// startOfWeek: startOfWeek(new Date()),
	// endOfWeek: endOfWeek(new Date()),
	// startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
	// endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
	// endOfToday: endOfDay(new Date()),
	// startOfYesterday: startOfDay(addDays(new Date(), -1)),
	// endOfYesterday: endOfDay(addDays(new Date(), -1)),
	// startOfMonth: startOfMonth(new Date()),
	// endOfMonth: endOfMonth(new Date()),
	// startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	// endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

export interface DateRangeI {
	startDate: Date;
	endDate: Date;
}

interface DateRangePropsI {
	range: DateRangeI;
	onChange: (range: DateRangeI) => void;
}

const DateRange = ({ range, onChange }: DateRangePropsI) => {
	const [dateRangeSelection, setDateRangeSelection] = React.useState<Range>({
		startDate: range.startDate,
		endDate: range.endDate,
		key: 'selection',
	});

	const handleSelect = (range: RangeKeyDict) => {
		setDateRangeSelection(range.selection);
	};

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setDateRangeSelection({ ...dateRangeSelection, startDate: range.startDate, endDate: range.endDate });
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const calculateIsSelected = (range: Range, startDate: Date, endDate: Date) => {
		return isSameDay(range?.startDate || new Date(), startDate) && isSameDay(range?.endDate || new Date(), endDate);
	};

	return (
		<Box>
			<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 2 }}>
				<Typography variant='h3'>Projects created per day</Typography>
				<Button aria-describedby={id} onClick={handleClick} endIcon={<ArrowDropDown />}>
					{range.startDate && range.endDate
						? `${range.startDate.toLocaleDateString('en-GB', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
						  })} - ${range.endDate.toLocaleDateString('en-GB', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
						  })}`
						: 'Select Date Range'}
				</Button>
			</Stack>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box
					sx={(theme) => ({
						'& button, & select, & input, & span': {
							fontFamily: 'Avenir',
							color: theme?.palette?.text?.primary,
							borderColor: theme?.palette?.divider,
						},
						'& input': {
							backgroundColor: theme?.palette?.background?.paper,
							outlineColor: theme?.palette?.divider,
						},
						'& .rdrCalendarWrapper': {
							color: theme?.palette?.text?.secondary,
							fontSize: '12px',
							backgroundColor: theme?.palette?.background?.paper,
						},
						'& .rdrDateDisplayWrapper, & .rdrStaticRange, & .rdrDefinedRangesWrapper': {
							backgroundColor: theme?.palette?.background?.paper,
							borderColor: theme?.palette?.divider,
						},
						'& .rdrStaticRange': {
							'& :hover': {
								backgroundColor: theme?.palette?.background?.default,
							},
						},
						'& .rdrDayToday .rdrDayNumber span:after': {
							background: theme?.palette?.primary?.main,
						},
						'& .rdrInRange, & .rdrStartEdge, & .rdrEndEdge, & .rdrSelected': {
							background: theme?.palette?.primary?.main,
						},
						'& .rdrStaticRangeSelected span': {
							color: theme?.palette?.primary?.main,
						},
					})}
				>
					<DateRangePicker
						ranges={[dateRangeSelection]}
						onChange={handleSelect}
						weekStartsOn={1}
						staticRanges={[
							{
								label: 'Last 7 days',
								range: () => ({
									endDate: calculatedDates.startOfToday,
									startDate: calculatedDates.getDaysAgo(6),
								}),
								isSelected: () => {
									const startDate = calculatedDates.getDaysAgo(6);
									const endDate = calculatedDates.startOfToday;
									return calculateIsSelected(dateRangeSelection, startDate, endDate);
								},
							},
							{
								label: 'Last 30 days',
								range: () => ({
									endDate: calculatedDates.startOfToday,
									startDate: calculatedDates.getDaysAgo(29),
								}),
								isSelected: () => {
									const startDate = calculatedDates.getDaysAgo(29);
									const endDate = calculatedDates.startOfToday;
									return calculateIsSelected(dateRangeSelection, startDate, endDate);
								},
							},
							{
								label: 'Last 60 days',
								range: () => ({
									endDate: calculatedDates.startOfToday,
									startDate: calculatedDates.getDaysAgo(60),
								}),
								isSelected: () => {
									const startDate = calculatedDates.getDaysAgo(60);
									const endDate = calculatedDates.startOfToday;
									return calculateIsSelected(dateRangeSelection, startDate, endDate);
								},
							},
							{
								label: 'Last 90 days',
								range: () => ({
									endDate: calculatedDates.startOfToday,
									startDate: calculatedDates.getDaysAgo(90),
								}),
								isSelected: () => {
									const startDate = calculatedDates.getDaysAgo(90);
									const endDate = calculatedDates.startOfToday;
									return calculateIsSelected(dateRangeSelection, startDate, endDate);
								},
							},
							{
								label: 'Last year',
								range: () => ({
									endDate: calculatedDates.startOfToday,
									startDate: calculatedDates.oneYearAgo,
								}),
								isSelected: () => {
									const startDate = calculatedDates.oneYearAgo;
									const endDate = calculatedDates.startOfToday;
									return calculateIsSelected(dateRangeSelection, startDate, endDate);
								},
							},
						]}
						inputRanges={[]}
					/>
				</Box>
				<Stack direction='row' padding={1} justifyContent='flex-end'>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						variant='contained'
						onClick={() => {
							onChange({ ...{ startDate: dateRangeSelection?.startDate || new Date(), endDate: dateRangeSelection?.endDate || new Date() } });
							setAnchorEl(null);
						}}
					>
						Apply
					</Button>
				</Stack>
			</Popover>
		</Box>
	);
};

export default DateRange;
