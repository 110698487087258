import React from 'react';

import { Box, Button, Chip, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { DownloadI, DownloadReleaseI, DownloadName } from '../../utilities/queries';
import trackEvent from '../../tracking';
import { formatBytes } from '../../utilities/helpers';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { sendCustomGAEvent } from '../../utilities/tracking';
import { ArrowDownward } from '@mui/icons-material';

export interface DownloadItemPropsI extends DownloadI {
	displayName?: string;
	description: string;
	imageUrl: string;
	customDownloadUrl?: string;
	docsName?: string;
	docsUrl?: string;
	legacy?: boolean;
}

export interface CombinedDownloadReleaseI extends DownloadReleaseI {
	stable: boolean;
}

const matchReleasePlatform = (releases: CombinedDownloadReleaseI[]) => {
	if (releases.length < 2) return releases[0];
	const platform = (window.navigator?.platform?.indexOf('Win') || navigator?.userAgent?.indexOf('Win')) !== -1 ? 'Windows' : 'MacOS';
	return releases.find((release) => release.platform === platform) || releases[0];
};

const twoMonthsInMs = 5259600000;

const DownloadItem = (props: DownloadItemPropsI) => {
	const { name, platform, latestStable, latestUnstable, displayName, description, imageUrl, customDownloadUrl, docsName, docsUrl, legacy } = props;

	const [expanded, setExpanded] = React.useState(!legacy);

	const combinedReleases = React.useMemo(() => {
		const releases: CombinedDownloadReleaseI[] = [];
		latestStable?.forEach((item) => releases.push({ ...item, stable: true }));
		// latestUnstable?.filter((item) => item.type === 'rc').forEach((item) => releases.push({ ...item, stable: false }));
		return releases;
	}, []);

	const [chosenRelease, setChosenRelease] = React.useState<CombinedDownloadReleaseI | null>(null);

	React.useEffect(() => {
		setChosenRelease(matchReleasePlatform(combinedReleases));
	}, []);

	const downloadHandler = (downloadLink: string) => {
		if (platform) {
			trackEvent('Package Downloaded', { platform: platform?.toLowerCase() });
			sendCustomGAEvent({ category: 'Package', action: 'Downloaded', label: platform?.toLowerCase() });
		} else if (name === DownloadName.studio) {
			trackEvent('Package Downloaded', { platform: 'studio-4.0' });
			sendCustomGAEvent({ category: 'Package', action: 'Downloaded', label: 'studio-4.0' });
		}
		const link = document.createElement('a');
		link.href = downloadLink || '';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Paper sx={{ p: 3, my: 2 }}>
			<Stack direction='row' alignItems='center'>
				{!legacy && (
					<Box
						component='img'
						sx={{
							height: 52,
							width: 52,
							ml: 1,
							mr: 4,
						}}
						alt={displayName ?? name}
						src={imageUrl}
					/>
				)}
				<Box sx={{ width: '100%' }}>
					<Stack direction='row' justifyContent='space-between' alignItems='center'>
						<Box>
							<Stack direction='row' spacing={1} alignItems='center'>
								<Typography variant='h6'>{displayName ?? name}</Typography>
							</Stack>
							<Typography variant='body1' color='text.secondary'>
								{description}
							</Typography>
							{expanded && (
								<Box>
									{docsName && docsUrl && (
										<Button target='_blank' component='a' href={docsUrl} startIcon={<InsertLinkIcon />} sx={{ px: 1 }}>
											{docsName}
										</Button>
									)}
								</Box>
							)}
						</Box>
						{!expanded && (
							<Box>
								<Button variant='text' color='info' onClick={() => setExpanded(true)}>
									<ArrowDownward />
								</Button>
							</Box>
						)}
					</Stack>
					{expanded && (
						<Stack direction='row' pt={2} alignItems='center' flexWrap='wrap' justifyContent='space-between' sx={{ width: '100%' }}>
							{combinedReleases.length > 1 && chosenRelease && (
								<Select
									value={chosenRelease?.downloadUrl}
									onChange={(e, v) => setChosenRelease(combinedReleases.find((r) => r.downloadUrl === e.target.value) || null)}
									id='downloadItems'
									sx={{ minWidth: '200px', my: 1, mr: 2 }}
									size='small'
								>
									{combinedReleases.map((release, i) => (
										<MenuItem key={release.downloadUrl} value={release.downloadUrl}>{`${release.platform} ${release.version} (${formatBytes(
											release.sizeInBytes
										)})`}</MenuItem>
									))}
								</Select>
							)}
							{combinedReleases.length <= 1 && chosenRelease && (
								<Box sx={{ py: 1, mr: 2 }}>
									<Typography variant='body1'>{`${chosenRelease.platform} ${chosenRelease.version} (${formatBytes(chosenRelease.sizeInBytes)})`}</Typography>
								</Box>
							)}
							{customDownloadUrl && (
								<Button variant='outlined' startIcon={<DownloadIcon />} onClick={() => downloadHandler(customDownloadUrl)}>
									Download
								</Button>
							)}
							{!customDownloadUrl && chosenRelease && (
								<Button variant='outlined' startIcon={<DownloadIcon />} onClick={() => downloadHandler(chosenRelease?.downloadUrl)}>
									Download
								</Button>
							)}
							{!customDownloadUrl && !chosenRelease && <Chip label='Coming soon' />}
						</Stack>
					)}
				</Box>
			</Stack>
		</Paper>
	);
};

export default DownloadItem;
