import { Components } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { PaletteMode } from '@mui/material';

export const typographyOptions: TypographyOptions = {
	htmlFontSize: 15,
	fontFamily: 'Avenir, Arial',
	h1: {
		fontSize: '4rem',
		fontStyle: 'heavy',
		fontWeight: '800',
		lineHeight: '100%',
	},
	h2: {
		fontSize: '2rem',
		fontStyle: 'heavy',
		fontWeight: '800',
		lineHeight: '150%',
	},
	h3: {
		fontSize: '1.4rem',
		fontStyle: 'heavy',
		fontWeight: '800',
		lineHeight: '120%',
	},
	h5: {
		lineHeight: '130%',
	},
	body1: {
		fontSize: '0.9rem',
	},
	body2: {
		fontSize: '0.8rem',
	},
	overline: {
		fontSize: '1rem',
		letterSpacing: 1.1,
		lineHeight: '120%',
	},
};

export const getComponentOptions = (mode: PaletteMode): Components => ({
	MuiCssBaseline: {
		styleOverrides: `
					@font-face {
							font-family: 'Avenir';
							font-style: medium;
							font-display: swap;
							font-weight: 400;
							src: url('/fonts/AvenirLTStd-Medium.woff') format('woff'),url('/fonts/AvenirLTStd-Medium.woff2') format('woff2');
							unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
					}
					@font-face {
							font-family: 'Avenir';
							font-style: heavy;
							font-display: swap;
							font-weight: 800;
							src: url('/fonts/AvenirLTStd-Heavy.woff') format('woff'), url('/fonts/AvenirLTStd-Heavy.woff2') format('woff2');
							unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
					}
					a {
						text-decoration: none;
					}
					${
						mode === 'dark'
							? `
						a {
							color: #FFF;
						}
						.react-tel-input .form-control+div:before {
							background: #191B1D;
							content: 'Mobile';
					    position: absolute;
					    top: -7px;
					    left: 10px;
					    display: block;
					    padding: 0 5px;
					    font-size: 13px;
					    white-space: nowrap;
						}
						.react-tel-input .country-list {
							background-color: #272A2C;
						}
						.react-tel-input .country-list .country.highlight {
							background-color: #272A2C;
						}
						.react-tel-input .country-list .country:hover {
							background-color: #383B3D;
						}
						.react-tel-input {
							font-family: 'Avenir', sans-serif;
						}
						.react-tel-input .form-control {
						background: #191B1D;
						color: #fff;
						font-family: 'Avenir', sans-serif;
					}`
							: ''
					}
					.react-tel-input .form-control+div:before {
						content: 'Mobile';
						font-family: inherit;
						left: 10px;
					}
				`,
	},
	MuiInputLabel:
		mode === 'dark'
			? {
					styleOverrides: {
						root: {
							'&.Mui-focused': {
								color: '#fff',
							},
						},
					},
			  }
			: {},
	MuiLink:
		mode === 'dark'
			? {
					styleOverrides: {
						root: {
							color: '#fff',
							textDecoration: 'underline',
							'&:hover': {
								color: '#fff',
							},
						},
					},
			  }
			: {},
	MuiButton:
		mode === 'dark'
			? {
					styleOverrides: {
						sizeSmall: {
							textTransform: 'none',
						},
						text: {
							color: '#fff',
							'&:hover': {
								color: 'rgba(255, 255, 255, 0.9)',
							},
						},
						outlined: {
							borderColor: 'white',
							color: 'white',
						},
					},
			  }
			: {
					styleOverrides: {
						sizeSmall: {
							textTransform: 'none',
						},
					},
			  },
	MuiListItemButton:
		mode === 'dark'
			? {
					styleOverrides: {
						root: {
							'&:hover': {
								color: 'rgba(255, 255, 255, 0.9)',
							},
						},
					},
			  }
			: {},
});

export const getPalette = (mode: PaletteMode) => ({
	mode,

	...(mode === 'light'
		? {
				primary: {
					main: '#1434f7',
				},
				background: {
					default: '#fafafa',
					warning: '#FFAAAA',
				},
		  }
		: {
				primary: {
					main: '#0062d1',
					dark: '#0062d1',
				},
				background: {
					default: '#191B1D',
					paper: '#212527',
					warning: '#972B45',
				},
		  }),
});
