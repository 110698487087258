import React from 'react';

import { Button, TextField, Link, Typography, Stack } from '@mui/material';
import { Link as BrowserLink, useLocation, useHistory } from 'react-router-dom';
import { Box } from '@mui/system';
import { API, Auth } from 'aws-amplify';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AppContext } from '../utilities/StateProvider';
import { CustomAlertPropsI } from '../utilities/CustomAlert';

import SSOLinks from './SSOLinks';
import { htmlValidationExpression } from '../utilities/helpers';

interface LoginFormI {
	username: string;
	password: string;
}

interface LoginFormPropsI {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setAlert: React.Dispatch<React.SetStateAction<CustomAlertPropsI | undefined>>;
}

const LoginForm = (props: LoginFormPropsI) => {
	const { setAlert, setLoading } = props;
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm<LoginFormI>();

	const location = useLocation<{ from?: string; showVerifiedMessage?: boolean }>();
	const history = useHistory();

	const [resendConfirmLink, setResendConfirmLink] = React.useState(false);

	const { state, dispatch } = React.useContext(AppContext);
	const userEmail = state.appAuthState.currentUserAttributes?.Email;

	React.useEffect(() => {
		if (location.state?.showVerifiedMessage)
			props.setAlert({
				severity: 'info',
				message: 'You have successfully verified your account. For security purposes, please sign in to access DeepAR.',
			});
		//eslint-disable-next-line
	}, []);

	const handleLogin: SubmitHandler<LoginFormI> = async (data: LoginFormI) => {
		if (!Auth || typeof Auth.signIn !== 'function') {
			throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
		}

		setLoading(true);

		const { username, password } = data;

		const formatString = (string: string, lowercase?: boolean) => {
			if (!string) return '';
			const trimmedString = string.trim();
			if (!!lowercase) {
				return trimmedString.toLowerCase();
			} else return trimmedString;
		};

		try {
			const user = await Auth.signIn(formatString(username, true), formatString(password));
			const isAdmin = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.some((group: string) => group === 'Admin');
			const isStudioDeveloper = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.some((group: string) => group === 'StudioDeveloper');
			const currentUserAttributes = await API.get('AppsApi', '/currentUserAtts', {});

			setLoading(false);
			if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
				console.log('confirm user with ' + user.challengeName);
				// Doesn't have any effect on the app currently
				dispatch({ type: 'CHANGE_AUTH_STATE', payload: { appAuthState: 'confirmSignIn' } });
			} else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				console.log('require new password', user.challengeParam);
				dispatch({ type: 'CHANGE_AUTH_STATE', payload: { appAuthState: 'requireNewPassword', currentUser: user } });
				props.setAlert(undefined);
			} else if (user.challengeName === 'MFA_SETUP') {
				console.log('TOTP setup', user.challengeParam);
				// Doesn't have any effect on the app currently
				dispatch({ type: 'CHANGE_AUTH_STATE', payload: { appAuthState: 'TOTPSetup' } });
			} else {
				dispatch({
					type: 'CHANGE_AUTH_STATE',
					payload: { appAuthState: 'signedIn', currentUser: user, currentUserAttributes, isAdmin, isStudioDeveloper },
				});
				if (!!window.sessionStorage.getItem('studioLogin')) {
					return;
				}
				history.push(location?.state?.from || '/projects');
			}
		} catch (error: any) {
			setLoading(false);
			console.error(error);

			if (error.code === 'UserNotConfirmedException') {
				setAlert({
					severity: 'warning',
					message: 'Please confirm email before logging in. Confirmation link has been sent to your email address.',
				});
				setResendConfirmLink(true);
				console.log('User is not confirmed. Confirmation link has been sent to your email address.');
			} else if (error.code === 'PasswordResetRequiredException') {
				console.log('User requires new password');
			} else if (error.code === 'InvalidParameterException' || error.code === 'UserNotFoundException' || error.code === 'NotAuthorizedException') {
				setAlert({
					severity: 'error',
					message: 'That email and password combination is incorrect.',
				});
			} else {
				setAlert({
					severity: 'error',
					message: error.message,
				});
			}
		}
	};

	const resendConfirmationLink = async () => {
		setLoading(true);

		try {
			await Auth.resendSignUp(getValues('username'));
			setLoading(false);
			setAlert({
				severity: 'success',
				message: 'Confirmation email sent. Please check your inbox',
			});
		} catch (error: any) {
			setLoading(false);
			setAlert({
				severity: 'error',
				message: error.message,
			});
		}
	};

	return (
		<Box sx={{ mt: 1, textAlign: 'center' }} component='form' onSubmit={handleSubmit(handleLogin)} noValidate>
			<Typography variant='h3'>Log in</Typography>
			<SSOLinks />
			<TextField
				{...register('username', {
					required: {
						value: true,
						message: 'Please enter email address',
					},
					pattern: {
						value: htmlValidationExpression,
						message: 'Please enter a valid email address',
					},
				})}
				margin='normal'
				required
				fullWidth
				id='username'
				label='Email Address'
				name='username'
				error={!!errors?.username}
				helperText={!!errors?.username && errors.username.message}
				autoComplete='email'
				autoFocus
				defaultValue={userEmail}
			/>
			<TextField
				{...register('password', {
					required: {
						value: true,
						message: 'Please fill out your password',
					},
				})}
				margin='normal'
				required
				fullWidth
				name='password'
				error={!!errors?.password}
				helperText={!!errors?.password && errors.password.message}
				label='Password'
				type='password'
				id='password'
				autoComplete='current-password'
			/>
			<Box sx={{ mt: 3, mb: 2 }}>
				{resendConfirmLink && (
					<Button fullWidth variant='text' onClick={() => resendConfirmationLink()}>
						Resend confirmation link
					</Button>
				)}
				<Button type='submit' fullWidth variant='contained'>
					Log In
				</Button>
			</Box>
			<Stack direction='column' justifyContent='center' spacing={1}>
				<Link component={BrowserLink} to='/password/forgot' variant='body1'>
					Forgot password?
				</Link>
				<Stack direction='row' spacing={1} justifyContent='center'>
					<Typography>Don't have an account?</Typography>
					<Link component={BrowserLink} to='/signup' variant='body1'>
						{'Sign Up'}
					</Link>
				</Stack>
			</Stack>
		</Box>
	);
};

export default LoginForm;
