import React from 'react';

import { Box, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';

import TopBar from '../../navigation/TopBar';
import Sidebar from '../../navigation/Sidebar';
import InvalidProjectsBanner from '../Projects/InvalidProjectsBanner';

const Main = styled('main')({
	flexGrow: 1,
	position: 'relative',
});

const Template: React.FC = (props) => {
	return (
		<Box>
			<CssBaseline />
			<TopBar />
			<Box sx={{ display: 'flex' }}>
				<Sidebar width={240} />
				<Main>
					<Box sx={{ height: '50px' }} />
					<InvalidProjectsBanner />
					<>{props.children}</>
				</Main>
			</Box>
		</Box>
	);
};

export default Template;
