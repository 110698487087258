import React from 'react';

import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const CustomerAgreement = () => {
	return (
		<Container component={Box} py={6}>
			<Typography variant='h1' gutterBottom>
				DeepAR Customer Agreement
			</Typography>
			<Typography variant='h5'>Last Updated: 12 April 2022</Typography>
			<br />
			<p>
				This DeepAR Customer Agreement (this “Agreement”) contains the terms and conditions that govern your access to
				and use of the Service Offerings (as defined below) and is an agreement between the applicable DeepAR
				Contracting Party specified in Section 14 below (also referred to as “<b>DeepAR</b>,” “we,” “us,” or “our”) and
				you or the entity you represent (“you” or “your”). This Agreement takes effect when you click an “I Accept”
				button or check box presented with these terms or, if earlier, when you use any of the Service Offerings (the
				“Effective Date”). You represent to us that you are lawfully able to enter into contracts (e.g., you are not a
				minor). If you are entering into this Agreement for an entity, such as the company you work for, you represent
				to us that you have legal authority to bind that entity. Please see Section 14 for definitions of certain
				capitalized terms used in this Agreement.
			</p>
			<br />

			<p>1. Use of the Service Offerings.</p>
			<p>
				1.1 Generally. You may access and use the Service Offerings in accordance with this Agreement. Service Level
				Agreements and Service Terms apply to certain Service Offerings. You will comply with the terms of this
				Agreement and all DeepAR, rules and regulations applicable to your use of the Service Offerings.
			</p>
			<p>
				1.2 Your Account. To access the Services, you must have an DeepAR account associated with a valid email address
				and a valid form of payment. Unless explicitly permitted by the Service Terms, you will only create one account
				per email address.
			</p>
			<p>
				1.3 Third-Party Content. Third-Party Content may be used by you at your election. Third-Party Content is
				governed by this Agreement and, if applicable, separate termsand conditions accompanying such Third-Party
				Content, which terms and conditions may include separate fees and charges.
			</p>
			<br />

			<p>2. Changes.</p>
			<p>
				2.1 To the Services. We may change or discontinue any of the Services from time to time. We will provide you at
				least 3 months’ prior notice if we discontinue material functionality of a Service that you are using, or
				materially alter a customer-facing API that you are using in a backwards-incompatible fashion, except that this
				notice will not be required if the 12 month notice period (a) would pose a security or intellectualproperty
				issue to us or the Services, (b) is economically or technically burdensome, or (c) would cause us to violate
				legal requirements.
			</p>
			<p>
				2.2 To the Service Level Agreements. We may change, discontinue or add Service Level Agreements from time to
				time in accordance with Section 12.
			</p>
			<br />

			<p>3. Security and Data Privacy.</p>
			<p>
				3.1 DeepAR Security. Without limiting Section 10 or your obligations under Section 4.2, we will implement
				reasonable and appropriate measures designed to help you secure Your Content against accidental or unlawful
				loss, access or disclosure.
			</p>
			<p>
				3.2 Data Privacy. You may specify the DeepAR regions in which Your Content will be stored. You consent to the
				storage of Your Content in, and transfer of Your Content into, the DeepAR regions you select. We will not access
				or use Your Content except as necessary to maintain or provide the Service Offerings, or as necessary to comply
				with the law or a binding order of a governmental body. We will not (a) disclose Your Content to any government
				or third party or (b) subject to Section 3.3, move Your Content from the DeepAR regions selected by you; except
				in each case as necessary to comply with the law or a binding order of a governmental body. Unless it would
				violate the law or a binding order of a governmental body, we will give you notice of any legal requirement or
				order referred to in this Section 3.2. We will only use your Account Information in accordance with the Privacy
				Policy, and you consent to such usage. The Privacy Policy does not apply to Your Content.
			</p>
			<p>
				3.3 Service Attributes. To provide billing and administration services, we may processService Attributes in the
				DeepAR region(s) where you use the Service Offerings and the DeepAR regions in the United Kingdom. To provide
				you with support services initiated by you and investigate fraud, abuse or violations of this Agreement, we
				mayprocess Service Attributes where we maintain our support and investigation personnel.
			</p>
			<br />

			<p>4. Your Responsibilities.</p>
			<p>
				4.1 Your Accounts. Except to the extent caused by our breach of this Agreement, (a) you are responsible for all
				activities that occur under your account, egardless of whether the activities are authorized by you or
				undertaken by you, your employees or a third party (including your contractors, agents or End Users), and (b) we
				and ouraffiliates are not responsible for unauthorized access to your account.
			</p>
			<p>
				4.2 Your Content. You will ensure that Your Content and your and End Users’ use of Your Content or the Service
				Offerings will not violate any of the Policies or any applicable law. You are solely responsible for the
				development, content, operation, maintenance, and use of Your Content.
			</p>
			<p>
				4.3 Your Security and Backup. You are responsible for properly configuring and using the Service Offerings and
				otherwise taking appropriate action to secure, protect and backup your accounts and Your Content in a manner
				that will provide appropriate security and protection, which might include use of encryption to protect Your
				Content from unauthorized access and routinely archiving Your Content.
			</p>
			<p>
				4.4 Log-In Credentials and Account Keys. DeepAR log-in credentials and private keys generated by the Services
				are for your internal use only and you will not sell, transferor sublicense them to any other entity or person,
				except that you may disclose your private key to your agents and subcontractors performing work on your behalf.
			</p>
			<p>
				4.5 End Users. You will be deemed to have taken any action that you permit, assist orfacilitate any person or
				entity to take related to this Agreement, Your Content or use of the Service Offerings. You are responsible for
				End Users’ use of Your Content and the Service Offerings. You will ensure that all End Users comply with your
				obligations under this Agreement and that the terms of your agreement with each End User are consistent with
				this Agreement. If you become aware of any violation of your obligations under this Agreement caused by an End
				User, you will immediately suspend access to Your Content and the Service Offerings by such End User. We do not
				provide any support or services to End Users unless we have a separate agreement with you or an End User
				obligating us to provide such support or services.
			</p>
			<br />

			<p>5. Fees and Payment.</p>
			<p>
				5.1 Service Fees. We calculate and bill fees and charges annually or monthly. We may bill you more frequently
				for fees accrued if we suspect that your account is fraudulent or at risk of non-payment. You will pay us the
				applicable fees and charges for use of the Service Offerings as described on the DeepAR Site using one of the
				payment methods we support. All amounts payable by you under this Agreement willbe paid to us without setoff or
				counterclaim, and without any deduction or withholding. Fees and charges for any new Service or new feature of a
				Service will be effective when we post updated fees and charges on the DeepAR Site, unless we expressly state
				otherwise in a notice. We may increase or add new fees and charges for any existing Services you are using by
				giving you at least 30 days’ prior notice. We may elect to charge you interest at the rate of 1.5% per month (or
				the highest rate permitted by law, if less) on all late payments.
			</p>
			<p>
				5.2 Taxes. Each party will be responsible, as required under applicable law, for identifying and paying all
				taxes and other governmental fees and charges (and any penalties, interest, and other additions thereto) that
				are imposed on that party upon or with respect to the transactions and payments under this Agreement. All fees
				payable by you are exclusive of Indirect Taxes. We may charge and you will pay applicable Indirect Taxes that we
				are legally obligated or authorized to collect from you. You will provide such information to us as reasonably
				required to determine whether we are obligated to collect Indirect Taxes from you. We will not collect, and you
				will not pay, any Indirect Tax for which you furnish us a properly completed exemption certificate or a direct
				payment permit certificate for which we may claim an available exemption from such Indirect Tax. All payments
				made by you to us under this Agreement will be made free and clear of any deduction or withholding, asmay be
				required by law. If any such deduction or withholding (including but not limited to cross-border withholding
				taxes) is required on any payment, you will pay such additional amounts as are necessary so that the net amount
				received by us is equal to the amount then due and payable under this Agreement. We will provide you with such
				tax forms as are reasonably requested in order to reduce or eliminate the amount of any withholding or deduction
				for taxes in respect of payments made under this Agreement.
			</p>
			<br />

			<p>6. Temporary Suspension.</p>
			<p>
				6.1 Generally. We may suspend your or any End User’s right to access or use any portion or all of the Service
				Offerings immediately upon notice to you if we determine:
			</p>
			<ol type='a'>
				<li>
					your or an End User’s use of the Service Offerings (i) poses a security risk tothe Service Offerings or any
					third party, (ii) could adversely impact our systems, the Service Offerings or the systems or Content of any
					other DeepAR customer, (iii) could subject us, our affiliates, or any third party to liability, or (iv) could
					be fraudulent;
				</li>
				<li>you are, or any End User is, in breach of this Agreement;</li>
				<li>you are in breach of your payment obligations under Section 5; or</li>
				<li>
					you have ceased to operate in the ordinary course, made an assignment forthe benefit of creditors or similar
					disposition of your assets, or become the subject of any bankruptcy, reorganization, liquidation, dissolution
					or similar proceeding.
				</li>
			</ol>
			<p>
				6.2 Effect of Suspension. If we suspend your right to access or use any portion or all of the Service Offerings:
			</p>
			<ol type='a'>
				<li>you remain responsible for all fees and charges you incur during the period of suspension; and</li>
				<li>
					you will not be entitled to any service credits under the Service Level Agreements for any period of
					suspension.
				</li>
			</ol>
			<br />

			<p>7. Term; Termination.</p>
			<p>
				7.1 Term. The term of this Agreement will commence on the Effective Date and will remain in effect until
				terminated under this Section 7. Any notice of termination of this Agreement by either party to the other must
				include a Termination Date that complies with the notice periods in Section 7.2.
			</p>
			<p>7.2 Termination.</p>
			<ol type='a'>
				<li>
					Termination for Convenience. You may terminate this Agreement for any reason by providing us notice and
					closing your account for all Services for which we provide an account closing mechanism. We may terminate this
					Agreement for any reason by providing you at least 30 days’ advance notice.
					<ol type='i'>
						<li>
							In case of termination by Licensee under this Section 7.2, no refund will be made to the Licensee, for the
							un-used portion of the contract.
						</li>
					</ol>
				</li>
				<li>
					Termination for Cause.
					<ol type='i'>
						<li>
							By Either Party. Either party may terminate this Agreement for cause if the other party is in material
							breach of this Agreement and the material breach remains uncured for a period of 30 days from receipt of
							notice by the other party. No later than the Termination Date, you will close your account.
						</li>
						<li>
							By Us. We may also terminate this Agreement immediately upon notice to you (A) for cause if we have the
							right to suspend under Section 6, (B) if our relationship with a third-party partner who provides software
							or other technology we use to provide the Service Offerings expires, terminates or requires us to change
							the way we provide the software or other technology as part of the Services, or (C) in order to comply
							with the law or requests of governmental entities.
						</li>
					</ol>
				</li>
			</ol>
			<p>7.3 Effect of Termination.</p>
			<ol type='a'>
				<li>
					(a) Generally. Upon the Termination Date:
					<ol type='i'>
						<li>except as provided in Section 7.3(b), all your rights under this Agreement immediately terminate;</li>
						<li>
							you remain responsible for all fees and charges you have incurred through the Termination Date and are
							responsible for any fees and charges you incurred during the post-termination period described in
							Section7.3(b);
						</li>
						<li>
							you will immediately return or, if instructed by us, destroy all DeepAR Content in your possession; and
						</li>
						<li>
							Sections 4.1, 5, 7.3, 8 (except the license granted to you in Section 8.3), 9, 10, 11, 13 and 14 will
							continue to apply in accordance with their terms.
						</li>
					</ol>
				</li>
				<li>
					(b) Post-Termination. Unless we terminate your use of the Service Offerings pursuant to Section 7.2(b), during
					the 30 days following the Termination Date:
					<ol type='i'>
						<li>
							we will not take action to remove from the DeepAR systems any of Your Content as a result of the
							termination; and
						</li>
						<li>
							we will allow you to retrieve Your Content from the Services only if youhave paid all amounts due under
							this Agreement.
						</li>
					</ol>
				</li>
			</ol>
			<p>
				For any use of the Services after the Termination Date, the terms of this Agreement will apply and you will pay
				the applicable fees at the rates under Section 5.
			</p>
			<br />

			<p>8. Proprietary Rights.</p>
			<p>
				8.1 Your Content. Except as provided in this Section 8, we obtain no rights under this Agreement from you (or
				your licensors) to Your Content. You consent to our use of Your Content to provide the Service Offerings to you
				and any End Users.
			</p>
			<p>
				8.2 Adequate Rights. You represent and warrant to us that: (a) you or your licensors own all right, title, and
				interest in and to Your Content and Suggestions; (b) you have all rights in Your Content and Suggestions
				necessary to grant the rights contemplated by this Agreement; and (c) none of Your Content or End Users’ use of
				Your Content or the Service Offerings will violate the Acceptable Use Policy.
			</p>
			<p>
				8.3 Service Offerings License. We or our licensors own all right, title, and interest in and to the Service
				Offerings, and all related technology and intellectual property rights. Subject to the terms of this Agreement,
				we grant you a limited, revocable, non-exclusive, non-sublicensable, non-transferrable license to do the
				following: (a) access and use the Services solely in accordance with this Agreement; and (b) copy and use the
				DeepAR Content solely in connection with your permitted use of the Services. Except as provided in this Section
				8.3, you obtain no rights under this Agreement from us, our affiliates or our licensors to the Service
				Offerings, including any related intellectual property rights. Some DeepAR Content and Third-Party Content may
				be provided to you under a separate license, such as the Apache License, Version 2.0, or other open source
				license. In the event of a conflict between this Agreement and any separate license, the separate license will
				prevail with respect to the DeepAR Content or Third-Party Content that is the subject of such separate license.
			</p>
			<p>
				8.4 License Restrictions. Neither you nor any End User will use the Service Offerings in any manner or for any
				purpose other than as expressly permitted by this Agreement. Neither you nor any End User will, or will attempt
				to (a) modify, distribute, alter, tamper with, repair, or otherwise create derivative works of any Content
				included in the Service Offerings (except to the extent Content included in the Service Offerings is provided to
				you under a separate license that expressly permits the creation of derivative works), (b) reverse engineer,
				disassemble, or decompile the Service Offerings or apply any other process or procedure to derive the source
				code of any software included in the Service Offerings (except to the extent applicable law doesn’t allow this
				restriction), (c) access or use the Service Offerings in a way intended to avoid incurring fees or exceeding
				usage limits or quotas, or (d) resell or sublicense the Service Offerings. You may only use the DeepAR Marks in
				accordance with the Trademark Use Guidelines. You will not misrepresent or embellish the relationship between us
				and you (including by expressing or implying that we support, sponsor, endorse, or contribute to you or your
				business endeavours). You will not imply any relationship or affiliation between us and you except as expressly
				permitted by this Agreement.
			</p>
			<p>
				8.5 Suggestions. If you provide any Suggestions to us or our affiliates, we and our affiliates will be entitled
				to use the Suggestions without restriction. You hereby irrevocably assign to us all right, title, and interest
				in and to the Suggestions and agree to provide us any assistance we require to document, perfect, and maintain
				our rights in the Suggestions.
			</p>
			<br />

			<p>9. Indemnification.</p>
			<p>
				9.1 General. You will defend, indemnify, and hold harmless us, our affiliates and licensors, and each of their
				respective employees, officers, directors, and representatives from and against any Losses arising out of or
				relating to any third-party claim concerning: (a) your or any End Users’ use of the Service Offerings (including
				any activities under your DeepAR account and use by your employees andpersonnel); (b) breach of this Agreement
				or violation of applicable law by you, End Users or Your Content; or (c) a dispute between you and any End User.
				You will reimburse us for reasonable attorneys’ fees, as well as our employees’ and contractors’ time and
				materials spent responding to any third party subpoena or other compulsory legal order or process associated
				with third party claims described in (a) through (c) above at our then-current hourly rates.
			</p>
			<p>9.2 Intellectual Property.</p>
			<ol type='a'>
				<li>
					Subject to the limitations in this Section 9, DeepAR will defend you and youremployees, officers, and
					directors against any third-party claim alleging that the Services infringe or misappropriate that third
					party’s intellectual property rights, and will pay the amount of any adverse final judgment or settlement.
				</li>
				<li>
					Subject to the limitations in this Section 9, you will defend DeepAR, its affiliates, and their respective
					employees, officers, and directors against any third-party claim alleging that any of Your Content infringes
					or misappropriates that third party’s intellectual property rights, and will pay the amount of any adverse
					final judgment or settlement.
				</li>
				<li>
					Neither party will have obligations or liability under this Section 9.2 arising from infringement by
					combinations of the Services or Your Content, as applicable, with any other product, service, software, data,
					content or method. In addition, DeepAR will have no obligations or liability arising from your or any End
					User’s use of the Services after DeepAR has notified you to discontinue such use. The remedies provided in
					this Section 9.2 are the sole and exclusive remedies for any third-party claims of infringement or
					misappropriation of intellectual property rights by the Services or by Your Content.
				</li>
				<li>
					For any claim covered by Section 9.2(a), DeepAR will, at its election, either: (i) procure the rights to use
					that portion of the Services alleged to be infringing;(ii) replace the alleged infringing portion of the
					Services with a non-infringing alternative; (iii) modify the alleged infringing portion of the Services to
					make it non-infringing; or (iv) terminate the allegedly infringing portion of the Services or this Agreement.
				</li>
			</ol>
			<p>
				9.3 Process. The obligations under this Section 9 will apply only if the party seeking defence or indemnity: (a)
				gives the other party prompt written notice of the claim; (b) permits the other party to control the defence and
				settlement of the claim; and (c) reasonably cooperates with the other party (at the other party’s expense) in
				the defence and settlement of the claim. In no event will a party agree to any settlementof any claim that
				involves any commitment, other than the payment of money, without the written consent of the other party.
			</p>
			<br />

			<p>10. Disclaimers.</p>
			<p>
				THE SERVICE OFFERINGS ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, OR TO THE EXTENT ANY
				STATUTORY RIGHTS APPLY THAT CANNOT BE EXCLUDED, LIMITED OR WAIVED, WE AND OUR AFFILIATES AND LICENSORS (A) MAKE
				NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE
				SERVICE OFFERINGS OR THE THIRD-PARTY CONTENT, AND (B) DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED OR EXPRESS
				WARRANTIES (I) OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,OR
				QUIET ENJOYMENT, (II) ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE, (III) THAT THE SERVICE OFFERINGS
				OR THIRD-PARTY CONTENT WILL BE UNINTERRUPTED, ERROR FREE OR FREE OF HARMFUL COMPONENTS, AND (IV) THAT ANY
				CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR ALTERED.
			</p>
			<br />

			<p>11. Limitations of Liability.</p>
			<p>
				WE AND OUR AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
				CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, REVENUES, CUSTOMERS, OPPORTUNITIES,
				GOODWILL, USE, OR DATA), EVEN IF A PARTY HAS BEEN ADVISED OFTHE POSSIBILITY OF SUCH DAMAGES. FURTHER, NEITHER WE
				NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE RESPONSIBLE FOR ANY COMPENSATION, REIMBURSEMENT, OR DAMAGES
				ARISING IN CONNECTION WITH: (A) YOUR INABILITY TO USE THE SERVICES, INCLUDING AS A RESULT OF ANY (I) TERMINATION
				OR SUSPENSION OF THIS AGREEMENT OR YOUR USE OF OR ACCESS TO THE SERVICE OFFERINGS, (II) OUR DISCONTINUATION OF
				ANY OR ALL OF THE SERVICE OFFERINGS,OR, (III) WITHOUT LIMITING ANY OBLIGATIONS UNDER THE SERVICE LEVEL
				AGREEMENTS, ANY UNANTICIPATED OR UNSCHEDULED DOWNTIME OF ALL OR A PORTION OF THE SERVICES FOR ANY REASON; (B)
				THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; (C) ANY INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY
				YOU IN CONNECTION WITH THIS AGREEMENT OR YOUR USE OF ORACCESS TO THE SERVICE OFFERINGS; OR (D) ANY UNAUTHORIZED
				ACCESS TO, ALTERATION OF, OR THE DELETION, DESTRUCTION, DAMAGE, LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT OR
				OTHER DATA. IN ANY CASE, EXCEPT FOR PAYMENTOBLIGATIONS UNDER SECTION 9.2, OUR AND OUR AFFILIATES’ AND LICENSORS’
				AGGREGATE LIABILITY UNDER THIS AGREEMENT WILL NOT EXCEED THE AMOUNT YOU ACTUALLY PAY US UNDER THIS AGREEMENT FOR
				THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE. THE LIMITATIONS IN THIS
				SECTION 11 APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
			</p>
			<br />

			<p>12. Modifications to the Agreement.</p>
			<p>
				We may modify this Agreement (including any Policies) at any time by posting a revised version on the DeepAR
				Site or by otherwise notifying you in accordance with Section 13.10; provided, however, that we will provide at
				least 30 days’ advance notice in accordance with Section 13.10 for adverse changes to any Service Level
				Agreement. Subject to the 30 day advance notice requirement with respect to adverse changes to Service Level
				Agreements, the modified terms will become effective upon posting or, if we notify you by email, as stated in
				the email message. By continuing to use the Service Offerings after the effective date of any modifications to
				this Agreement, you agree to be bound by the modified terms. It is your responsibility to check the DeepAR Site
				regularly for modifications to this Agreement. We last modified this Agreement on the date listed at the end of
				this Agreement.
			</p>
			<br />

			<p>13. Miscellaneous.</p>
			<p>
				13.1 Assignment. You will not assign or otherwise transfer this Agreement or any of your rights and obligations
				under this Agreement, without our prior written consent. Any assignment or transfer in violation of this Section
				13.1 will be void. We may assign this Agreement without your consent (a) in connection with a merger,
				acquisition or sale of all or substantially all of our assets, or (b) to any affiliate or as part of a corporate
				reorganization; and effective upon such assignment, the assigneeis deemed substituted for DeepAR as a party to
				this Agreement and DeepAR is fully released from all of its obligations and duties to perform under this
				Agreement. Subject to the foregoing, this Agreement will be binding upon, and inure to the benefit of the
				parties and their respective permitted successors and assigns.
			</p>
			<p>
				13.2 Entire Agreement. This Agreement incorporates the Policies by reference and is the entire agreement between
				you and us regarding the subject matter of this Agreement. This Agreement supersedes all prior or
				contemporaneous representations, understandings, agreements, or communications between you and us, whether
				written or verbal, regarding the subject matter of this Agreement. We will not be bound by, and specifically
				object to, any term, condition or other provisionthat is different from or in addition to the provisions of this
				Agreement (whether or not it would materially alter this Agreement) including for example, any term, condition
				or other provision (a) submitted by you in any order, receipt, acceptance, confirmation, correspondence or other
				document, (b) related to any online registration, response to any Request for Bid, Request for Proposal, Request
				for Information, or other questionnaire, or (c) related to any invoicing process that you submit or require us
				to complete. If the terms of this document are inconsistent with the terms contained in any Policy, the terms
				contained in this document will control, except that the Service Terms will control over this document.
			</p>
			<p>
				13.3 Force Majeure. We and our affiliates will not be liable for any delay or failure to perform any obligation
				under this Agreement where the delay or failure results from any cause beyond our reasonable control, including
				acts of God, labour disputes or other industrial disturbances, electrical or power outages, utilities or other
				telecommunications failures, earthquake, storms or other elements of nature, blockages, embargoes, riots, acts
				or orders of government, acts of terrorism, or war.
			</p>
			<p>
				13.4 Governing Law. The Governing law, without reference to conflict of law rules, govern this Agreement and any
				dispute of any sort that might arise between you andus. The United Nations Convention for the International Sale
				of Goods does not applyto this Agreement.
			</p>
			<p>
				13.5 Disputes. Any dispute or claim relating in any way to your use of the Service Offerings, or to any products
				or services sold or distributed by DeepAR will be adjudicated in the Governing Courts, and you consent to
				exclusive jurisdiction and venue in the Governing Courts; except, if the applicable DeepAR Contracting Party is
				I Love IceCream Ltd., any such dispute will be resolved by binding arbitration as provided in this Section 13.5,
				ather than in court, except that you may assert claimsin small claims court if your claims qualify. The Federal
				Arbitration Act and federal arbitration law apply to this Agreement. There is no judge or jury in arbitration,
				and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the
				same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must
				follow the terms of this Agreement as a court would. Payment of filing, administration and arbitrator fees
				willbe governed by the AAA's rules. We will reimburse those fees for claims totalling lessthan $5,000 unless the
				arbitrator determines the claims are frivolous. We will not seek attorneys' fees and costs in arbitration unless
				the arbitrator determines the claims are frivolous. You may choose to have the arbitration conducted by
				telephone, based on written submissions, or at a mutually agreed location. We and you agree that any dispute
				resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or
				representative action. If for any reason a claim proceeds in court rather than in arbitration we and you waive
				any right to a jury trial.Notwithstanding the foregoing we and you both agree that you or we may bring suit in
				court to enjoin infringement or other misuse of intellectual property rights.
			</p>
			<p>
				13.6 Trade Compliance. In connection with this Agreement, each party will comply with all applicable import,
				re-import, sanctions, anti-boycott, export, and re-export control laws and regulations, including all such laws
				and regulations that apply to a U.S. company, such as the Export Administration Regulations, the International
				Traffic in Arms Regulations, and economic sanctions programs implemented by the Office of Foreign Assets
				Control. For clarity, you are solely responsible for compliancerelated to the manner in which you choose to use
				the Service Offerings, including your transfer and processing of Your Content, the provision of Your Content to
				End Users, and the DeepAR region in which any of the foregoing occur. You represent andwarrant that you and your
				financial institutions, or any party that owns or controls you or your financial institutions, are not subject
				to sanctions or otherwise designated on any list of prohibited or restricted parties, including but not limited
				to the lists maintained by the United Nations Security Council, the U.S. Government (e.g., the Specially
				Designated Nationals List and Foreign Sanctions Evaders List of the U.S. Department of Treasury, and the Entity
				List of the U.S. Department of Commerce), the European Union or its Member States, or other applicable
				government authority.
			</p>
			<p>
				13.7 Independent Contractors; Non-Exclusive Rights. We and you are independent contractors, and this Agreement
				will not be construed to create a partnership, joint venture, agency, or employment relationship. Neither party,
				nor any of their respective affiliates, is an agent of the other for any purpose or has the authority to bind
				the other. Both parties reserve the right (a) to develop or have developed for it products, services, concepts,
				systems, or techniques that are similar to or compete with the products, services, concepts, systems, or
				techniques developed or contemplated by the other party, and (b) to assist third party developers or systems
				integrators who may offer products or services which compete with the other party’s products or services.
			</p>
			<p>
				13.8 Language. All communications and notices made or given pursuant to this Agreement must be in the English
				language. If we provide a translation of the Englishlanguage version of this Agreement, the English language
				version of the Agreement will control if there is any conflict.
			</p>
			<p>
				13.9 Confidentiality and Publicity. You may use DeepAR Confidential Information only in connection with your use
				of the Service Offerings as permitted under this Agreement. You will not disclose DeepAR Confidential
				Information during the Term or at any time during the 5-year period following the end of the Term. You will take
				all reasonable measures to avoid disclosure, dissemination or unauthorized use of DeepAR Confidential
				Information, including, at a minimum, those measures you take to protect your own confidential information of a
				similar nature. You will not issue anypress release or make any other public communication with respect to this
				Agreement or your use of the Service Offerings.
			</p>
			<p>13.10 Notice.</p>
			<ol type='a'>
				<li>
					To You. We may provide any notice to you under this Agreement by: (i) posting a notice on the DeepAR Site; or
					(ii) sending a message to the email address then associated with your account. Notices we provide by posting
					on the DeepAR Site will be effective upon posting and notices we provide by emailwill be effective when we
					send the email. It is your responsibility to keep your email address current. You will be deemed to have
					received any email sent to the email address then associated with your account when we send the email, whether
					or not you actually receive the email.
				</li>
				<li>
					To Us. To give us notice under this Agreement, you must contact DeepAR byfacsimile transmission or personal
					delivery, overnight courier or registered or certified mail to the facsimile number or mailing address, as
					applicable, listed for the applicable DEEPAR Contracting Party in Section 14 below. We may update the
					facsimile number or address for notices to us by posting a notice onthe DeepAR Site. Notices provided by
					personal delivery will be effective immediately. Notices provided by facsimile transmission or overnight
					courier will be effective one business day after they are sent. Notices provided registered or certified mail
					will be effective three business days after they are sent.
				</li>
			</ol>
			<p>
				13.11 No Third-Party Beneficiaries. Except as set forth in Section 9, this Agreement does not create any
				third-party beneficiary rights in any individual or entity that is not a party to this Agreement.
			</p>
			<p>
				13.12 U.S. Government Rights. The Service Offerings are provided to the U.S. Government as “commercial items,”
				“commercial computer software,” “commercial computer software documentation,” and “technical data” with the same
				rights and restrictions generally applicable to the Service Offerings. If you are using the Service Offerings on
				behalf of the U.S. Government and these terms fail to meet the U.S. Government’s needs or are inconsistent in
				any respect with federal law, you will immediately discontinue your use of the Service Offerings. The terms
				“commercial item” “commercial computer software,” “commercial computer software documentation,” and “technical
				data” are defined in the Federal Acquisition Regulation and the Defense Federal Acquisition Regulation
				Supplement.
			</p>
			<p>
				13.13 No Waivers. The failure by us to enforce any provision of this Agreement will not constitute a present or
				future waiver of such provision nor limit our right to enforce such provision at a later time. All waivers by us
				must be in writing to be effective.
			</p>
			<p>
				13.14 Severability. If any portion of this Agreement is held to be invalid or unenforceable, the remaining
				portions of this Agreement will remain in full force and effect. Any invalid or unenforceable portions will be
				interpreted to effect and intent of the original portion. If such construction is not possible, the invalid or
				unenforceable portion will be severed from this Agreement but the rest of the Agreement will remain in full
				force and effect.
			</p>
			<br />

			<p>14. Definitions.</p>
			<p>
				“Acceptable Use Policy” means the policy located at https://deepar.ai/aup (and any successor or related
				locations designated by us), as it may be updated by us from time to time.
			</p>
			<p>
				“Account Country” is the country associated with your account. If you have provided a valid tax registration
				number for your account, then your Account Country is the country associated with your tax registration. If you
				have not provided a valid tax registration, then your Account Country is the country where your billing address
				is located, except if your credit card account is issued in a different country and your contact address is also
				in that country, then your Account Country is that different country.
			</p>
			<p>
				“Account Information” means information about you that you provide to us in connection with the creation or
				administration of your DeepAR account. For example,Account Information includes names, usernames, phone numbers,
				email addresses and billing information associated with your DeepAR account.
			</p>
			<p>“API” means an application program interface.</p>
			<p>
				“DeepAR Confidential Information” means all nonpublic information disclosed by us, our affiliates, business
				partners or our or their respective employees, contractors or agents that is designated as confidential or that,
				given the nature of the information or circumstances surrounding its disclosure, reasonably should be understood
				to be confidential. DeepAR Confidential Information includes: (a) nonpublic information relating to our or our
				affiliates or business partners’ technology, customers, business plans, promotional and marketing activities,
				finances and other business affairs; (b) third-party information that we are obligated to keep confidential; and
				(c) the nature,content and existence of any discussions or negotiations between you and us or our affiliates.
				DeepAR Confidential Information does not include any information that: (i) is or becomes publicly available
				without breach of this Agreement; (ii) can be shown by documentation to have been known to you at the time of
				your receipt from us; (iii) is received from a third party who did not acquire or disclose the same by a
				wrongful or tortious act; or (iv) can be shown by documentation to have been independently developed by you
				without reference to the DeepAR Confidential Information.
			</p>
			<p>
				“DeepAR Content” means Content we or any of our affiliates make available in connection with the Services or on
				the DeepAR Site to allow access to and use of the Services, including APIs; WSDLs; Documentation; sample code;
				software libraries; command line tools; proofs of concept; templates; and other related technology (including
				any of the foregoing that are provided by our personnel). DeepAR Content does not include the Services or
				Third-Party Content.
			</p>
			<p>
				"DeepAR Contracting Party" means the party identified in the table below, based on your Account Country. If you
				change your Account Country to one identified to a different DeepAR Contracting Party below, you agree that this
				Agreement is then assigned to the new DeepAR Contracting Party under Section 13.1 without any further action
				required by either party.
			</p>
			<table className='legal-table'>
				<tbody>
					<tr>
						<th>Account Country</th>
						<th>DeepAR Contracting Party</th>
						<th>Mailing Address</th>
					</tr>
					<tr>
						<td>Any country within Europe, the Middle East, or Africa ("EMEA")*</td>
						<td>I Love IceCream Ltd.</td>
						<td>9 Percy St London W1T 1DL UK</td>
					</tr>
					<tr>
						<td>Any other country that isnot in EMEA</td>
						<td>I Love IceCream Ltd.</td>
						<td>9 Percy St London W1T 1DL UK</td>
					</tr>
				</tbody>
			</table>
			<br />
			<p>
				“DeepAR Marks” means any trademarks, service marks, service or trade names, logos, and other designations of
				DeepAR and its affiliates that we may make available to you in connection with this Agreement.
			</p>
			<p>
				“DeepAR Site” means https://deepar.ai (and any successor or related site designatedby us), as may be updated by
				us from time to time.
				<br />
				“Content” means software (including machine images), data, text, audio, video or images.
			</p>
			<p>
				“Documentation” means the user guides and admin guides (in each case exclusive ofcontent referenced via
				hyperlink) for the Services located at https://developer.deepar.ai (and any successor or related locations
				designated by us), as such user guides and admin guides may be updated by DeepAR from time to time.
			</p>
			<p>
				“End User” means any individual or entity that directly or indirectly through another user: (a) accesses or uses
				Your Content; or (b) otherwise accesses or uses the Service Offerings under your account. The term “End User”
				does not include individuals or entities when they are accessing or using the Services or any Content under
				their own DeepAR account, rather than under your account.
			</p>
			<p>
				"Governing LDeepAR" and “Governing Courts” mean, for each DEEPAR Contracting Party, the lDeepAR and courts set
				forth in the following table:
			</p>
			<table className='legal-table'>
				<tbody>
					<tr>
						<th>DeepAR Contracting Party</th>
						<th>Governing Laws</th>
						<th>Governing Courts</th>
					</tr>
					<tr>
						<td>I Love IceCream Ltd</td>
						<td>The laws of England</td>
						<td>The courts of England</td>
					</tr>
				</tbody>
			</table>
			<br />
			<p>
				“Indirect Taxes” means applicable taxes and duties, including, without limitation, VAT, Service Tax, GST, excise
				taxes, sales and transactions taxes, and gross receipts tax.
			</p>
			<p>
				“Losses” means any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys’
				fees).
			</p>
			<p>
				“Policies” means the Acceptable Use Policy, Privacy Policy, the Site Terms, the Service Terms, the Trademark Use
				Guidelines, all restrictions described in the DEEPAR Content and on the DeepAR Site, and any other policy or
				terms referenced inor incorporated into this Agreement, but does not include whitepapers or other marketing
				materials referenced on the DeepAR Site.
			</p>
			<p>
				“Privacy Policy” means the privacy policy located at{' '}
				<Link to='/privacyNotice' target='_blank'>
					privacy notice
				</Link>{' '}
				(and any successor or related locations designated by us), as it may be updated by us from time to time.
			</p>
			<p>
				“Service” means each of the services made available by us or our affiliates, includingthose web services
				described in the Service Terms. Services do not include Third-Party Content.
			</p>
			<p>
				“Service Attributes” means Service usage data related to your account, such as resource identifiers, metadata
				tags, security and access roles, rules, usage policies, permissions, usage statistics and analytics.
			</p>
			<p>
				“Service Level Agreement” means all service level agreements that we offer with respect to the Services and post
				on the DeepAR Site, as they may be updated by us from time to time (and any successor or related locations
				designated by DeepAR), asmay be updated by DeepAR from time to time.
			</p>
			<p>
				“Service Offerings” means the Services (including associated APIs), the DeepAR Content, the DeepAR Marks, and
				any other product or service provided by us under this Agreement. Service Offerings do not include Third-Party
				Content.
			</p>
			<p>
				“Service Terms” means the rights and restrictions for particular Services located at
				https://deepar.ai/serviceterms (and any successor or related locations designated by us), as may be updated by
				us from time to time.
			</p>
			<p>
				“Site Terms” means the terms of use located at{' '}
				<Link to='/siteTerms' target='_blank'>
					site terms
				</Link>{' '}
				and any successor or related locations designated by us), as may be updated by us from timeto time.
			</p>
			<p>“Suggestions” means all suggested improvements to the Service Offerings that you provide to us.</p>
			<p>“Term” means the term of this Agreement described in Section 7.1.</p>
			<p>
				“Termination Date” means the effective date of termination provided in accordance with Section 7, in a notice
				from one party to the other.
			</p>
			<p>
				“Third-Party Content” means Content made available to you by any third party on the DeepAR Site or in
				conjunction with the Services.
			</p>
			<p>
				“Trademark Use Guidelines” means the guidelines and trademark license located at
				https://deepar.ai/trademark-guidelines and any successor or related locations designated by us), as they may be
				updated by us from time to time.
			</p>
			<p>
				“Your Content” means Content that you or any End User transfers to us for processing, storage or hosting by the
				Services in connection with your DeepAR account and any computational results that you or any End User derive
				from the foregoing through their use of the Services. Your Content does not include Account Information.
			</p>
		</Container>
	);
};

export default CustomerAgreement;
