import { Container, Box } from '@mui/material';
import React from 'react';
import { AppContext } from '../../utilities/StateProvider';
import AccountForm from './AccountForm';
import Invoices from './Invoices';

const Account = () => {
	const { state } = React.useContext(AppContext);

	const stripeId = React.useMemo(() => {
		return state.appAuthState.currentUser?.attributes?.['custom:stripe_id'];
	}, [state.appAuthState.currentUser]);

	return (
		<Container component={Box} pt={4}>
			{state.appAuthState.appAuthState === 'signedIn' && <AccountForm />}
			{stripeId && <Invoices />}
		</Container>
	);
};

export default Account;
