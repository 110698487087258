import React from 'react';

import { Alert, Box, Button, Chip, Dialog, DialogContent, DialogProps, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQueryClient } from 'react-query';
import { ProjectI, useCards } from '../../../utilities/queries';
import SelectOrCreateCard from '../SelectOrCreateCard';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { AppContext } from '../../../utilities/StateProvider';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import ResponsiveDialog from '../../../components/ResponsiveDialog';

interface ChangePaymentMethodDialogPropsI extends DialogProps {
	closeDialog: () => void;
	project: ProjectI;
}

const ChangePaymentMethodDialog = (props: ChangePaymentMethodDialogPropsI) => {
	const { closeDialog, project, ...rest } = props;

	const history = useHistory();

	const [loading, setLoading] = React.useState(false);

	const { state, setGlobalAlertMessage } = React.useContext(AppContext);

	const stripe = useStripe();
	const elements = useElements();

	const stripeId = React.useMemo(() => {
		return state.appAuthState.currentUser?.attributes?.['custom:stripe_id'];
	}, [state.appAuthState.currentUser]);

	const { data: cardsData, isLoading: cardsLoading } = useCards(!!stripeId);
	const [createNewCard, setCreateNewCard] = React.useState(false);
	const [selectedCard, setSelectedCard] = React.useState('');
	const [currentCard, setCurrentCard] = React.useState('');

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	const queryClient = useQueryClient();

	React.useEffect(() => {
		setLoading(true);
		const getCurrentCard = async () => {
			try {
				const response = await API.get('AppsApi', '/projectPaymentMethod/' + project.SubscriptionId, {});
				setCurrentCard(response.default_source);
				setSelectedCard(response.default_source);
			} catch (error: any) {
				console.log(error.response.data);
			} finally {
				setLoading(false);
			}
		};

		getCurrentCard();
	}, [project.SubscriptionId]);

	const updateProjectPaymentMethod = async (params: { token?: string; cardId?: string; stripeId: string; SubscriptionId: string }) => {
		const { SubscriptionId, ...rest } = params;
		const data = {
			body: { ...rest },
			headers: { 'Content-Type': 'application/json' },
		};
		return await API.post('AppsApi', '/projectPaymentMethod/' + SubscriptionId, data);
	};

	const mutation = useMutation(updateProjectPaymentMethod, {
		onSuccess: async (data) => {
			queryClient.invalidateQueries(['project', project?.Id]);
			if (!data.pay_int?.next_action) {
				closeDialog();
			} else {
				if (data.pay_int.client_secret && data.pay_int.source) {
					await stripe?.confirmCardPayment(data.pay_int.client_secret, {
						payment_method: data.pay_int.source,
					});
					history.push(`projects/${data.item?.Id}`);
				}
			}
		},
	});

	const handleChangePaymentMethod = async () => {
		setLoading(true);

		const user = state.appAuthState?.currentUser?.attributes?.['custom:stripe_id'];

		if (!project || !project?.SubscriptionId || !user) {
			return;
		}

		try {
			if (createNewCard) {
				if (stripe && elements) {
					const stripeCardElement = elements.getElement(CardElement);
					if (stripeCardElement) {
						const { token, error } = await stripe.createToken(stripeCardElement);
						if (error) {
							throw new Error(error.message);
						}

						if (token && user) {
							await mutation.mutateAsync({
								SubscriptionId: project.SubscriptionId,
								stripeId: user,
								token: token.id,
							});
						}
					}
				}
			} else {
				await mutation.mutateAsync({
					SubscriptionId: project.SubscriptionId,
					stripeId: user,
					cardId: selectedCard,
				});
			}
			closeDialog();
			setGlobalAlertMessage({ message: 'Payment method updated successfully' });
		} catch (error: any) {
			setServerErrorMessage(error?.response?.data?.error?.message);
		} finally {
			setLoading(false);
		}
	};

	const isCurrentMethod = selectedCard === currentCard;

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Change payment method</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					{!!serverErrorMessage && <Alert severity='warning'>{serverErrorMessage}</Alert>}
					{isCurrentMethod && !loading && !createNewCard && <Chip label='Current payment method' color='info' />}
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} direction='column'>
						<Box>
							<SelectOrCreateCard
								cards={cardsData?.data}
								loading={cardsLoading}
								createNewCard={createNewCard}
								setCreateNewCard={setCreateNewCard}
								selectedCard={selectedCard}
								setSelectedCard={setSelectedCard}
							/>
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' onClick={handleChangePaymentMethod} disabled={!createNewCard && isCurrentMethod}>
								Change
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default ChangePaymentMethodDialog;
