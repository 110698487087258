import { Alert, Box, Button, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomAlertPropsI } from '../utilities/CustomAlert';
import { AppContext } from '../utilities/StateProvider';

interface NewPasswordFormI {
	password: string;
}

interface RequireNewPasswordPropsI {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setAlert: React.Dispatch<React.SetStateAction<CustomAlertPropsI | undefined>>;
}

const RequireNewPassword = (props: RequireNewPasswordPropsI) => {
	const { state, dispatch } = React.useContext(AppContext);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<NewPasswordFormI>();

	const handleResetPassword: SubmitHandler<NewPasswordFormI> = async (data: NewPasswordFormI) => {
		props.setLoading(true);
		const password = data.password.trim();

		try {
			await Auth.completeNewPassword(state.appAuthState.currentUser, password);
			dispatch({ type: 'CHANGE_AUTH_STATE', payload: { appAuthState: 'signedIn' } });
		} catch (error: any) {
			props.setLoading(false);
			props.setAlert({ severity: 'warning', message: error.message });
			console.error(error);
		}
	};

	return (
		<Box component='form' onSubmit={handleSubmit(handleResetPassword)}>
			<Alert severity='info'>To protect your account, please enter a new password</Alert>
			<TextField
				{...register('password', { required: true, minLength: 8 })}
				margin='normal'
				required
				fullWidth
				name='password'
				label='Password'
				type='password'
				id='password'
				error={!!errors.password}
				helperText={!!errors.password && 'Please enter a password that is 8 characters or longer'}
				autoComplete='current-password'
			/>
			<Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
				Set new password
			</Button>
		</Box>
	);
};

export default RequireNewPassword;
