import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export interface SideBarListItemProps {
	text: string;
	url: string;
	target?: string;
	icon?: JSX.Element;
	onClick?: () => void;
}

const SidebarListItem = (props: SideBarListItemProps) => {
	const { url, target, text, icon, onClick } = props;

	const { pathname } = useLocation();

	if (!!target) {
		return (
			<ListItemButton component={'a'} href={url} target={target || ''} selected={pathname === url} onClick={() => onClick?.()}>
				<ListItemIcon>{icon ? icon : <StarBorderIcon />}</ListItemIcon>
				<ListItemText primary={text} />
			</ListItemButton>
		);
	}

	return (
		<ListItemButton component={Link} to={url} target={target || ''} selected={pathname === url} onClick={() => onClick?.()}>
			<ListItemIcon>{icon ? icon : <StarBorderIcon />}</ListItemIcon>
			<ListItemText primary={text} />
		</ListItemButton>
	);
};

export default SidebarListItem;
