import { Box } from '@mui/system'
import React from 'react'

interface ClientProps {
  name: string
  logoUrl: string
}

const Client = (props: ClientProps) => {
  return (
    <Box
      component='img'
      src={props.logoUrl}
      alt={props.name}
      sx={{
        flex: 1,
        width: 100,
        maxHeight: 70,
        objectFit: 'contain',
      }}
    />
  )
}

export default Client
