import { ArrowRight } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { pricePlans } from '../../pricePlans';
import { lastMonthStrings } from '../../utilities/helpers';
import { ProjectI } from '../../utilities/queries';
import MauChart, { transformForMauChart } from './Project/MauChart';

interface ProjectListItemPropsI {
	project: ProjectI;
}

const ProjectListItem = (props: ProjectListItemPropsI) => {
	const { project } = props;
	const { apps } = project;

	const monthStrings = React.useMemo(() => {
		return lastMonthStrings(6);
	}, []);

	return (
		<Link to={`projects/${project.Id}`}>
			<Paper sx={{ padding: 3, my: 2 }}>
				<Grid container spacing={2} minHeight={!!apps.length ? '240px' : undefined}>
					<Grid item sm={3}>
						<Typography variant='h5'>{project.Name}</Typography>
						<Typography variant='body1' color='text.secondary'>
							{pricePlans.find((p) => p.id === project?.PlanId)?.name}
						</Typography>
					</Grid>
					<Grid item sm={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						{!!apps.length && (
							<MauChart
								data={transformForMauChart(monthStrings, project.Mau)}
								infoRight='Last 6 months'
								emptyState={
									<Box sx={{ bgcolor: 'background.default', height: '100%', p: 4, borderRadius: 3, display: 'flex', alignItems: 'center' }}>
										<Box>
											<Typography variant='h6' gutterBottom>
												No users have enjoyed your project recently
											</Typography>
											<Button variant='contained'>Connect your project</Button>
										</Box>
									</Box>
								}
							/>
						)}
						{!apps.length && (
							<Button variant='contained' endIcon={<ArrowRight />}>
								Add app to get started
							</Button>
						)}
					</Grid>
				</Grid>
			</Paper>
		</Link>
	);
};

export default ProjectListItem;
