import React from 'react';

import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogProps,
	FormControlLabel,
	IconButton,
	LinearProgress,
	Stack,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SingleAdminAccountI } from '../../utilities/queries';
import { PricePlanI, privatePricePlans } from '../../pricePlans';
import { API } from 'aws-amplify';
import { AppContext } from '../../utilities/StateProvider';

interface PrivatePlansPermissionsDialogPropsI extends DialogProps {
	closeDialog: () => void;
	account: SingleAdminAccountI;
}

const PrivatePlansPermissionsDialog = (props: PrivatePlansPermissionsDialogPropsI) => {
	const { closeDialog, account, ...rest } = props;

	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const [allowedPricePlans, setAllowedPricePlans] = React.useState<PricePlanI['name'][]>([]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setAllowedPricePlans(account?.UserAtts?.AccessToPrivatePlans || []);
	}, [account]);

	const attrs = account?.UserAttributes;
	const given_name = attrs?.find((item) => item.Name === 'given_name')?.Value || '';
	const family_name = attrs?.find((item) => item.Name === 'family_name')?.Value || '';

	const savePermissions = async (pricePlans: PricePlanI['name'][], userId: string) => {
		setLoading(true);
		try {
			const data = {
				body: {
					userId: userId,
					plans: pricePlans,
				},
				headers: { 'Content-Type': 'application/json' },
			};

			await API.post('AppsApi', '/admin/privatePlansPerms/', data);
			setGlobalAlertMessage({
				message: 'User permissions updated 🙌',
			});
			closeDialog();
		} catch (error: any) {
			setGlobalAlertMessage({
				message: error.response.data.message,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (checked: boolean, name: PricePlanI['name']) => {
		let arr = [...allowedPricePlans];

		if (checked) {
			arr.push(name);
		} else {
			arr = arr.filter((pp) => pp !== name);
		}

		setAllowedPricePlans(arr);
	};

	return (
		<Dialog {...rest}>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<DialogContent sx={{ width: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>
							Private plans permissions for {given_name} {family_name}
						</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} direction='column'>
						<Box sx={{ maxWidth: '500px', m: 'auto' }}>
							{privatePricePlans.map((pp, i) => (
								<Box key={i}>
									<FormControlLabel
										control={
											<Checkbox
												checked={allowedPricePlans.includes(pp.name)}
												onChange={(e, checked) => handleChange(checked, pp.name)}
											/>
										}
										label={pp.name}
									/>
								</Box>
							))}
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button
								variant='contained'
								fullWidth
								size='large'
								onClick={() => savePermissions(allowedPricePlans, account?.Username)}
							>
								Continue
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default PrivatePlansPermissionsDialog;
