import { Dialog, DialogProps } from '@mui/material';
import React from 'react';

const ResponsiveDialog = (props: DialogProps) => {
	return (
		<Dialog
			{...props}
			sx={{
				...props.sx,
				'& .MuiDialog-container > .MuiPaper-root': {
					width: {
						xs: '100vw',
						sm: '450px',
					},
					borderRadius: {
						xs: 0,
						sm: 2,
					},
					margin: {
						xs: 0,
						sm: 4,
					},
				},
			}}
		>
			{props.children}
		</Dialog>
	);
};

export default ResponsiveDialog;
