import React from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Chip, Container, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useDemoProject } from '../../../utilities/queries';
import Tutorial from './Tutorial';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';

interface MatchParams {
	demoProjectId?: string;
}

const DemoProject = () => {
	const { demoProjectId } = useParams<MatchParams>();
	const { data: demoProject, isLoading } = useDemoProject(demoProjectId || '');

	if (!demoProject || isLoading) {
		return (
			<Container sx={{ py: 5, height: '100%' }} component={Box} paddingY={4}>
				<Grid container sx={{ h: '100%' }}>
					<Grid item xs={12} md={6} sx={{ px: 2 }}>
						<Skeleton variant='rectangular' height={200} sx={{ mb: 2 }} />
						<Skeleton variant='rectangular' height={100} sx={{ mb: 2 }} />
						<Skeleton variant='rectangular' height={800} sx={{ mb: 2 }} />
					</Grid>
					<Grid item xs={12} md={6} sx={{ px: 2 }}>
						<Skeleton variant='rectangular' height={400} sx={{ mb: 2 }} />
					</Grid>
				</Grid>
			</Container>
		);
	}

	return (
		<>
			<ScrollToTopOnMount />
			<Container sx={{ py: 5 }}>
				<Grid container>
					<Grid item xs={12} md={6} sx={{ px: 2 }} order={{ md: 1, xs: 2 }}>
						<Box sx={{ mb: 6 }}>
							<Button component={Link} to='/demo-projects' startIcon={<ArrowBack />}>
								All Demo Projects
							</Button>
						</Box>
						<Typography variant='h1' gutterBottom>
							{demoProject?.Title}
						</Typography>
						<Typography variant='body1' color={'text.secondary'} gutterBottom>
							{demoProject?.Description}
						</Typography>
						<Stack direction='row' paddingBottom={1} flexWrap='wrap' sx={{ mb: 6 }}>
							{demoProject?.Code.map((code, i) => (
								<Chip label={`${code.Platform} (${code.Language})`} key={i} sx={{ mt: 1, mr: 1 }} />
							))}
						</Stack>
						<Typography variant='h3' gutterBottom>
							Get started with this project
						</Typography>
						<Tutorial demoProject={demoProject} />
					</Grid>
					<Grid item xs={12} md={6} sx={{ px: 2, position: 'relative' }} order={{ md: 2, xs: 1 }}>
						<Box
							sx={{
								width: '100%',
								position: { xs: 'static', md: 'sticky' },
								top: { xs: 0, md: 90 },
								height: 'fit-content',
							}}
						>
							<Box
								component='img'
								sx={{
									width: '100%',
									zIndex: -1,
									position: 'absolute',
									top: 0,
									bottom: 0,
									right: 0,
									left: 0,
									filter: 'blur(20rem)',
								}}
								alt={demoProject?.Title}
								src={demoProject?.ImageUrl}
							/>
							<Box
								component='img'
								sx={{
									width: '100%',
									zIndex: 100,
									top: 0,
									bottom: 0,
									right: 0,
									left: 0,
									borderRadius: 4,
									objectFit: 'cover',
								}}
								alt={demoProject?.Title}
								src={demoProject?.ImageUrl}
							/>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default DemoProject;
