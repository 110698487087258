import { Box, Button, Dialog, DialogContent, DialogProps, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { deleteProject, ProjectI } from '../../../utilities/queries';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import ResponsiveDialog from '../../../components/ResponsiveDialog';

interface DeleteProjectConfirmationDialogPropsI extends DialogProps {
	project: ProjectI;
	closeDialog: () => void;
}

const DeleteProjectConfirmationDialog = (props: DeleteProjectConfirmationDialogPropsI) => {
	const { project, closeDialog, ...rest } = props;
	const history = useHistory();

	const queryClient = useQueryClient();

	const mutation = useMutation(deleteProject, {
		onSuccess: async () => {
			queryClient.setQueryData<ProjectI[]>('projects', (old) => old?.filter((p) => p.Id !== project.Id) || []);
			history.push('/projects');
		},
	});

	const handleDeleteProject = async (projectId: string) => {
		try {
			await mutation.mutateAsync(projectId);
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{mutation.isLoading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '600px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Delete {project.Name}?</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box py={2}>
						<Typography>
							This project {!!project.apps.length && `and ${project.apps.length} applications licences`} will be deleted, and any existing subscriptions for the
							project will be canceled. Are you sure you want to proceed?
						</Typography>
					</Box>
					<Stack direction='row' spacing={1} mt={3}>
						<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
							Cancel
						</Button>
						<Button variant='contained' color='error' fullWidth size='large' onClick={() => handleDeleteProject(project.Id)}>
							Delete project {!!project.apps.length && `and ${project.apps.length} apps`}
						</Button>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default DeleteProjectConfirmationDialog;
