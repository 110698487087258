import React from 'react';

import { Download, GitHub } from '@mui/icons-material';
import { Alert, Box, Button, Divider, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { DemoProject } from '../../../Models/demoProjects';
import { DownloadName } from '../../../utilities/queries';
import { extraDownloadInfo } from '../../Downloads';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SelectOrCreateProject from './SelectOrCreateProject';
import { AppContext } from '../../../utilities/StateProvider';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

interface TutorialPropsI {
	demoProject: DemoProject;
}

const Tutorial = ({ demoProject }: TutorialPropsI) => {
	const [tabValue, setTabValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const { state } = React.useContext(AppContext);
	const signedIn = state.appAuthState.appAuthState === 'signedIn';

	return (
		<Paper sx={{ my: 3 }} component='section' id='select-create-project'>
			<Box sx={{ width: '100%', overflowX: 'scroll' }}>
				{demoProject.Code.length > 1 && (
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={tabValue} onChange={handleChange} aria-label='basic tabs example' scrollButtons='auto' variant='scrollable'>
							{demoProject.Code.map((code, i) => (
								<Tab label={`${code.Platform} (${code.Language})`} {...a11yProps(i)} key={i} />
							))}
						</Tabs>
					</Box>
				)}
				{demoProject.Code.map((code, i) => (
					<TabPanel value={tabValue} index={i} key={i}>
						<Box sx={{ px: 1, pt: 3 }}>
							<Typography variant='h2' gutterBottom>
								Install {demoProject.Title} for {code.Platform}
							</Typography>
							<Typography variant='body1' color='text.secondary' gutterBottom>
								Language: {code.Language}
							</Typography>

							<Box sx={{ my: 6 }} id='select-create-project'>
								<Typography variant='h3'>1. {signedIn ? 'Select or create project' : 'Sign in to create project'}</Typography>

								<Box sx={{ my: 3 }}>
									<SelectOrCreateProject demoProject={demoProject} code={code} />
								</Box>
							</Box>

							<Divider />

							<Box sx={{ my: 6 }}>
								<Typography variant='h3'>2. Get template project code</Typography>

								<Box sx={{ my: 2 }}>
									<Alert severity='info' sx={{ mb: 2 }}>
										You can also skip this step to complete the equivalent next steps in your own project.
									</Alert>
									<Button href={code.RepositoryUrl} startIcon={<GitHub />} variant='outlined' target='_blank'>
										{code.RepositoryName}
									</Button>
								</Box>
							</Box>

							<Divider />

							<Box sx={{ my: 6 }}>
								<Typography variant='h3'>3. Download {code.Platform} SDK</Typography>
								<Stack sx={{ my: 2 }} direction='row'>
									<Button href={code.SdkDownloadUrl} startIcon={<Download />} variant='outlined' sx={{ mr: 1 }}>
										{code.Platform} SDK
									</Button>
									<Button href={'https://docs.deepar.ai/deepar-sdk/introduction'} startIcon={<HelpOutlineIcon />} variant='outlined' target='_blank'>
										Documentation
									</Button>
								</Stack>
							</Box>

							<Divider />

							<Box sx={{ my: 6 }}>
								<Typography variant='h3' gutterBottom>
									4. Install SDK code
								</Typography>
								<Box sx={{ my: 2 }}>
									<Typography>
										<span dangerouslySetInnerHTML={{ __html: code.InstallationInstructions }}></span>
									</Typography>
								</Box>
							</Box>

							<Divider />

							<Box sx={{ my: 6 }}>
								<Typography variant='h3' gutterBottom>
									5. Add some AR filters to your project!
								</Typography>
								<Box sx={{ my: 2 }}>
									<Button
										href={extraDownloadInfo.find((d) => d.name === DownloadName.filterPack)?.customDownloadUrl}
										startIcon={<Download />}
										variant='outlined'
									>
										Download Free Assets
									</Button>
								</Box>
							</Box>
						</Box>
					</TabPanel>
				))}
			</Box>
		</Paper>
	);
};

export default Tutorial;
