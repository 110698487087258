import React from 'react';
import { Amplify } from 'aws-amplify';
import ReactGA from 'react-ga4';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ConfirmSignup from './login/ConfirmSignup/ConfirmSignup';
import ForgotPassword from './login/ForgotPassword/ForgotPassword';
import Login from './login/Login';
import LoginRoute from './login/LoginRoute';
import PrivateRoute from './login/PrivateRoute';
import Signup from './login/Signup';
import Account from './pages/Account';
import AdminRoute from './pages/Admin/AdminRoute';
import Admin from './pages/Admin';
import Downloads from './pages/Downloads';
import Home from './pages/Home/Home';
import CustomerAgreement from './pages/Legal/CustomerAgreement';
import PrivacyNotice from './pages/Legal/PrivacyNotice';
import SiteTerms from './pages/Legal/SiteTerms';
import Pricing from './pages/Pricing';
import Project from './pages/Projects/Project/Project';
import Projects from './pages/Projects/Projects';
import Template from './pages/Template/Template';
import StateProvider from './utilities/StateProvider';
import AdminProjects from './pages/Admin/AdminProjects';
import AdminProject from './pages/Admin/AdminProject';
import AdminAccount from './pages/Admin/AdminAccount';
import Resources from './pages/Resources';
import ResetPassword from './login/ResetPassword';
import DevStagePasswordCheck from './devstagepass/DevStagePasswordCheck';
import NotFound from './pages/NotFound';
import DemoProjects from './pages/DemoProjects';
import DemoProject from './pages/DemoProjects/DemoProject';
import StudioDeveloperRoute from './pages/StudioDeveloper/StudioDeveloperRoute';
import StudioDeveloper from './pages/StudioDeveloper';
import StudioDeveloperEffectTemplates from './pages/StudioDeveloper/StudioDeveloperEffectTemplates';

export type StageT = 'dev' | 'stage' | 'prod';

var stage = (process.env.REACT_APP_STAGE as StageT) || 'dev';
var currStackConfiguration = require('./stacks.json')[stage];

ReactGA.initialize('G-3F7VPVC38S');

Amplify.configure({
	Auth: {
		identityPoolId: currStackConfiguration.identityPoolId,
		region: 'eu-west-1',
		userPoolId: currStackConfiguration.userPoolId,
		userPoolWebClientId: currStackConfiguration.userPoolWebClientId,
		mandatorySignIn: false,
		oauth: {
			domain: currStackConfiguration.cognitoDomain,
			scope: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
			redirectSignIn: currStackConfiguration.ssoRedirectSignIn,
			redirectSignOut: currStackConfiguration.ssoRedirectSignOut,
			clientId: currStackConfiguration.userPoolWebClientId,
			responseType: 'code',
		},
	},
	API: {
		endpoints: [
			{
				name: 'AppsApi',
				endpoint: currStackConfiguration.apiEndpoint,
				region: 'eu-west-1',
			},
		],
	},
});

const App = () => {
	const [passwordEntered, setPasswordEntered] = React.useState(false);

	React.useEffect(() => {
		if (stage === 'dev' || stage === 'stage') {
			setPasswordEntered(!!localStorage.getItem('passwordEntered'));
		} else if (stage === 'prod') {
			setPasswordEntered(true);
		}
	}, [stage]);

	if (!passwordEntered && stage === 'dev') {
		return (
			<BrowserRouter>
				<StateProvider>
					<DevStagePasswordCheck stage={stage} setPasswordEntered={setPasswordEntered} />
				</StateProvider>
			</BrowserRouter>
		);
	}

	return (
		<BrowserRouter>
			<StateProvider>
				<Switch>
					<LoginRoute exact path='/login' component={Login} />
					<LoginRoute exact path='/login/join' component={Signup} /> {/* Legacy attribution route */}
					<LoginRoute exact path='/login/:code' component={ResetPassword} />
					<LoginRoute exact path='/login/validation/:code' component={ConfirmSignup} />
					<LoginRoute exact path='/password/forgot' component={ForgotPassword} />
					<LoginRoute exact path='/signup' component={Signup} />
					<Template>
						<Switch>
							<Route exact path='/' component={Home} />
							<Route path='/pricing' component={Pricing} />
							<Route path='/downloads' component={Downloads} />
							<Route path='/customer-agreement' component={CustomerAgreement} />
							<Route path='/privacy' component={PrivacyNotice} />
							<Route path='/terms' component={SiteTerms} />
							<Route exact path='/demo-projects' component={DemoProjects} />
							<Route exact path='/demo-projects/:demoProjectId' component={DemoProject} />

							{/* Legacy routes */}
							<Redirect from='/customerAgreement' to='/customer-agreement' />
							<Redirect from='/privacyNotice' to='/privacy' />
							<Redirect from='/siteTerms' to='/terms' />
							<Redirect from='/resouceSets' to='/resources' />

							<PrivateRoute exact path='/createproject/:plan?' component={Projects} />
							<PrivateRoute exact path='/projects/:projectId' component={Project} />
							<PrivateRoute exact path='/projects' component={Projects} />
							<PrivateRoute path='/account' component={Account} />

							<StudioDeveloperRoute exact path='/studio-developer' component={StudioDeveloper} />
							<StudioDeveloperRoute exact path='/studio-developer/effect-templates' component={StudioDeveloperEffectTemplates} />

							<AdminRoute exact path='/admin' component={Admin} />
							<AdminRoute exact path='/admin/projects' component={AdminProjects} />
							<AdminRoute exact path='/admin/projects/:projectId' component={AdminProject} />
							<AdminRoute exact path='/admin/accounts/:userId' component={AdminAccount} />
							<AdminRoute exact path='/resources' component={Resources} />
							<Route component={NotFound} />
						</Switch>
					</Template>
				</Switch>
			</StateProvider>
		</BrowserRouter>
	);
};

export default App;
