import React from 'react';

import axios from 'axios';

import { Box, Button, Dialog, DialogContent, DialogProps, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQueryClient } from 'react-query';
import { editResource, ResourceI, ResourceSetI } from '../../utilities/queries';
import { AppContext } from '../../utilities/StateProvider';
import { useDropzone } from 'react-dropzone';
import { API } from 'aws-amplify';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ResponsiveDialog from '../../components/ResponsiveDialog';

export interface UploadFileDialogPropsI extends DialogProps {
	closeDialog: () => void;
	_resource: ResourceI;
	resourceSet: ResourceSetI;
	resourceIndex?: number;
	mode: 'file' | 'thumb';
}

const UploadFileDialog = (props: UploadFileDialogPropsI) => {
	const { closeDialog, resourceSet, _resource: resource, resourceIndex, mode, ...rest } = props;

	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const [files, setFiles] = React.useState<(File & { preview: string })[]>([]);
	const [loading, setLoading] = React.useState(false);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		maxFiles: 1,
		accept: {
			...(mode === 'thumb' ? { 'image/*': [] } : undefined),
			// ...(mode === 'file' ? { 'application/octet-stream': [] } : undefined),    We may want to add validation for effect files in the future
		},
		onDrop: (acceptedFiles: File[]) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const queryClient = useQueryClient();

	React.useEffect(() => {
		return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
	}, [files]);

	const editResourceMutation = useMutation(editResource, {
		onSuccess: async () => {
			queryClient.invalidateQueries('resourceSets');
			setGlobalAlertMessage({ message: 'Resource saved successfully' });
			closeDialog();
		},
	});

	const getSignedURL = async (file: File) => {
		if (!file) {
			throw new Error('No file given');
		}

		const bodyData = {
			fileName: file.name,
			fileType: file.type || 'application/octet-stream',
		};

		var data = {
			body: bodyData,
			headers: { 'Content-Type': 'application/json' },
		};

		return await API.post('AppsApi', '/resourceSets/' + resourceSet.Id + '/resourceUploadSignedUrl', data);
	};

	const handleUploadFile = async (e: React.SyntheticEvent, file: File) => {
		e.preventDefault();
		setLoading(true);
		try {
			const signedResponse = await getSignedURL(file);
			const axiosApi = axios.create();

			await axiosApi.put(signedResponse.signedUrl, file);

			if (resource && typeof resourceIndex === 'number') {
				editResourceMutation.mutateAsync({
					resource: {
						...resource,
						meta: {
							...resource.meta,
							...(mode === 'thumb' ? { thumbUrl: signedResponse.downloadUrl } : undefined),
						},
						...(mode === 'file' ? { url: signedResponse.downloadUrl } : undefined),
					},
					resourceIndex,
					resourceList: resourceSet.ResourceSet.resources,
					resourceSet,
				});
			}
		} catch (error: any) {
			setGlobalAlertMessage({ message: error.message, severity: 'error' });
		} finally {
			setLoading(false);
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{loading || (editResourceMutation.isLoading && <LinearProgress />)}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Upload {mode === 'file' ? 'File' : 'Thumbnail'} </Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack
						sx={{ mt: 1, textAlign: 'left', width: '100%' }}
						component='form'
						onSubmit={(event: React.SyntheticEvent) => handleUploadFile(event, files[0])}
						direction='column'
					>
						{!files.length && (
							<Box
								sx={{ border: '1px dashed', display: 'flex', py: 8, justifyContent: 'center', alignItems: 'center', borderRadius: 2 }}
								{...getRootProps()}
								component='div'
							>
								<Box component='input' {...getInputProps()} />
								{isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
							</Box>
						)}
						{files.map((file) => (
							<Box key={file.name} sx={{ height: '300px', width: '300px', margin: 'auto', borderRadius: 2, position: 'relative', overflow: 'hidden' }}>
								<Box
									sx={{
										position: 'absolute',
										opacity: 0,
										left: 0,
										right: 0,
										top: 0,
										bottom: 0,
										'&:hover': { backgroundColor: 'rgba(0,0,0,0.5)', opacity: 1 },
									}}
								>
									<IconButton
										aria-label='delete'
										onClick={() => setFiles([])}
										sx={{
											position: 'absolute',
											right: 8,
											top: 8,
											color: (theme) => theme.palette.grey[100],
										}}
									>
										<CloseIcon />
									</IconButton>
								</Box>
								{file.type.includes('image/') ? (
									<Box
										component='img'
										src={file.preview}
										// Revoke data uri after image is loaded
										onLoad={() => {
											URL.revokeObjectURL(file.preview);
										}}
										sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
									/>
								) : (
									<Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: '100%', width: '100%' }}>
										<FilePresentIcon />
										<Typography>{file.name}</Typography>
									</Stack>
								)}
							</Box>
						))}
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' type='submit'>
								Save
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default UploadFileDialog;
