import { Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { userDataHandler } from '../../utilities/helpers';
import { AppContext } from '../../utilities/StateProvider';

import 'react-phone-input-2/lib/material.css';
import { countryOptions, CountryOptionI } from '../../utilities/countriesData';
import { API, Auth } from 'aws-amplify';

export interface AccountDetailsFormI {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	address: string;
	zipCode: string;
	country: CountryOptionI;
	city: string;
	sendNotifications: boolean;
	companyName: string;
	companyWebsite: string;
	vatId: string;
	stripeId: string;
}

const AccountForm = () => {
	const { state, dispatch } = React.useContext(AppContext);
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm<AccountDetailsFormI>({
		defaultValues: {
			...userDataHandler(state.appAuthState.currentUser),
		},
	});
	const [loading, setLoading] = React.useState(false);

	const handleUpdateUser: SubmitHandler<AccountDetailsFormI> = async (data: AccountDetailsFormI) => {
		setLoading(true);
		try {
			const user = await Auth.currentAuthenticatedUser();

			const stripeId = user.attributes?.['custom:stripe_id'];
			if (stripeId) {
				await API.post('AppsApi', '/billingInfo', {
					body: {
						stripeId,
						billingInfo: {
							email: data.email,
							firstName: data.firstName,
							lastName: data.lastName,
							address: data.address ?? '',
							city: data.city ?? '',
							zipCode: data.zipCode ?? '',
							country: data.country?.value ?? '',
							vatId: data.vatId ?? '',
							companyName: data.companyName ?? '',
						},
					},
					headers: { 'Content-Type': 'application/json' },
				});
			}

			await Auth.updateUserAttributes(user, {
				given_name: data.firstName,
				family_name: data.lastName,
				'custom:phone': data.phone ? data.phone : '',
				address: data.address ? data.address : '',
				'custom:zip_code': data.zipCode ? data.zipCode : '',
				'custom:country': data.country ? data.country.value : '',
				'custom:city': data.city ? data.city : '',
				'custom:send_notifications': data.sendNotifications ? 'true' : 'false',
				'custom:company_name': data.companyName ? data.companyName : '',
				'custom:company_website': data.companyWebsite ? data.companyWebsite : '',
				'custom:vat_id': data.vatId || '',
			});
			const newUser = await Auth.currentAuthenticatedUser();
			dispatch({ type: 'CHANGE_AUTH_STATE', payload: { currentUser: newUser } });
		} catch (error: any) {
			console.log(error.message);
		} finally {
			setLoading(false);
		}
	};

	if (state.appAuthState.appAuthState === 'loading') {
		return null;
	}

	return (
		<Stack
			sx={{ mt: 1, textAlign: 'left', maxWidth: 500 }}
			component='form'
			onSubmit={handleSubmit(handleUpdateUser)}
			direction='column'
			spacing={3}
			noValidate
		>
			<Box>
				<Typography variant='h5'>Your details</Typography>
				<Typography variant='body1' color='text.secondary' gutterBottom>
					{userDataHandler(state.appAuthState.currentUser).email}
				</Typography>
			</Box>
			<Stack direction='row' spacing={1} sx={{ my: 2 }}>
				<TextField
					{...register('firstName', { required: true })}
					required
					fullWidth
					name='firstName'
					label='First name'
					id='firstName'
					error={!!errors.firstName}
					helperText={errors?.firstName?.message}
					autoComplete='firstName'
				/>
				<TextField
					{...register('lastName', { required: true })}
					required
					fullWidth
					name='lastName'
					label='Last name'
					id='lastName'
					error={!!errors.lastName}
					helperText={errors?.lastName?.message}
					autoComplete='lastName'
				/>
			</Stack>
			<Controller
				name='phone'
				control={control}
				render={({ field: { onChange, value } }) => (
					<PhoneInput
						country={'us'}
						value={value}
						onChange={onChange}
						inputProps={{
							name: 'Phone number',
							required: true,
						}}
					/>
				)}
			/>
			<FormControlLabel
				control={
					<Controller
						name='sendNotifications'
						control={control}
						render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
					/>
				}
				label='Send account notifications via email?'
			/>
			<Divider />
			<Typography variant='h5' gutterBottom>
				Company details
			</Typography>
			<Stack direction='row' spacing={1} sx={{ my: 2 }}>
				<TextField
					{...register('companyName')}
					fullWidth
					name='companyName'
					label='Company name'
					id='companyName'
					error={!!errors.companyName}
					helperText={errors?.companyName?.message}
					autoComplete='companyName'
				/>
				<TextField
					{...register('companyWebsite')}
					fullWidth
					name='companyWebsite'
					label='Company website'
					id='companyWebsite'
					error={!!errors.companyWebsite}
					helperText={errors?.companyWebsite?.message}
					autoComplete='companyWebsite'
				/>
			</Stack>
			<TextField
				{...register('address')}
				fullWidth
				name='address'
				label='Company address'
				id='address'
				error={!!errors.address}
				helperText={errors?.address?.message}
				autoComplete='address'
			/>
			<Stack direction='row' spacing={1} sx={{ my: 2 }}>
				<TextField
					{...register('city')}
					fullWidth
					name='city'
					label='City'
					id='city'
					error={!!errors.city}
					helperText={errors?.city?.message}
					autoComplete='city'
				/>
				<TextField
					{...register('zipCode')}
					fullWidth
					name='zipCode'
					label='Zip/Postal Code'
					id='zipCode'
					error={!!errors.zipCode}
					helperText={errors?.zipCode?.message}
					autoComplete='zipCode'
				/>
			</Stack>
			<Stack direction='row' spacing={1} sx={{ my: 2 }}>
				<Controller
					name='country'
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value }, ...props }) => (
						<Autocomplete
							value={value}
							onChange={(e, v) => onChange(v)}
							fullWidth
							id='country'
							disablePortal
							options={countryOptions}
							getOptionLabel={(option) => option.text}
							isOptionEqualToValue={(option, value) => option.value === value.value}
							renderInput={(params) => <TextField {...params} label='Country' error={!!errors['country']} />}
						/>
					)}
				/>
				<TextField
					{...register('vatId')}
					fullWidth
					name='vatId'
					label='VAT ID (with country code)'
					id='vatId'
					error={!!errors.vatId}
					helperText={errors?.vatId?.message}
					autoComplete='vatId'
				/>
			</Stack>
			<Stack direction='row'>
				<Button onClick={() => reset()}>Cancel</Button>
				<Button type='submit'>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
			</Stack>
		</Stack>
	);
};

export default AccountForm;
