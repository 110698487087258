import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useProjects } from '../../utilities/queries';
import { AppContext } from '../../utilities/StateProvider';

const InvalidProjectsBanner = () => {
	const { pathname } = useLocation();
	const { state } = React.useContext(AppContext);
	const { data, isLoading } = useProjects(state.appAuthState.appAuthState === 'signedIn');

	const invalidProjects = React.useMemo(() => {
		return data?.filter((p) => (p.InvalidPayment || p.InvalidMau) && p.PlanId !== 'free' && p.PlanId !== 'xxl-tier');
	}, [data]);

	const invalidProject = invalidProjects?.[0];

	if (!invalidProject || isLoading || pathname === `/projects/${invalidProject.Id}`) {
		return null;
	}

	return (
		<Link to={`/projects/${invalidProject.Id}`}>
			<Box
				sx={{
					py: 1,
					px: 3,
					bgcolor: 'background.warning',
					position: 'sticky',
					top: 54,
					zIndex: 1000,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography sx={{ color: 'text.primary' }}>
					{`${invalidProject.Name} has ${
						invalidProject.InvalidPayment ? 'an outstanding payment' : 'exceeded usage limits'
					}. Please fix to ensure your users aren't interrupted.`}
				</Typography>
				<Button variant='contained' size='small'>
					Fix issue
				</Button>
			</Box>
		</Link>
	);
};

export default InvalidProjectsBanner;
