import React from 'react';

import { Box, Container, Typography, Grid, Button } from '@mui/material';
import DemoProjectsCard from '../../../DemoProjects/DemoProjectsCard';

import { ArrowForward } from '@mui/icons-material';
import { useDemoProjects } from '../../../../utilities/queries';
import { Link } from 'react-router-dom';

const DemoProjects = () => {
	const { data: demoProjects, isLoading } = useDemoProjects();

	return (
		<>
			<Container sx={{ py: 5 }}>
				<Box sx={{ py: 3 }}>
					<Typography variant='h2' gutterBottom>
						Demo Projects
					</Typography>
					<Typography variant='body1' color='text.secondary'>
						Get started fast with easy project templates for popular AR app formats
					</Typography>
				</Box>
				<Grid container rowSpacing={2} columnSpacing={2}>
					{demoProjects?.slice(0, 3).map((project, i) => (
						<DemoProjectsCard key={i} {...project} />
					))}
				</Grid>
				<Box padding={4} textAlign='center'>
					<Button endIcon={<ArrowForward />} to='/demo-projects' component={Link}>
						All Demo Projects
					</Button>
				</Box>
			</Container>
		</>
	);
};

export default DemoProjects;
