import React, { useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { AppBar, Avatar, Box, IconButton, Link, Menu, MenuItem, Stack, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppContext } from '../utilities/StateProvider';
import { Auth } from '@aws-amplify/auth';
import Logo from '../utilities/Logo';
import { stringAvatar } from '../utilities/helpers';
import Sidebar from './Sidebar';

const TopBar = () => {
	const [accountMenuElement, setAccountMenuElement] = React.useState<null | HTMLElement>(null);
	const history = useHistory();
	const { state, dispatch, setGlobalAlertMessage } = useContext(AppContext);
	const signedIn = state.appAuthState.appAuthState === 'signedIn';
	const [sidebarOpen, setSidebarOpen] = React.useState(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setAccountMenuElement(event.currentTarget);
	};

	const handleClose = () => {
		setAccountMenuElement(null);
	};

	const handleSignOut = async () => {
		try {
			const currentUser = await Auth.currentAuthenticatedUser();
			await currentUser.signOut();
			dispatch({ type: 'CHANGE_AUTH_STATE', payload: { appAuthState: 'signedOut' } });
			history.push('/');
		} catch (error) {
			setGlobalAlertMessage({ message: 'Oops, there was a problem signing you out. Please try again', severity: 'error' });
			console.error('error signing out: ', error);
		}
	};

	const handleGoToAccount = () => {
		history.push('/account');
		setAccountMenuElement(null);
	};

	return (
		<AppBar
			position='fixed'
			sx={{
				zIndex: (theme) => theme.zIndex.drawer + 1,
				backgroundColor: (theme) => theme.palette.background.paper,
				boxShadow: 2,
				borderBottom: 1,
				borderColor: (theme) => theme.palette.divider,
				px: 3,
			}}
		>
			<Stack direction='row' justifyContent='space-between' alignItems='center' py={0.8}>
				<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
					<Link component={RouterLink} to='/' sx={{ display: 'flex', alignItems: 'center' }}>
						<Logo />
					</Link>
				</Box>

				<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
					<IconButton size='large' aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={() => setSidebarOpen(true)}>
						<MenuIcon />
					</IconButton>
					<React.Fragment>
						<Sidebar
							open={sidebarOpen}
							variant='temporary'
							anchor='left'
							mobile
							onClose={() => setSidebarOpen(false)}
							closeSidebar={() => setSidebarOpen(false)}
						/>
					</React.Fragment>
				</Box>
				<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
					<Link component={RouterLink} to='/' sx={{ display: 'flex', alignItems: 'center' }}>
						<Logo />
					</Link>
				</Box>
				<Box sx={{ flexGrow: 0 }}>
					<IconButton sx={{ p: 0 }} onClick={handleClick}>
						<Avatar alt={state.appAuthState.currentUserAttributes?.Email} {...stringAvatar(state.appAuthState.currentUserAttributes?.Email)} />
					</IconButton>
					<Menu
						sx={{ mt: '45px' }}
						id='menu-appbar'
						anchorEl={accountMenuElement}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(accountMenuElement)}
						onClose={handleClose}
					>
						{!signedIn && (
							<MenuItem onClick={() => history.push('/login')}>
								<Typography textAlign='center'>Log In</Typography>
							</MenuItem>
						)}
						{!signedIn && (
							<MenuItem onClick={() => history.push('/signup')}>
								<Typography textAlign='center'>Sign Up</Typography>
							</MenuItem>
						)}
						{signedIn && (
							<MenuItem onClick={handleSignOut}>
								<Typography textAlign='center'>Sign Out</Typography>
							</MenuItem>
						)}
						{signedIn && (
							<MenuItem onClick={handleGoToAccount}>
								<Typography textAlign='center'>Account</Typography>
							</MenuItem>
						)}
					</Menu>
				</Box>
			</Stack>
		</AppBar>
	);
};

export default TopBar;
