import React from 'react';
import { Box, Button, Container, Stack } from '@mui/material';

import { Link } from 'react-router-dom';
import InviteUserDialog from './InviteUserDialog';
import NewProjectGraph from './Graphs/NewProjectGraph';
import ActiveProjectGraph from './Graphs/ActiveProjectGraph';

const Admin = () => {
	const [inviteUserDialogOpen, setInviteUserDialogOpen] = React.useState(false);

	return (
		<Container component={Box} pt={4}>
			<Stack direction='row' sx={{ mt: 2 }}>
				<Button component={Link} to='/admin/projects'>
					Browse projects
				</Button>
				<Button onClick={() => setInviteUserDialogOpen(true)}>Invite User</Button>
				{inviteUserDialogOpen && (
					<InviteUserDialog open={inviteUserDialogOpen} onClose={() => setInviteUserDialogOpen(false)} closeDialog={() => setInviteUserDialogOpen(false)} />
				)}
			</Stack>

			<NewProjectGraph />
			<ActiveProjectGraph />
		</Container>
	);
};

export default Admin;
