import React from 'react';

import { Box, Button, Dialog, DialogContent, DialogProps, IconButton, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitHandler, useForm } from 'react-hook-form';
import { API } from 'aws-amplify';
import { AppContext } from '../../utilities/StateProvider';
import ResponsiveDialog from '../../components/ResponsiveDialog';

interface EditAdminProjectDialogPropsI extends DialogProps {
	closeDialog: () => void;
}

export interface InviteUserFormI {
	email: string;
}

const InviteUserDialog = (props: EditAdminProjectDialogPropsI) => {
	const { closeDialog, ...rest } = props;
	const { setGlobalAlertMessage } = React.useContext(AppContext);
	const [loading, setLoading] = React.useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<InviteUserFormI>({
		defaultValues: {
			email: '',
		},
	});

	const createUserInvite = async (data: InviteUserFormI) => {
		const request = {
			body: { ...data },
			headers: { 'Content-Type': 'application/json' },
		};
		return API.post('AppsApi', '/admin/invite', request);
	};

	const handleCreateProjectApp: SubmitHandler<InviteUserFormI> = async (data: InviteUserFormI) => {
		setLoading(true);
		try {
			await createUserInvite(data);
			setGlobalAlertMessage({
				message: 'User successfully invited 🙌',
			});
			closeDialog();
		} catch (error: any) {
			setGlobalAlertMessage({
				message: error.response.data.message,
				severity: 'error',
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<ResponsiveDialog {...rest}>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<DialogContent sx={{ maxWidth: '500px' }}>
				<Box textAlign='center'>
					<Box sx={{ p: 1 }}>
						<Typography variant='h5'>Invite user</Typography>
						<IconButton
							aria-label='close'
							onClick={(e) => props.onClose?.(e, 'backdropClick')}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Stack sx={{ mt: 1, textAlign: 'left', width: '100%' }} component='form' onSubmit={handleSubmit(handleCreateProjectApp)} direction='column'>
						<Box>
							<TextField
								{...register('email', { required: true })}
								margin='normal'
								required
								fullWidth
								type='email'
								id='email'
								label='Email'
								name='email'
								error={!!errors?.email}
								helperText={!!errors?.email && errors.email.message}
								autoFocus
							/>
						</Box>
						<Stack direction='row' spacing={1} mt={3}>
							<Button variant='text' fullWidth size='large' onClick={(e) => props.onClose?.(e, 'backdropClick')}>
								Cancel
							</Button>
							<Button variant='contained' fullWidth size='large' type='submit'>
								Continue
							</Button>
						</Stack>
					</Stack>
				</Box>
			</DialogContent>
		</ResponsiveDialog>
	);
};

export default InviteUserDialog;
