import React from 'react';

import { Box, Button, IconButton, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material';
import { StudioDeveloperEffectTemplateI } from '../../utilities/queries';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import CreateEditEffectTemplateDialog from './CreateEditEffectTemplateDialog';

interface StudioDeveloperEffectTemplateItemPropsI {
	effectTemplate: StudioDeveloperEffectTemplateI;
}

const StudioDeveloperEffectTemplateItem = (props: StudioDeveloperEffectTemplateItemPropsI) => {
	const { effectTemplate } = props;

	const [moreMenuElement, setMoreMenuElement] = React.useState<null | HTMLElement>(null);
	const [editEffectTemplateOpen, setEditEffectTemplateOpen] = React.useState(false);
	const [duplicateEffectTemplateOpen, setDuplicateEffectTemplateOpen] = React.useState(false);

	const handleEditEffectTemplateClick = () => {
		setEditEffectTemplateOpen(true);
		setMoreMenuElement(null);
	};

	const handleDuplicateEffectTemplateClick = () => {
		setDuplicateEffectTemplateOpen(true);
		setMoreMenuElement(null);
	};

	const handleDownloadClick = (downloadUrl: string) => {
		const link = document.createElement('a');
		link.href = downloadUrl || '';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Paper sx={{ padding: 3, my: 2 }}>
			<Stack direction='row' justifyContent='space-between' pb={2}>
				<Stack direction='row'>
					<Box
						component='img'
						src={effectTemplate.ImageUrl}
						sx={{
							height: 256,
							width: 256,
							maxHeight: { xs: 256, md: 128 },
							maxWidth: { xs: 256, md: 128 },
						}}
						mr={5} />
					<Stack spacing={1}>
						<Typography variant='h6'>{effectTemplate.Name['english']}</Typography>
						{effectTemplate.MaxStudioVersion && <Typography variant='h3' color='error'>Discontinued</Typography>}
						<Button variant='outlined' startIcon={<DownloadIcon />} onClick={() => handleDownloadClick(effectTemplate.ZipUrl)}>
							Download .ZIP
						</Button>
					</Stack>
				</Stack>
				<Stack direction='row' spacing={2}>
					<Box minWidth={4} sx={{ textAlign: 'center' }}>
						<IconButton
							aria-label='menu'
							onClick={(e) => setMoreMenuElement(e.currentTarget)}
							sx={{
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id='menu-resource-set'
							anchorEl={moreMenuElement}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={!!moreMenuElement}
							onClose={() => setMoreMenuElement(null)}
						>
							<MenuItem onClick={handleEditEffectTemplateClick}>
								<Stack direction='row' spacing={1}>
									<EditIcon />
									<Typography textAlign='center'>Edit</Typography>
								</Stack>
							</MenuItem>
							<MenuItem onClick={handleDuplicateEffectTemplateClick}>
								<Stack direction='row' spacing={1}>
									<EditIcon />
									<Typography textAlign='center'>Duplicate</Typography>
								</Stack>
							</MenuItem>
							<MenuItem disabled={true}>
								<Stack direction='row' spacing={1}>
									<DeleteIcon color='error' />
									<Typography textAlign='center'>Delete</Typography>
								</Stack>
							</MenuItem>
						</Menu>
					</Box>
				</Stack>
			</Stack>
			{editEffectTemplateOpen && (
				<CreateEditEffectTemplateDialog open closeDialog={() => setEditEffectTemplateOpen(false)} onClose={() => setEditEffectTemplateOpen(false)} effectTemplate={effectTemplate} />
			)}
			{duplicateEffectTemplateOpen && (
				<CreateEditEffectTemplateDialog open closeDialog={() => setDuplicateEffectTemplateOpen(false)} onClose={() => setDuplicateEffectTemplateOpen(false)} effectTemplate={effectTemplate} forceNew />
			)}
		</Paper>
	);
};

export default StudioDeveloperEffectTemplateItem;
