import { Alert, AlertProps } from '@mui/material';
import React from 'react';

export interface CustomAlertPropsI {
	severity: AlertProps['severity'];
	message: string;
}

const CustomAlert = (props?: CustomAlertPropsI) => {
	if (!props) return null;
	return (
		<Alert severity={props.severity} sx={{ my: 2 }}>
			{props.message}
		</Alert>
	);
};

export default CustomAlert;
