import React from 'react';

import { Box, Button, CircularProgress, Container, Skeleton, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useResourceSets } from '../../utilities/queries';
import ResourceSetListItem from './ResourceSetListItem';
import CreateEditResourceSetDialog from './CreateEditResourceSetDialog';

const Resources = () => {
	const { data, isLoading, isFetching } = useResourceSets();
	const [createResourceSetOpen, setCreateResourceSetOpen] = React.useState(false);

	return (
		<Container component={Box} pt={4} pb={8}>
			<Stack paddingY={2} direction='row' justifyContent='space-between' mb={3}>
				<Box>
					<Typography variant='h5' gutterBottom>
						Resource Sets {isLoading || isFetching ? <CircularProgress size={20} /> : `(${data?.length})`}
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						Store your DeepAR assets and access them via API
					</Typography>
				</Box>
				<Button startIcon={<AddIcon />} onClick={() => setCreateResourceSetOpen(true)}>
					New Resource Set
				</Button>
			</Stack>
			{isLoading ? (
				<Stack spacing={2}>
					{[1, 2, 3, 4].map((v, i) => (
						<Skeleton variant='rectangular' height={200} key={i} />
					))}
				</Stack>
			) : (
				<Box>
					{data?.map((resourceSet, i) => (
						<ResourceSetListItem resourceSet={resourceSet} key={i} />
					))}
				</Box>
			)}
			{createResourceSetOpen && <CreateEditResourceSetDialog open closeDialog={() => setCreateResourceSetOpen(false)} onClose={() => setCreateResourceSetOpen(false)} />}
		</Container>
	);
};

export default Resources;
