import React from 'react';

import { Box, Button, Chip, CircularProgress, Link, Stack, TextField, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import { ProjectPriceI } from '../PaidProjectTotal';

interface CouponCodePropsI {
	couponCode: string;
	pricePlanId: string;
	setCouponCode: React.Dispatch<React.SetStateAction<string>>;
}

const CouponCode = (props: CouponCodePropsI) => {
	const { couponCode, setCouponCode, pricePlanId } = props;

	const [couponCodeValue, setCouponCodeValue] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [showInput, setShowInput] = React.useState(false);
	const [couponCodeDetails, setCouponCodeDetails] = React.useState('');
	const [couponCodeError, setCouponCodeError] = React.useState('');

	const applyCoupon = async (coupon: string) => {
		setLoading(true);
		try {
			var data = {
				body: { couponId: coupon },
				headers: { 'Content-Type': 'application/json' },
			};
			const response: ProjectPriceI = await API.post('AppsApi', '/calculatePrice/' + pricePlanId, data);
			setCouponCodeDetails(response?.couponName || '');
			setCouponCode(coupon);
		} catch (error: any) {
			setCouponCodeError('This code is invalid');
		} finally {
			setLoading(false);
		}
	};

	const handleCouponInputBlur = () => {
		if (!couponCodeValue) {
			setShowInput(false);
		}
	};

	const handleCouponInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setCouponCodeValue(e.target.value);
		setCouponCodeError('');
	};

	const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			applyCoupon(couponCodeValue);
		}
	};

	const handleDeletePromoCode = () => {
		setCouponCode('');
		setCouponCodeValue('');
	};

	return (
		<Box py={2}>
			{!!couponCode ? (
				<Box>
					<Chip label={couponCode} onDelete={handleDeletePromoCode} sx={{ mb: 1 }} />
					<Typography variant='body2' color='text.secondary'>
						{couponCodeDetails}
					</Typography>
				</Box>
			) : showInput ? (
				<Stack direction='row' spacing={1}>
					<TextField
						label='Promotional code'
						value={couponCodeValue}
						onChange={handleCouponInputChange}
						size='small'
						autoFocus
						onBlur={handleCouponInputBlur}
						error={!!couponCodeError}
						helperText={!!couponCodeError && couponCodeError}
						onKeyDown={handleEnter}
					/>
					{!!couponCodeValue.length && (
						<Button onClick={() => applyCoupon(couponCodeValue)}>
							{loading ? <CircularProgress size={20} /> : 'Apply'}
						</Button>
					)}
				</Stack>
			) : (
				<Link onClick={() => setShowInput(true)} sx={{ cursor: 'pointer' }}>
					Add promotional code
				</Link>
			)}
		</Box>
	);
};

export default CouponCode;
