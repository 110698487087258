import React from 'react';
import { Box, Container, Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useDownloads, DownloadName, DownloadI } from '../../utilities/queries';
import DownloadItem, { DownloadItemPropsI } from './DownloadItem';

interface ExtraDownloadInfoI {
	name: DownloadName;
	displayName?: string;
	description: string;
	imageUrl: string;
	customDownloadUrl?: string;
	docsName?: string;
	docsUrl?: string;
	legacy?: boolean;
}

export const extraDownloadInfo: ExtraDownloadInfoI[] = [
	{
		name: DownloadName.android,
		description: 'Install DeepAR on Android devices',
		imageUrl: 'https://uploads-ssl.webflow.com/6139ee313144e527fc2c2fdb/613fc96f936d4d6222a6c528_android.svg',
		docsName: 'API reference',
		docsUrl: 'https://docs.deepar.ai/deepar-sdk/deep-ar-sdk-for-android/api-reference/',
	},
	{
		name: DownloadName.ios,
		description: 'Install DeepAR on iPhone',
		imageUrl: 'https://uploads-ssl.webflow.com/6139ee313144e527fc2c2fdb/613fc96e101a55da79eb260e_apple.svg',
		docsName: 'API reference',
		docsUrl: 'https://docs.deepar.ai/deepar-sdk/deep-ar-sdk-for-ios/api-reference/documentation/deepar/',
	},
	{
		name: DownloadName.web,
		description: 'Install DeepAR on the Web',
		imageUrl: 'https://uploads-ssl.webflow.com/6139ee313144e527fc2c2fdb/613fc96edac76ed3733f43a1_html.svg',
		docsName: 'API reference',
		docsUrl: 'https://docs.deepar.ai/deepar-sdk/deep-ar-sdk-for-web/api-reference/',
	},
	{
		name: DownloadName.macos,
		description: 'Install DeepAR on Mac Desktop',
		imageUrl: 'https://uploads-ssl.webflow.com/6139ee313144e527fc2c2fdb/613fc96ff2d5e656f50b0df3_macos.svg',
		docsName: 'API reference',
		docsUrl: 'https://docs.deepar.ai/deepar-sdk/deep-ar-sdk-for-ios/api-reference/documentation/deepar/',
	},
	{
		name: DownloadName.studio,
		displayName: 'DeepAR Studio',
		description: 'Create, edit and preview augmented reality assets for Web, iOS and Android. Get started fast with free demo effects.',
		imageUrl: '/studio-logo.png',
		docsName: 'Documentation',
		docsUrl: 'https://docs.deepar.ai/deepar-studio/introduction',
	},
	{
		name: DownloadName.filterPack,
		displayName: 'DeepAR Filter Pack (Legacy)',
		description: 'Get started fast with templates of popular AR filters and effects',
		imageUrl: '/deepar-free-filters.png',
		customDownloadUrl: 'https://sdk.developer.deepar.ai/examples/free_package.zip',
		legacy: true,
	},
];

const Downloads = () => {
	const { data, isLoading } = useDownloads();

	const theme = useTheme();

	const { sdkProps, studioProps } = React.useMemo<{ sdkProps: DownloadItemPropsI[]; studioProps: DownloadItemPropsI[] }>(() => {
		const combineInfo = (downloads: DownloadI[]) =>
			extraDownloadInfo
				.map((info) => ({
					info,
					download: downloads.find((download) => download.name === info.name),
				}))
				.filter((expanded) => expanded.download != null)
				.map((expanded) => ({
					...expanded.info,
					...expanded.download,
				}));
		return {
			sdkProps: combineInfo(data?.sdkDownloads || []),
			studioProps: combineInfo(data?.studioDownloads || []),
		};
	}, [data]);

	if (isLoading) {
		return (
			<Container component={Box} pb={4} pt={10}>
				<Stack spacing={2}>
					{[1, 2, 3, 4].map((v, i) => (
						<Skeleton variant='rectangular' height={200} key={i} />
					))}
				</Stack>
			</Container>
		);
	}

	const filterPackInfo = extraDownloadInfo.find((info) => info.name === DownloadName.filterPack);

	return (
		<Box
			sx={{
				backgroundImage: theme.palette.mode === 'light' ? 'url(/assets/bg-grid.svg)' : 'url(/assets/bg-grid-dark.svg)',
				backgroundRepeat: 'repeat',
				height: '106%',
			}}
		>
			<Container component={Box} pb={4} pt={10}>
				<Box pb={6}>
					<Typography variant='h1'>Download DeepAR</Typography>
				</Box>
				<Grid container rowSpacing={6} columnSpacing={4}>
					<Grid item sm={12} md={6}>
						<Typography variant='h5' gutterBottom>
							Install
						</Typography>
						{sdkProps.map((sdk) => (
							<DownloadItem key={`download-${sdk.name}`} {...sdk} />
						))}
					</Grid>
					<Grid item sm={12} md={6}>
						<Typography variant='h5' gutterBottom>
							Create
						</Typography>
						{studioProps.map((studio) => (
							<DownloadItem key={`download-${studio.name}`} {...studio} />
						))}
						{filterPackInfo && <DownloadItem {...filterPackInfo} />}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Downloads;
