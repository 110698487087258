import React from 'react';

import { Box, Container, Typography, Grid, Button } from '@mui/material';

import { ArrowForward } from '@mui/icons-material';
import { effectTemplates } from '../../../EffectTemplates/effectTemplates';
import EffectTemplatesCard from '../../../EffectTemplates/EffectTemplatesCard';

const EffectTemplates = () => {
	return (
		<Container sx={{ py: 5 }}>
			<Box sx={{ py: 3 }}>
				<Typography variant='h2' gutterBottom>
					Effect Templates
				</Typography>
				<Typography variant='body1' color='text.secondary'>
					Ready to use asset projects for the most popular AR effects
				</Typography>
			</Box>
			<Grid container rowSpacing={2} columnSpacing={2}>
				{effectTemplates.slice(0, 4).map((project, i) => (
					<EffectTemplatesCard key={i} {...project} />
				))}
			</Grid>
			<Box padding={4} textAlign='center'>
				<Button endIcon={<ArrowForward />} href='https://www.store.deepar.ai/' target='_blank'>
					All Effect Templates
				</Button>
			</Box>
		</Container>
	);
};

export default EffectTemplates;
